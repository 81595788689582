import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import jwt from "jwt-decode";
import { setAuthUser, setAuthToken } from "app/redux/authReducer";
import { setSocial } from "app/redux/socialReducer";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { EResponseStatus } from "app/helper/constant";
import { setUpdateState } from "app/redux/updateInfoReducer";
import Path from "app/route/Path";
import { setRouteEmpty } from "app/redux/previousRouteReducer";
import UserService from "app/services/UserService";
import { setProfileStore } from "app/redux/profileReducer";
import {
  appendFormData,
  generateSNSIcon,
  getGoogleUrl,
  getLineUrl,
} from "app/utils";
import AuthService from "app/services/AuthService";
import { setEmail } from '../../redux/emailReducer';

export default function SigninSocial({ setIsLoading }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const previousRoute = useSelector((state) => state.previousRoute);
  const social = useSelector((state) => state.social);
  const paramSearch = new URLSearchParams(location.search);
  const code = paramSearch.get("code");
  const redirectUri = `${window.location.origin}/login`;

  const getSystemAccessToken = (platform, data) => {
    setIsLoading(true);
    AuthService.loginWithSocial(platform, data)
      .then((response) => {
        if (response.status === EResponseStatus.CREATED) {
          const user = jwt(response.data.data.accessToken);
          const token = {
            accessToken: response.data.data.accessToken,
            refreshToken: response.data.data.refreshToken,
          };
          dispatch(setAuthUser(user));
          dispatch(setAuthToken(token));
          dispatch(
            setUpdateState(
              response.data.data.not_updated
                ? response.data.data.not_updated
                : false
            )
          );
          if (
            response.data.data.is_first_login ||
            response.data.data.not_updated
          ) {
            navigate(Path.register);
          } else {
            navigate(previousRoute || Path.home);
            dispatch(setRouteEmpty());
            dispatch(setEmail(null))
            UserService.getProfile().then((res) => {
              if (res.status === EResponseStatus.SUCCESS) {
                dispatch(setProfileStore(res.data.data));
              }
            });
          }
        }
      })
      .catch(() => {})
      .finally(() => {
        dispatch(setSocial(null));
        setIsLoading(false);
      });
  };

  const loginWithLine = (codes) => {
    const data = {
      grant_type: "authorization_code",
      code: codes,
      redirect_uri: redirectUri,
      client_id: process.env.REACT_APP_LINE_CLIENT_ID,
      client_secret: process.env.REACT_APP_LINE_CLIENT_SECRET,
      social_id_provider: `${social}_influencer`,
    };
    getSystemAccessToken(social, data);
  };

  const loginWithGoogle = (codes) => {
    const data = {
      grant_type: "authorization_code",
      code: codes,
      redirect_uri: redirectUri,
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      client_secret: process.env.REACT_APP_GOOGLE_CLIENT_SECRET,
    };

    // exchange code to get account info
    axios
      .post("https://oauth2.googleapis.com/token", appendFormData(data))
      .then((res) => {
        if (res.status === EResponseStatus.SUCCESS) {
          const info = jwt(res?.data?.id_token);
          const payload = {
            login_id: info?.email,
            firstName: info?.given_name || "",
            lastName: info?.family_name || "",
            picture: info?.picture || "",
            displayName: info?.name || "",
            accessToken: res?.data?.access_token,
            social_id_provider: `${social}_influencer`,
          };
          getSystemAccessToken(social, payload);
        }
      });
  };

  useEffect(() => {
    if (code) {
      if (social === "line") {
        loginWithLine(code);
      }
      if (social === "google") {
        loginWithGoogle(code);
      }
    } else {
      dispatch(setSocial(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="px-[32px]">
      <div className="flex flex-col gap-4">
        <a
          href={getLineUrl(redirectUri)}
          className="w-full h-10 sm:h-14 text-base font-bold relative bg-white border border-border rounded-[8px] hover:border-blue-300"
          onClick={() => {
            dispatch(setSocial("line"));
          }}
        >
          <span className="flex items-center justify-center h-full">
            {t("auth.Line")}
          </span>
          {generateSNSIcon(
            "line",
            "absolute left-3 top-[50%] -translate-y-[50%] !w-6 !h-6"
          )}
        </a>
        <a
          href={getGoogleUrl(redirectUri)}
          className="w-full h-10 sm:h-14 text-base font-bold relative bg-white border border-border rounded-[8px] hover:border-blue-300"
          onClick={() => {
            dispatch(setSocial("google"));
          }}
        >
          <span className="flex items-center justify-center h-full">
            {t("auth.Google")}
          </span>
          {generateSNSIcon(
            "google",
            "absolute left-3 top-[50%] -translate-y-[50%] !w-6 !h-6"
          )}
        </a>
      </div>
    </div>
  );
}
