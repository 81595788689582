import React, { useState } from "react";

const NO_AVAILABLE_IMG = require("app/assets/images/no-available-image.svg");

function BannerItem(props) {
  const { banner } = props;
  const [imgSource, setImgSource] = useState(banner?.thumbnail_url);

  return (
    <a
      className="w-full h-full absolute top-0"
      href={
        banner?.address_url
          ? banner?.address_url
          : // eslint-disable-next-line no-script-url
            "javascript:void(0)"
      }
      target={banner?.is_new_tab === "1" ? "_blank" : "_self"}
      rel="noreferrer"
    >
      <img
        src={imgSource}
        alt=""
        onError={() => {
          setImgSource(NO_AVAILABLE_IMG.default);
        }}
        className="w-full h-full object-cover rounded-[8px]"
      />
    </a>
  );
}

export default BannerItem;
