import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Login from "app/pages/Login";
import Home from "app/pages/Home/Home";
import ProjectApply from "app/pages/Projects/ProjectApply";
import TermOfService from "app/pages/TermOfService";
import PrivacyPolicy from "app/pages/PrivacyPolicy";
import Profile from "app/pages/Profile";
import AuthRoute from "./AuthRoute";
import UnAuthRoute from "./UnAuthRoute";
import Projects from "../pages/Projects";
import ProjectDetail from "../pages/Projects/detail";
import Register from "../pages/Register";
import Path from "./Path";
import AccountSettingsPersonalInfo from "../pages/AccountSettings/PersonalInfo";
import AccountSettings from "../pages/AccountSettings";
import RewardPage from "../pages/Reward";
// import GlobalSetting from "../pages/AccountSettings/GlobalSetting";
import FavoritePage from "../pages/Favorite";
import NotFound from "../pages/404";
import AccountSettingsLoginSecurity from "../pages/AccountSettings/LoginSecurity";
import Withdraw from "../pages/AccountSettings/Withdraw";
import ChangePassword from '../pages/Login/ChangePassword';

export default function AppRoute() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<UnAuthRoute />}>
          <Route path={Path.login} element={<Login />} />
        </Route>
        <Route element={<AuthRoute />}>
          <Route path={Path.register} element={<Register />} />
          <Route path={Path.home} element={<Home />} />
          <Route path={Path.project_apply()} element={<ProjectApply />} />
          <Route path={Path.project_applied} element={<Projects />} />
          <Route path={Path.project_detail()} element={<ProjectDetail />} />
          <Route path={Path.account_settings} element={<AccountSettings />} />
          <Route
            path={Path.account_settings_personal_info}
            element={<AccountSettingsPersonalInfo />}
          />
          {/* <Route
            path={Path.account_settings_global_setting}
            element={<GlobalSetting />}
          /> */}
          <Route path={Path.reward} element={<RewardPage />} />
          <Route path={Path.favorite} element={<FavoritePage />} />
          <Route
            path={Path.account_settings_login_security}
            element={<AccountSettingsLoginSecurity />}
          />
          <Route path={Path.profile} element={<Profile />} />
          <Route path={Path.withdraw} element={<Withdraw />} />
        </Route>

        <Route path="*" element={<NotFound />} />
        <Route path={Path.privacy_policy} element={<PrivacyPolicy />} />
        <Route path={Path.term_of_service} element={<TermOfService />} />
        <Route path={Path.change_password} element={<ChangePassword />} />
      </Routes>
    </BrowserRouter>
  );
}
