import React from "react";
import { useTranslation } from "react-i18next";
import ChangeLanguageButton from "app/components/ChangeLanguageButton";

const link1 = "https://enjoy-japan.jp/privacy-policy/";
const link2 = "https://enjoy-japan.jp/";
const link3 = "https://enjoy-japan.jp/";

function Footer() {
  const { t } = useTranslation();

  return (
    <footer className="border-t border-solid border-secondary-300 bg-secondary w-full">
      <div className="custom-container">
        <div className="py-7 flex flex-col gap-2">
          <div className="grid grid-cols-1 sm:grid-cols-2 items-baseline gap-2">
            <div className="flex items-center text-sm sm:order-2 gap-5 sm:justify-end">
              <ChangeLanguageButton className="w-7 h-7" />
              <p className="font-bold">{t("footer.currency")} (JP)</p>
              <div className="flex items-center gap-2">
                <p className="font-bold">¥</p>
                <p className="font-bold">JPY</p>
              </div>
            </div>

            <div className="text-sm">
              <div className="flex items-center flex-wrap gap-1 text-xs sm:text-sm md:text-xs">
                <p className="md:mr-2">
                  {t("footer.copyright")}
                </p>
                <div className="flex flex-wrap">
                  <a
                    href={link1}
                    className="hover:underline truncate cursor-pointer"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t("footer.privacy")}
                  </a>

                  <div className="mx-2">•</div>
                  <a
                    className="hover:underline truncate cursor-pointer"
                    href={link2}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t("footer.term_of_use")}
                  </a>
                  <div className="mx-2">•</div>
                  <a
                    className="hover:underline truncate cursor-pointer"
                    href={link3}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t("footer.about_comany")}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
