export default {
  for_influencer: "インフルエンサーへの",
  open_door: "扉を開きましょう",
  Start_registration: "登録を始める",
  Twitter: "X (旧Twitter)",
  Google: "Googleで続行",
  Line: "LINEで続行",
  Login_or_Register: "ログインまたは登録",
  welcome: "Buzz Travelerへようこそ",
  register_label: "会員登録",
  register_mess:
    "会員登録頂きありがとうございます\n入力頂いたメールアドレスに、仮パスワードの案内を送りました。\nメールに記載のパスワード変更画面にアクセス頂き、メールに記載の一時パスワードを入力し、パスワードを設定してください。",
  enter_password: "パスワードを入力",
  forgot_password_mess: "パスワード忘れの方はこちらをクリックして下さい",
  change_pass_info_title: "パスワード再設定ご案内",
  change_pass_info_mess:
    "パスワードリセット用のメールを、登録のメールアドレスに送信しました。メールに記載のパスワード変更画面にアクセス頂き、メールに記載の一時パスワードを入力し、新しいパスワードを設定してください",
  change_pass_left_title: "本パスワードを設定して下さい。",
  wrong_format_new_pass:
    "パスワードは半角英数字記号を含む8文字以上64文字以内で入力してください。",
  require_confirm_pass: "新しいパスワード（確認用）を入力してください。",
  tempo_password: "メールに記載されてる仮パスワード",
  password_format_notice:
    "パスワードは半角英数字記号を含む8文字以上64文字以内で入力してください。",
};
