/* eslint-disable react/no-danger */
import React from "react";
import Header from "app/components/common/Header";
import MainContent from "app/components/common/MainContent";
import LayoutMain from "app/layouts/LayoutMain";

function TermOfService() {
  const content = `第一章 総則 <br />
第1条（適用範囲）<br />
1.当社が旅行者との間で締結する手配旅行契約は、この約款の定めるところによります。この約款に定めのない事項については、
 法令又は一般に確立された慣習によります。<br /><br />

2.当社が法令に反せず、かつ、旅行者の不利にならない範囲で書面により特約を結んだときは、前項の規定にかかわらず、その特約が優先します。<br /><br />

第2条（用語の定義）<br />
1.この約款で「手配旅行契約」とは、当社が旅行者の委託により、旅行者のために代理、媒介又は取次をすること等により旅行者が運送・宿泊機関等の提供する運送、
 宿泊その他の旅行に関するサービス（以下「旅行サービス」といいます。）の提供を受けることができるように、手配することを引き受ける契約をいいます。<br /><br />

2.この約款で「国内旅行」とは、本邦内のみの旅行をいい、「海外旅行」とは、国内旅行以外の旅行をいいます。<br /><br />

3.この約款で「旅行代金」とは、当社が旅行サービスを手配するために、運賃、宿泊料その他の運送・宿泊機関等に対して支払う費用及び当社所定の旅行業務取扱料金
 （変更手続料金及び取消手続料金を除きます。）をいいます。<br /><br />

4.この部で「通信契約」とは、当社が提携するクレジットカード会社（以下「提携会社」といいます。）のカード会員との間で電話、郵便、ファクシミリ、インターネットその他の
 通信手段による申込みを受けて締結する手配旅行契約であって、当社が旅行者に対して有する手配旅行契約に基づく旅行代金等に係る債権又は債務を、
 当該債権又は債務が履行されるべき日以降に別に定める提携会社のカード会員規約に従って決済することについて、旅行者があらかじめ承諾し、
 かつ旅行代金等を第十六条第二項又は第五項に定める方法により支払うことを内容とする手配旅行契約をいいます。<br /><br />

5.この約款で「カード利用日」とは、旅行者又は当社が手配旅行契約に基づく旅行代金等の支払又は払戻債務を履行すべき日をいいます`;

  return (
    <LayoutMain>
      <Header />
      <MainContent>
        <div className="grid grid-cols-12 gap-4 mt-24 privacy">
          <div className="col-span-12 lg:col-span-7 xl:col-span-8">
            <div className="flex flex-col h-full">
              <div className="mt-5">
                <div className="flex items-center justify-between text-xl font-bold">
                  <p className="mr-3">利用規約</p>
                </div>
              </div>

              <p
                dangerouslySetInnerHTML={{ __html: content }}
                className="content px-3 my-8 flex-1"
              />
            </div>
          </div>
        </div>
      </MainContent>
    </LayoutMain>
  );
}

export default TermOfService;
