import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Path from "app/route/Path";
import { ErrorMessage, Field, Formik, Form } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import jwtDecode from "jwt-decode";
import {
  emailRegex,
  EResponseStatus,
  passwordRegex,
} from "../../helper/constant";
import AuthService from "../../services/AuthService";
import { setActive } from "../../redux/blockUIReducer";
import { setProfileStore } from "../../redux/profileReducer";
import { setAuthToken, setAuthUser } from "../../redux/authReducer";
import { setUpdateState } from "../../redux/updateInfoReducer";
import { setRouteEmpty } from "../../redux/previousRouteReducer";
import UserService from "../../services/UserService";
import PopupShowMessage from "./PopupShowMessage";
import { setEmail } from "../../redux/emailReducer";

function FormLoginWithEmail({ open, setOpen }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const previousRoute = useSelector((state) => state.previousRoute);
  const email = useSelector((state) => state.email);

  const [openPopupShowMessage, setOpenPopupShowMessage] = useState(false);
  const [errorMess, setErrorMess] = useState("");

  const signInFormInitialValues = {
    email: email || "",
    password: "",
    social_id_provider: "email_influencer",
  };

  const signInValidationSchema = Yup.object().shape({
    email: Yup.string().required().trim().matches(emailRegex),
    password: Yup.string().required().trim().matches(passwordRegex),
  });

  const onLogin = async (values) => {
    dispatch(setActive(true));
    const loginResponse = await AuthService.login(values).catch(() => {
      dispatch(setActive(false));
      setErrorMess(t("common.message.wrong_information"));
      return Promise.reject();
    });

    const user = jwtDecode(loginResponse?.data?.data?.accessToken);
    const token = {
      accessToken: loginResponse?.data?.data?.accessToken,
      refreshToken: loginResponse?.data?.data?.refreshToken,
    };
    dispatch(setAuthUser(user));
    dispatch(setAuthToken(token));
    dispatch(
      setUpdateState(
        loginResponse?.data?.data?.not_updated
          ? loginResponse?.data?.data?.not_updated
          : false
      )
    );
    if (
      loginResponse?.data?.data?.is_first_login ||
      loginResponse?.data?.data?.not_updated
    ) {
      dispatch(setActive(false));
      navigate(Path.register);
    } else {
      navigate(previousRoute || Path.home);
      dispatch(setRouteEmpty());
      dispatch(setEmail(null));
      UserService.getProfile()
        .then((res) => {
          if (res.status === EResponseStatus.SUCCESS) {
            dispatch(setProfileStore(res?.data?.data));
          }
        })
        .finally(() => dispatch(setActive(false)));
    }
  };

  const forgotPassword = () => {
    dispatch(setActive(true));
    const payload = {
      login_id: email,
      social_id_provider: "email_influencer",
    };

    UserService.forgotPassword(payload)
      .then((response) => {
        if (response.status === EResponseStatus.CREATED) {
          setOpenPopupShowMessage(true);
        }
      })
      .finally(() => dispatch(setActive(false)));
  };

  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          setErrorMess("");
          setOpen(false);
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className="flex justify-center items-center h-full p-6">
            <div className="relative max-w-[600px] w-full">
              <div className="border-0 rounded-[4px] shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
                <div className="relative flex items-center justify-center p-6 border-b border-border">
                  <div className="absolute left-6">
                    <IconButton
                      edge="start"
                      color="inherit"
                      onClick={() => {
                        setErrorMess("");
                        setOpen(false);
                      }}
                      aria-label="close"
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                  <p className="font-[700]">{t("auth.enter_password")}</p>
                </div>

                <div className="p-6">
                  <Formik
                    initialValues={signInFormInitialValues}
                    validationSchema={signInValidationSchema}
                    onSubmit={onLogin}
                  >
                    {({ values, handleChange }) => {
                      return (
                        <Form>
                          {errorMess && (
                            <div className="error-msg mb-4">{errorMess}</div>
                          )}
                          <div className="password-field mb-10 mt-6">
                            <Field
                              name="password"
                              type="password"
                              maxLength={64}
                              onChange={(e) => {
                                handleChange(e);
                                setErrorMess("");
                              }}
                              placeholder={t("validation_field.password")}
                              className="w-full bg-gray-50 border border-border text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
                            />
                            <ErrorMessage
                              component="div"
                              name="password"
                              className="error-msg mt-1"
                            />
                          </div>
                          <Button
                            type="submit"
                            disabled={!values?.password}
                            className="!w-full sm:!text-[16px] !text-white !bg-[#0E5CB5] !rounded-[8px]"
                          >
                            {t("common.action.continue")}
                          </Button>

                          <div className="flex items-center justify-center mt-4">
                            <button
                              type="button"
                              className="font-semibold underline text-sm"
                              onClick={forgotPassword}
                            >
                              {t("auth.forgot_password_mess")}
                            </button>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
      <PopupShowMessage
        open={openPopupShowMessage}
        label={t("auth.change_pass_info_title")}
        message={t("auth.change_pass_info_mess")}
      />
    </>
  );
}

export default FormLoginWithEmail;
