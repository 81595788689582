import { EProjectReward } from "app/helper/constant";

export const listRewardType = [
  {
    label: "common.message.all",
    value: "all",
  },
  {
    label: "project.detail.fixed_price",
    value: EProjectReward.FIXED_PRICE,
  },
  {
    label: "project.detail.free_supply",
    value: EProjectReward.FREE,
  },
];

export const listPrMethod = [
  {
    label: "common.message.all",
    value: "all",
  },
  {
    label: "common.message.image",
    value: "image",
  },
  {
    label: "common.message.video",
    value: "video",
  },
];

export const listSNS = [
  {
    label: "common.message.all",
    value: "all",
  },
  {
    label: "Instagram",
    value: "instagram",
  },
  {
    label: "Tiktok",
    value: "tiktok",
  },
  {
    label: "common.message.twitter",
    value: "twitter",
  },
  {
    label: "Facebook",
    value: "facebook",
  },
  {
    label: "Youtube",
    value: "youtube",
  },
];

export const listAgeGroup = [
  {
    label: "10",
    value: "10代",
  },
  {
    label: "20",
    value: "20代",
  },
  {
    label: "30",
    value: "30代",
  },
  {
    label: "40",
    value: "40代",
  },
  {
    label: "50",
    value: "50代",
  },
  {
    label: "60",
    value: "60代以上",
  },
];
