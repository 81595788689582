import { Button } from "@mui/material";
import React, { useRef, useEffect, useState } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setActive } from "app/redux/blockUIReducer";
import { formatHeicFile } from "app/utils";
import {
  APPLIED_PROJECT_STATUS,
  EFileType,
  EResponseStatus,
  MAX_FILE_SIZE,
  MAX_VIDEO_SIZE,
} from "../../helper/constant";
import CommonService from "../../services/CommonService";
import SuccessDialog from "../../components/common/SuccessDialog";
import ErrorDialog from "../../components/common/ErrorDialog";
import InfluencerService from "../../services/InfluencerService";

function ApplyForm({ openForm, applicationId, setOpenForm, statusText }) {
  const formikRef = useRef();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [apply, setApply] = useState(false);

  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [message, setMessage] = useState("");

  // validate
  const validationSchema = Yup.object().shape({
    url: Yup.string().max(1000),
    message: Yup.string().max(1000).required(),
  });
  const initialValues = {
    imageList: [
      {
        id: "img-1",
        file: "",
      },
      {
        id: "img-2",
        file: "",
      },
      {
        id: "img-3",
        file: "",
      },
    ],
    url: "",
    message: "",
    file1: "",
    file2: "",
    file3: "",
  };

  const openSuccessDiaglog = (mess) => {
    setOpenSuccess(true);
    setMessage(mess);
  };

  const openErrorDiaglog = (mess) => {
    setOpenError(true);
    setMessage(mess);
  };

  const onSuccess = () => {
    setOpenSuccess(false);
  };

  const onError = () => {
    setOpenError(false);
  };

  const onChangeUploadFile = (
    e,
    { values, setFieldValue, setFieldError, id, index }
  ) => {
    // Allowing file type
    const allowedExtensions =
      /[^\\/.]+(?=(\.png|\.jpeg|\.jpg|\.pdf|\.heic|\.mp4)$)/;

    const errorFileSizeMess = t("common.message.oversize_file", {
      size: MAX_FILE_SIZE / 1024 / 1024,
    });
    const errorVideoSizeMess = t("common.message.oversize_file", {
      size: MAX_VIDEO_SIZE / 1024 / 1024,
    });
    const errorFormatMess = t("common.message.wrong_format_file", {
      format: "PNG、JPEG、JPG、PDF、HEIC, MP4",
    });
    const files = e.target.files || e.dataTransfer.files;
    const payload = [...(values?.imageList || [])];
    if (!files.length) return;
    if (!allowedExtensions.test(files[0].name.toLowerCase())) {
      setFieldError(id, errorFormatMess);
    } else if (
      files[0].type === "video/mp4" &&
      files[0].size > MAX_VIDEO_SIZE
    ) {
      setFieldError(id, errorVideoSizeMess);
    } else if (
      files[0].type === "application/pdf" &&
      files[0].size > MAX_FILE_SIZE
    ) {
      setFieldError(id, errorFileSizeMess);
    } else if (
      files[0].type !== "video/mp4" &&
      files[0].type !== "application/pdf" &&
      files[0].size > MAX_FILE_SIZE
    ) {
      setFieldError(id, errorFileSizeMess);
    } else {
      const file = formatHeicFile(files[0]);
      const lstShowPreview = ["image/png", "image/jpg", "image/jpeg"];
      payload[index] = {
        id,
        file: file || files[0],
        showPreview: !!(
          files[0]?.type && lstShowPreview.includes(files[0]?.type)
        ),
      };
      setFieldValue("imageList", payload);
    }
  };

  const onSubmit = (values, context) => {
    const lstFile = [];
    values.imageList.forEach((item, index) => {
      if (item.file) {
        lstFile.push({
          id: index + 1,
          file: item.file,
        });
      }
    });
    if (!lstFile.length) {
      dispatch(setActive(true));
      setApply(true);
    } else {
      dispatch(setActive(true));
      const listPromise = [];
      lstFile.forEach((element) => {
        const format = element.file.name.substring(
          element.file.name.lastIndexOf(".") + 1
        );
        let fileType = EFileType.IMAGE;
        if (format === EFileType.MP4) {
          fileType = EFileType.VIDEO;
        }
        if (format === EFileType.PDF) {
          fileType = EFileType.PDF;
        }
        const body = {
          file: element.file,
          type: fileType,
        };
        listPromise.push(
          CommonService.uploadFile(body).catch(() => {
            dispatch(setActive(false));
            openErrorDiaglog(t("common.message.update_fail"));
            return Promise.reject();
          })
        );
      });
      Promise.all([...listPromise]).then((result) => {
        result.forEach((item, index) => {
          context.setFieldValue(
            `file${index + 1}`,
            item.data.data.thumbnail_url || item.data.data.media_url
          );
        });
        setApply(true);
      });
    }
  };

  const sendDeliverable = (values) => {
    const files = [values.file1, values.file2, values.file3];
    const body = {
      deliverable: {
        deliverables: {
          web: [values.url],
          files: files.filter((item) => item),
        },
        message: values.message,
      },
    };
    InfluencerService.sendDeliverable(applicationId, body)
      .then((res) => {
        if (res.status === EResponseStatus.CREATED) {
          openSuccessDiaglog(t("project.apply.delivery_success"));
          setOpenForm(false);
        }
      })
      .catch(() => openErrorDiaglog(t("common.message.update_fail")))
      .finally(() => {
        dispatch(setActive(false));
        setApply(false);
      });
  };

  useEffect(() => {
    if (formikRef && formikRef.current) {
      const { values } = formikRef.current;
      if (apply) {
        sendDeliverable(values);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apply]);

  return (
    <div>
      {(openForm ||
        (statusText === APPLIED_PROJECT_STATUS.IN_PROGRESS && openForm)) && (
        <Formik
          innerRef={formikRef}
          validateOnMount
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {(prop) => {
            const { values, setFieldValue, setFieldError, errors } = prop;
            return (
              <Form>
                <div>
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
                    <div className="custom_grid grid grid-cols-1 md:!grid-cols-1 gap-5">
                      {values?.imageList?.map(({ id }, index) => {
                        return (
                          <div key={id} className="upload-file md:!block">
                            <div className="relative pt-[130%] md:!w-[70%] md:!pt-[90%] file-item">
                              <label
                                htmlFor={id}
                                className="absolute top-0 w-full h-full bg-secondary-300 flex justify-center items-center cursor-pointer"
                              >
                                {values?.imageList[index].file ? (
                                  <div className="w-full h-full">
                                    {values.imageList[index].showPreview ? (
                                      <img
                                        src={URL.createObjectURL(
                                          new Blob([
                                            values.imageList[index].file,
                                          ])
                                        )}
                                        className="w-full h-full object-cover"
                                        alt=""
                                      />
                                    ) : (
                                      <div className="absolute top-0 w-full h-full border border-secondary-200 bg-white flex items-center justify-center word-break px-3 font-bold">
                                        {values.imageList[index].file.name}
                                      </div>
                                    )}
                                  </div>
                                ) : (
                                  <div className="flex justify-center items-center">
                                    <i className="text-[30px] fa-solid fa-plus" />
                                  </div>
                                )}
                                <input
                                  className="w-0 h-0 overflow-hidden"
                                  onClick={(e) => {
                                    e.target.value = null;
                                  }}
                                  onChange={(e) =>
                                    onChangeUploadFile(e, {
                                      values,
                                      setFieldValue,
                                      setFieldError,
                                      id,
                                      index,
                                    })
                                  }
                                  id={id}
                                  type="file"
                                  name={id}
                                />
                              </label>
                            </div>
                            {errors[id] ? (
                              <p className="error-msg md:w-[70%] pt-2">
                                {errors[id]}
                              </p>
                            ) : null}
                          </div>
                        );
                      })}
                    </div>

                    <div className="flex flex-col gap-8 md:col-start-2 md:col-end-4">
                      <div className="w-full">
                        <Field
                          name="url"
                          className="max-w-full w-full rounded-[8px] border border-solid border-secondary-200 px-3 py-2"
                          placeholder={t("project.apply.url_placeholder")}
                          maxLength={1000}
                        />
                        <ErrorMessage
                          component="div"
                          name="url"
                          className="error-msg"
                        />
                      </div>

                      <div className="w-full flex-1">
                        <Field
                          as="textarea"
                          name="message"
                          className="max-w-full w-full h-full rounded-[8px] min-h-[200px] border border-solid border-secondary-200 resize-none px-3 py-2"
                          placeholder={t("project.apply.message_placeholder")}
                          maxLength={1000}
                        />
                        <ErrorMessage
                          component="div"
                          name="message"
                          className="error-msg"
                        />
                      </div>

                      <div className="flex justify-center">
                        <Button
                          type="submit"
                          className="!text-white !min-w-[200px] !rounded-[8px] !px-4 !py-2"
                          classes={{
                            root: "!bg-black",
                          }}
                        >
                          {t('common.action.submit')}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      )}

      <SuccessDialog
        message={message}
        open={openSuccess}
        onClose={onSuccess}
        onOK={onSuccess}
      />
      <ErrorDialog
        message={message}
        open={openError}
        onClose={onError}
        onOK={onError}
      />
    </div>
  );
}

export default ApplyForm;
