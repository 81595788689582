import React, { useEffect, useRef } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Tooltip,
} from "@mui/material";
import { useTranslation } from "react-i18next";

function PopupNotify({ open, onClose, notification }) {
  const { t } = useTranslation();
  const notifyTitle = useRef(null);

  useEffect(() => {
    if (notification) {
      const element = notifyTitle?.current;
      const parentElement = notifyTitle?.current?.parentElement;
      if (element?.clientWidth > parentElement.clientWidth - 70) {
        element?.classList?.add("truncate");
        element?.classList?.add("ml-5");
      } else {
        element?.classList?.remove("truncate");
        element?.classList?.remove("ml-5");
      }
    }
  }, [notification]);

  return (
    <Dialog
      keepMounted
      open={open}
      onClose={onClose}
      classes={{
        paper: "!min-h-[200px] !max-h-[500px] !rounded-[12px] !w-[450px]",
      }}
    >
      <DialogActions className="!px-4 !justify-center relative">
        <button
          type="button"
          className="w-8 h-8 flex items-center justify-center absolute left-1 text-black"
          onClick={onClose}
        >
          <i className="fa-solid fa-xmark" />
        </button>
        <Tooltip title={notification?.title || ""}>
          <p ref={notifyTitle} className="font-semibold whitespace-nowrap">
            {notification?.title}
          </p>
        </Tooltip>
      </DialogActions>
      <DialogContent className="!flex flex-col !pt-3">
        {notification?.message}
      </DialogContent>
      <DialogActions className="!justify-center !px-4">
        <Button
          type="button"
          onClick={onClose}
          className="!text-white !min-w-[70px] !rounded-[8px] !py-2"
          classes={{
            root: "!bg-black",
          }}
        >
          {t("common.action.close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default PopupNotify;
