import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Path from "app/route/Path";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import LayoutMain from "app/layouts/LayoutMain";
import MainContent from "app/components/common/MainContent";
import SuccessDialog from "app/components/common/SuccessDialog";
import ErrorDialog from "app/components/common/ErrorDialog";
import AuthService from "app/services/AuthService";
import { logout } from "app/utils";
import SNSConnected from "./SNSConnected";
import ChangePassword from "./ChangePassword";
import ChangeEmail from "./ChangeEmail";

const relativeTime = require("dayjs/plugin/relativeTime");

dayjs.extend(relativeTime);

function LoginSecurity() {
  const { t } = useTranslation();
  const [loginInfo, setLoginInfo] = useState({
    email: "",
    password_update_at: "",
  });

  const [showChangePassword, setShowChangePassword] = useState(false);
  const [showChangeLoginId, setShowChangeLoginId] = useState(false);
  const [errorMess, setErrorMess] = useState("");
  const [successMess, setSuccessMess] = useState("");

  const { login_id: loginId, social_id_provider: socialIdProvider } =
    useSelector((state) => state.auth.user);

  useEffect(() => {
    AuthService.getEmailToLoginUser().then((response) => {
      if (response.status === 200) {
        setLoginInfo(response?.data?.data);
      }
    });
  }, []);

  const onSuccess = (e) => {
    if (
      socialIdProvider === "email_influencer" &&
      loginId !== loginInfo.email
    ) {
      logout(e);
    } else {
      setSuccessMess("");
    }
  };

  return (
    <LayoutMain>
      <MainContent>
        <div className="h-full w-full">
          <div className="w-full px-x max-w-[1080px] mx-auto">
            <div className="w-full pt-5 pb-4">
              <ol className="text-sm leading-[18px] text-REGULAR_TEXT_COLOR font-semibold">
                <li className="inline-block hover:underline">
                  <Link to={Path.account_settings}>
                    <span>{t("header.account_settings")}</span>
                  </Link>
                </li>
                <li className="inline-block">
                  <div className="inline-block mx-4">
                    <svg
                      fill="#767676"
                      viewBox="0 0 18 18"
                      style={{ height: 10, width: 10 }}
                    >
                      <path
                        fillRule="#767676"
                        d="M4.29 1.71A1 1 0 115.71.3l8 8a1 1 0 010 1.41l-8 8a1 1 0 11-1.42-1.41l7.29-7.29z"
                      />
                    </svg>
                  </div>
                  <span>
                    {t("accountSetting.personal_info.login_security")}
                  </span>
                </li>
              </ol>

              <h1 className="text-[32px] leading-9 text-REGULAR_TEXT_COLOR font-extrabold mt-3">
                {t("accountSetting.personal_info.login_security")}
              </h1>
            </div>

            <div className="w-full h-auto flex">
              <div className="w-full md:w-7/12">
                <div className="w-full h-auto">
                  <div className="!px-0">
                    <div className="w-full h-auto text-[#484848]">
                      <div className="w-full">
                        <h1 className="text-2xl leading-[30px] font-extrabold pt-8 pb-6">
                          {t("accountSetting.login_security.auth_form")}
                        </h1>
                      </div>
                      <div className="w-full h-auto py-6 border-b border-border">
                        <div className="w-full flex items-start justify-between">
                          <div className="flex-1 flex flex-col text-base">
                            <div className="w-full font-semibold">
                              {t("accountSetting.login_security.password")}
                            </div>
                          </div>

                          <div>
                            <button
                              type="button"
                              className={`text-[#008489] font-semibold ${
                                !loginInfo.email
                                  ? "cursor-not-allowed"
                                  : "hover:underline cursor-pointer"
                              }`}
                              disabled={!loginInfo.email}
                              onClick={() =>
                                setShowChangePassword(!showChangePassword)
                              }
                            >
                              {showChangePassword
                                ? t("common.action.cancel")
                                : t("common.action.update")}
                            </button>
                          </div>
                        </div>
                        <div className="w-full">
                          {!showChangePassword ? (
                            <div className="w-full font-normal mt-2 ">
                              {t(
                                "accountSetting.login_security.password_last_update"
                              )}
                              {loginInfo.password_update_at
                                ? dayjs(loginInfo.password_update_at).fromNow()
                                : t("common.message.no_input_yet")}
                            </div>
                          ) : (
                            <ChangePassword
                              setShowChangePassword={setShowChangePassword}
                              setErrorMess={setErrorMess}
                              setSuccessMess={setSuccessMess}
                              setLoginInfo={setLoginInfo}
                            />
                          )}
                        </div>
                      </div>

                      <div className="w-full h-auto py-6 border-b border-border">
                        <div className="w-full flex items-start justify-between">
                          <div className="flex-1 flex flex-col text-base">
                            <div className="w-full font-semibold">
                              {t("validation_field.email")}
                            </div>
                          </div>

                          <div>
                            <button
                              type="button"
                              onClick={() =>
                                setShowChangeLoginId(!showChangeLoginId)
                              }
                              className="text-[#008489] font-semibold"
                            >
                              {showChangeLoginId
                                ? t("common.action.cancel")
                                : t("common.action.update")}
                            </button>
                          </div>
                        </div>
                        <div className="w-full">
                          {!showChangeLoginId ? (
                            <div className="w-full font-normal mt-2 ">
                              {loginInfo.email ? (
                                <span>{loginInfo.email}</span>
                              ) : (
                                <span>{t("common.message.no_input_yet")}</span>
                              )}
                            </div>
                          ) : (
                            <ChangeEmail
                              email={loginInfo.email}
                              setErrorMess={setErrorMess}
                              setSuccessMess={setSuccessMess}
                              setShowChangeLoginId={setShowChangeLoginId}
                              setLoginInfo={setLoginInfo}
                            />
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="w-full h-auto text-[#484848]">
                      <div className="w-full">
                        <h1 className="text-2xl leading-[30px] text- font-extrabold pt-8 pb-6">
                          {t("accountSetting.login_security.social_account")}
                        </h1>
                      </div>
                      <SNSConnected />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </MainContent>
      <SuccessDialog
        message={successMess}
        open={!!successMess}
        onClose={(e) => onSuccess(e)}
        onOK={(e) => onSuccess(e)}
      />
      <ErrorDialog
        message={errorMess}
        open={!!errorMess}
        onClose={() => setErrorMess("")}
        onOK={() => setErrorMess("")}
      />
    </LayoutMain>
  );
}

export default LoginSecurity;
