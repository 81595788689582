import React, { useState, useEffect, useContext } from "react";
import Drawer from "@mui/material/Drawer";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { LayoutContext, lstLanguage } from "app/utils";

function ChangeLanguage({ setFilterValues }) {
  const { t } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const { isChangeLangOpen, setIsChangeLangOpen } = useContext(LayoutContext);

  useEffect(() => {
    setSelectedLanguage(i18next.resolvedLanguage);
  }, []);

  const changeTheLanguage = (language) => {
    i18next.changeLanguage(language?.value); // i18next function to change language
    setSelectedLanguage(language?.value);
    dayjs.locale(language?.locale); // change locale dayjs
    setIsChangeLangOpen(false);
    if (setFilterValues) {
      setFilterValues((prev) => ({
        ...prev,
        locale: prev.locale ? language?.value : "",
      }));
    }
  };

  return (
    <Drawer
      anchor="bottom"
      open={isChangeLangOpen}
      onClose={() => setIsChangeLangOpen(false)}
      className="!z-[1400]"
      classes={{
        paper:
          "!h-[90%] !w-[80%] !mx-auto !max-w-[800px] !top-[5%] rounded-[12px] !bg-white",
      }}
      transitionDuration={600}
    >
      <header className="px-6 py-4 flex items-center">
        <button
          type="button"
          onClick={() => setIsChangeLangOpen(false)}
          className="w-7 h-7"
        >
          <i className="text-[20px] fa-solid fa-xmark" />
        </button>
      </header>
      <main className="flex-1 px-6 overflow-auto">
        <div className="pb-2 border-b border-solid border-secondary-300">
          <button type="button" className="relative">
            <p className="p-2 -mx-2 hover:bg-secondary rounded-[8px]">
              {t("change_language")}
            </p>
            <div className="absolute bottom-[-8px] h-[2px] w-[90%] bg-black left-1/2 -translate-x-1/2" />
          </button>
        </div>
        <div className="py-8">
          <p className="text-[22px] font-[700] mb-4">
            {t("common.message.select_lang")}
          </p>
          <div className="grid md:grid-cols-3 lg:grid-cols-4 gap-4">
            {lstLanguage.map((language) => {
              return (
                <button
                  key={language.id}
                  type="button"
                  onClick={() => changeTheLanguage(language)}
                  className={`text-[14px] text-left hover:bg-secondary p-2 rounded-[8px] ${
                    selectedLanguage === language.value
                      ? "border border-solid border-secondary-200 "
                      : ""
                  }`}
                >
                  <p>{language.title}</p>
                  <p className="text-secondary-400">{language.note}</p>
                </button>
              );
            })}
          </div>
        </div>
      </main>
    </Drawer>
  );
}

export default ChangeLanguage;
