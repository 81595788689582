/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable camelcase */
import React, { useRef, useState, useEffect, useCallback } from "react";
import Chatbox from "app/chat/components/Chatbox";
import { useTranslation } from "react-i18next";
import ChatService from "app/services/chat/api/ChatService";
import { Button, Popover } from "@mui/material";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import {
  APPLIED_PROJECT_STATUS,
  EResponseStatus,
  INFLUENCER_APPLIED_FOR_AD_PROJECT_STATUS,
  PROJECT_APPLIED_TYPE,
  PROJECT_AD_STATUS,
} from "app/helper/constant";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import { getProjectDetailByLanguage, getUpdatedByText } from "app/utils";
import Path from "../../route/Path";
import InfluencerService from "../../services/InfluencerService";
import ApplyForm from "./ApplyForm";
import {
  socketIO,
  SOCKET_LISTENER,
  SOCKET_NAMESPACE,
  SOCKET_TYPE,
} from "../../context/socket";
import Media from "../../components/Media";
import ErrorDialog from "../../components/common/ErrorDialog";
import { SEND_BY } from "../../chat/enum/ChatEnum";

function AccountInfo(props) {
  const { data, onCloseDrawer, setStatusApplied } = props;
  const { t } = useTranslation();
  const accountInfoRef = useRef();
  const {
    project_media,
    application_id,
    ad_project_id,
    visibility_and_status,
  } = data || {};
  const [openError, setOpenError] = useState(false);
  const [message, setMessage] = useState("");
  const [commentFromAdmin, setCommentFromAdmin] = useState({});

  // loading
  const [isLoading, setIsLoading] = useState(true);

  // popover state
  const [anchorEl, setAnchorEl] = useState(null);
  const [replyAnchorEl, setReplyAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const openReply = Boolean(replyAnchorEl);

  // chat
  const [apiMessages, setApiMessages] = useState([]);
  const [isLastPage, setIsLastPage] = useState(true);

  // pagination
  const [page, setPage] = useState(1);

  const [statusText, setStatusText] = useState("");
  const [projectType, setProjectType] = useState(null);
  const [hideButton, setHideButton] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [minHeight, setMinHeight] = useState(500);

  const [isNotFound, setIsNotFound] = useState(false);

  const validationSchema1 = Yup.object().shape({
    reason: Yup.string().required(),
  });
  const initialValues1 = {
    reason: "",
  };

  const openErrorDiaglog = (mess) => {
    setOpenError(true);
    setMessage(mess);
  };

  // get first 5 messages
  const getFiveMessages = useCallback(() => {
    setIsLoading(true);
    const limit = 5;
    ChatService.getAllMessages({ chatRoomId: application_id, page, limit })
      .then((res) => {
        if (res.data && res.data.items) {
          setApiMessages(res.data.items.reverse());
          setIsLastPage(res.data.meta.currentPage === res.data.meta.totalPages);
        }
      })
      .finally(() => setIsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [application_id]);

  // get detail project
  useEffect(() => {
    InfluencerService.getDetailProjectApplied(ad_project_id)
      .then((res) => {
        if (res.status === EResponseStatus.SUCCESS) {
          const applied_status = res?.data?.data?.applied_status;
          const status_change_log = res?.data?.data?.applied_status_change_log;
          const deliverable_status_change_log =
            res?.data?.data?.deliverable_status_change_log;
          const project_type = res?.data?.data?.project_type;
          if (
            applied_status !== APPLIED_PROJECT_STATUS.WAIT_APPROVAL_RECRUITMENT
          ) {
            getFiveMessages();
          }
          setStatusText(applied_status);
          setStatusApplied(applied_status);
          setProjectType(project_type);
          setIsNotFound(false);
          let array = [];
          if (applied_status === APPLIED_PROJECT_STATUS.COMPLETED) {
            array = status_change_log?.filter(
              (item) =>
                item.status ===
                INFLUENCER_APPLIED_FOR_AD_PROJECT_STATUS.FINISHED
            );
          }
          if (applied_status === APPLIED_PROJECT_STATUS.CANCEL_TRANSACTION) {
            array = status_change_log?.filter(
              (item) =>
                item.status ===
                INFLUENCER_APPLIED_FOR_AD_PROJECT_STATUS.CANCELED
            );
          }
          if (applied_status === APPLIED_PROJECT_STATUS.REJECT_REPORT) {
            array = deliverable_status_change_log?.filter(
              (item) =>
                item.status ===
                INFLUENCER_APPLIED_FOR_AD_PROJECT_STATUS.REJECTED
            );
          }
          if (
            !applied_status &&
            project_type === PROJECT_APPLIED_TYPE.SPECIAL_OFFER
          ) {
            array = status_change_log?.filter(
              (item) =>
                item.status === INFLUENCER_APPLIED_FOR_AD_PROJECT_STATUS.REVIEW
            );
          }
          setCommentFromAdmin(array.length ? array[array.length - 1] : "");
        }
      })
      .catch((error) => {
        if (error.response.status === EResponseStatus.NOT_FOUND) {
          if (
            ![PROJECT_AD_STATUS.Private, PROJECT_AD_STATUS.Trash].includes(
              visibility_and_status
            )
          ) {
            openErrorDiaglog(t("common.message.project_not_found"));
          } else {
            setIsNotFound(true);
          }
        } else {
          openErrorDiaglog(t("common.message.cannot_display_data"));
          setIsNotFound(true);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ad_project_id, getFiveMessages]);

  useEffect(() => {
    const socket = socketIO(SOCKET_TYPE.ITCL, SOCKET_NAMESPACE.BOOKING, {
      room_id: application_id,
    });
    socket.on(SOCKET_LISTENER.UPDATE_APPLY_PROJECT_STATUS, (response) => {
      const applied_status_change_log = response?.applied_status_change_log;
      const deliverable_status_change_log =
        response?.deliverable_status_change_log;
      let array = [];
      if (response.status === APPLIED_PROJECT_STATUS.COMPLETED) {
        array = applied_status_change_log?.filter(
          (item) =>
            item.status === INFLUENCER_APPLIED_FOR_AD_PROJECT_STATUS.FINISHED
        );
      }
      if (response.status === APPLIED_PROJECT_STATUS.CANCEL_TRANSACTION) {
        array = applied_status_change_log?.filter(
          (item) =>
            item.status === INFLUENCER_APPLIED_FOR_AD_PROJECT_STATUS.CANCELED
        );
      }
      if (response.status === APPLIED_PROJECT_STATUS.REJECT_REPORT) {
        array = deliverable_status_change_log?.filter(
          (item) =>
            item.status === INFLUENCER_APPLIED_FOR_AD_PROJECT_STATUS.REJECTED
        );
      }
      setCommentFromAdmin(array.length ? array[array.length - 1] : "");
      setStatusText(response.status);
    });

    return () => {
      socket.off(SOCKET_LISTENER.UPDATE_APPLY_PROJECT_STATUS);
    };
  }, [application_id]);

  useEffect(() => {
    if (openForm) {
      setMinHeight("unset");
    } else {
      setMinHeight(500);
    }
  }, [openForm]);

  const handleOpen = (event) => {
    setAnchorEl(event.target);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleReasonOpen = (event) => {
    setReplyAnchorEl(event.target);
  };

  const handleReasonClose = (resetForm) => {
    setReplyAnchorEl(null);
    resetForm();
  };

  const changeStatusApplied = ({ body, callbackSuccess }) => {
    InfluencerService.changeStatusApplied(ad_project_id, body)
      .then((res) => {
        if (res.status === EResponseStatus.CREATED) {
          if (callbackSuccess) {
            callbackSuccess();
          }
        }
      })
      .catch(() => {
        // handle error
      });
  };

  const acceptOffer = () => {
    const body = {
      status: INFLUENCER_APPLIED_FOR_AD_PROJECT_STATUS.ACCEPTED,
      message: "",
    };
    changeStatusApplied({
      body,
      callbackSuccess: () => setOpenForm(true),
    });
  };

  const cancelProject = (values, context) => {
    const { resetForm } = context;
    const body = {
      status: INFLUENCER_APPLIED_FOR_AD_PROJECT_STATUS.CANCELED,
      message: values.reason,
    };

    changeStatusApplied({
      body,
      callbackSuccess: () => {
        handleReasonClose(resetForm);
        setOpenForm(false);
        setHideButton(true);
      },
    });
  };

  const getAllMessages = () => {
    setIsLoading(true);
    const limit = 20;
    ChatService.getAllMessages({ chatRoomId: application_id, page, limit })
      .then((res) => {
        if (res.data && res.data.items) {
          setApiMessages(res.data.items.reverse());
          setIsLastPage(res.data.meta.currentPage === res.data.meta.totalPages);
        }
      })
      .finally(() => setIsLoading(false));
  };

  const handleUnreadMessage = (newMess) => {
    if (newMess?.send_by !== SEND_BY.INFLUENCER) {
      InfluencerService.setReadMsg(newMess.chat_room_id).then(() => {});
    }
  };

  return (
    <div
      ref={accountInfoRef}
      className="relative w-full max-w-[960px] h-[100vh] overflow-auto"
    >
      <div className="sticky top-0 bg-white z-20 flex justify-between px-[24px] py-[8px] border-b-[1px] border-border">
        <button
          type="button"
          className="flex items-center"
          onClick={onCloseDrawer}
        >
          <i className="fa-solid fa-chevron-right" />
        </button>
        {isNotFound ? (
          <button
            type="button"
            className="px-[16px] py-[4px] rounded-[8px] text-[#3B7DC5] border border-[#3B7DC5]"
            onClick={() =>
              openErrorDiaglog(t("common.message.project_not_found"))
            }
          >
            {t("project.chat.open_project_detail")}
          </button>
        ) : (
          <Link
            to={Path.project_detail(ad_project_id)}
            target="_blank"
            className="px-[16px] py-[4px] rounded-[8px] text-[#3B7DC5] border border-[#3B7DC5]"
          >
            {t("project.chat.open_project_detail")}
          </Link>
        )}
      </div>
      <div className="flex flex-col">
        <div className="px-[16px] md:px-6 py-[16px] border-b border-border">
          <div className="flex flex-wrap items-center justify-between mx-[20px]">
            <div className="flex flex-wrap sm:flex-nowrap items-center gap-3">
              <div className="w-[100px] h-[56px] flex-shrink-0">
                {(() => {
                  const isVideoProps = `${
                    project_media && project_media[0]
                      ? project_media[0].thumbnail_url
                      : ""
                  }`
                    ?.toLowerCase()
                    ?.includes(".mp4");
                  return (
                    <Media
                      data={
                        project_media && project_media[0]
                          ? project_media[0].thumbnail_url
                          : ""
                      }
                      isVideoProps={isVideoProps}
                      className="w-full h-full"
                      videoClass="object-cover w-full h-full"
                      imageClassName="object-cover w-full h-full"
                    />
                  );
                })()}
              </div>

              <p className="font-semibold word-break">
                {getProjectDetailByLanguage(data)?.title}
              </p>
            </div>
          </div>
        </div>

        <div
          className="px-4 md:px-[54px] pt-[24px] pb-10 border-b border-border"
          style={{ minHeight }}
        >
          {statusText !== APPLIED_PROJECT_STATUS.CANCEL_TRANSACTION &&
            statusText !== APPLIED_PROJECT_STATUS.COMPLETED && (
              <div className="flex justify-end mb-6">
                <div
                  onClick={(event) => {
                    if (statusText !== APPLIED_PROJECT_STATUS.DECLINED) {
                      handleOpen(event);
                    }
                  }}
                  role="presentation"
                  className="cursor-pointer w-9 h-6 flex justify-end relative"
                >
                  <i className="fa-solid fa-ellipsis-vertical" />
                </div>
              </div>
            )}

          {statusText !== APPLIED_PROJECT_STATUS.CANCEL_TRANSACTION &&
            statusText !== APPLIED_PROJECT_STATUS.COMPLETED && (
              <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <div className="min-w-[200px] py-3">
                  <button
                    type="button"
                    onClick={(event) => handleReasonOpen(event)}
                    className="hover:bg-secondary text-left w-full px-3 py-2"
                  >
                    {t("project.chat.cancel_transaction")}
                  </button>

                  <Formik
                    initialValues={initialValues1}
                    validationSchema={validationSchema1}
                    onSubmit={cancelProject}
                  >
                    {(prop) => {
                      const { resetForm, handleSubmit } = prop;
                      return (
                        <Form>
                          {/* Reply Popover */}
                          <Popover
                            open={openReply}
                            anchorEl={replyAnchorEl}
                            onClose={() => handleReasonClose(resetForm)}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                          >
                            <div className="p-4">
                              <div className="flex items-center justify-between mb-3">
                                <label htmlFor="reason">
                                  {t("validation_field.reason")}
                                </label>
                                <i
                                  role="presentation"
                                  onClick={() => handleReasonClose(resetForm)}
                                  className="p-2 -m-2 fa-solid fa-xmark cursor-pointer"
                                />
                              </div>
                              <Field
                                as="textarea"
                                id="reason"
                                name="reason"
                                className="max-w-full w-[400px] h-[200px] border border-solid border-secondary-200 resize-none px-3 py-2"
                              />
                              <ErrorMessage
                                component="div"
                                name="reason"
                                className="error-msg"
                              />
                              <div className="flex items-center justify-between pt-3">
                                <button
                                  type="button"
                                  onClick={() => handleReasonClose(resetForm)}
                                  className="underline font-[700]"
                                >
                                  {t("common.action.cancel")}
                                </button>
                                <Button
                                  onClick={handleSubmit}
                                  className="!text-white !min-w-[70px] !rounded-[4px] !py-2"
                                  classes={{
                                    root: "!bg-black",
                                  }}
                                >
                                  {t("OK")}
                                </Button>
                              </div>
                            </div>
                          </Popover>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </Popover>
            )}

          {(() => {
            if (
              projectType === PROJECT_APPLIED_TYPE.SPECIAL_OFFER &&
              !statusText
            ) {
              return (
                <div className="flex items-center justify-center">
                  <Button
                    variant="contained"
                    onClick={acceptOffer}
                    className="!bg-black !text-white !py-2 !px-3 !rounded-[8px] !font-bold !min-w-[116px]"
                  >
                    {t("project.chat.accept_special_offer")}
                  </Button>
                </div>
              );
            }
            if (
              (statusText === APPLIED_PROJECT_STATUS.IN_PROGRESS ||
                statusText === APPLIED_PROJECT_STATUS.REJECT_REPORT) &&
              !openForm &&
              !hideButton
            ) {
              return (
                <div className="flex items-center justify-center">
                  <Button
                    variant="contained"
                    onClick={() => setOpenForm(true)}
                    className="!bg-black !text-white !py-2 !px-3 !rounded-[8px] !font-bold !min-w-[116px]"
                  >
                    {t("project.chat.start_report_btn")}
                  </Button>
                </div>
              );
            }
            return null;
          })()}

          {statusText === APPLIED_PROJECT_STATUS.COMPLETED && (
            <div className="rounded-[4px] bg-[#eee] py-[20px] px-[20px] max-w-[550px] m-auto text-center">
              <i className="fa-solid fa-clipboard-check text-[#838383] mx-[8px]" />
              {t("project.chat.completed_mess_date", {
                date: dayjs(commentFromAdmin?.changed_at).format(
                  "YYYY/MM/DD HH:mm"
                ),
                from: getUpdatedByText(commentFromAdmin.ad_group),
              })}
            </div>
          )}

          <h1 className="text-center my-[24px] font-semibold text-[22px]">
            {(() => {
              if (
                statusText === APPLIED_PROJECT_STATUS.WAIT_APPROVAL_RECRUITMENT
              ) {
                return t("project.chat.waiting_application_approval_mess");
              }
              if (
                statusText === APPLIED_PROJECT_STATUS.WAIT_APPROVAL_FINISHED
              ) {
                return t("project.chat.waiting_report_approval_mess");
              }
              if (statusText === APPLIED_PROJECT_STATUS.COMPLETED) {
                return t("project.chat.completed_mess");
              }
              if (statusText === APPLIED_PROJECT_STATUS.DECLINED) {
                return t("project.chat.decline_mess");
              }
              if (statusText === APPLIED_PROJECT_STATUS.REJECT_REPORT) {
                return t("project.chat.reject_report_mess");
              }
              if (statusText === APPLIED_PROJECT_STATUS.CANCEL_TRANSACTION) {
                return t("project.chat.cancel_mess");
              }
              return null;
            })()}
          </h1>

          {(statusText === APPLIED_PROJECT_STATUS.COMPLETED ||
            (!openForm &&
              statusText === APPLIED_PROJECT_STATUS.REJECT_REPORT) ||
            statusText === APPLIED_PROJECT_STATUS.CANCEL_TRANSACTION ||
            (!statusText &&
              projectType === PROJECT_APPLIED_TYPE.SPECIAL_OFFER)) && (
            <p className="word-break whitespace-pre-wrap">
              {commentFromAdmin?.message}
            </p>
          )}

          <ApplyForm
            openForm={openForm}
            statusText={statusText}
            applicationId={application_id}
            setOpenForm={(value) => setOpenForm(value)}
          />
        </div>

        {(() => {
          // status === APPCEPT
          if (
            statusText === APPLIED_PROJECT_STATUS.WAIT_APPROVAL_RECRUITMENT ||
            statusText === APPLIED_PROJECT_STATUS.CANCEL_TRANSACTION ||
            statusText === APPLIED_PROJECT_STATUS.DECLINED
          ) {
            return null;
          }
          return (
            <div className="flex-1">
              <Chatbox
                chatRoomId={application_id}
                isLastPage={isLastPage}
                isLoading={isLoading}
                isNotFound={isNotFound}
                apiMessages={apiMessages}
                scrollRef={
                  accountInfoRef.current ? accountInfoRef.current : undefined
                }
                messageInputClassName="max-w-[960px]"
                wantScrollToBottomFirstLoad={false}
                statusText={statusText}
                handleUnreadMessage={handleUnreadMessage}
                listMessageClassName="flex flex-col justify-end"
                handleGetMoreMessages={() => {
                  setPage(page + 1);
                  getAllMessages();
                }}
                allowHandleMarginBottom
              />
            </div>
          );
        })()}
      </div>
      <ErrorDialog
        message={message}
        open={openError}
        onClose={() => {
          setOpenError(false);
          onCloseDrawer();
        }}
        onOK={() => {
          setOpenError(false);
          onCloseDrawer();
        }}
      />
    </div>
  );
}

export default AccountInfo;
