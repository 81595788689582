import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { setRoute } from "../redux/previousRouteReducer";
import Path from "./Path";

export default function AuthRoute() {
  const token = useSelector((state) => state.auth.token?.accessToken);
  const notUpdate = useSelector((state) => state.notUpdate);
  const location = useLocation();
  const dispatch = useDispatch();

  if (token) {
    if (location.pathname === Path.register) {
      if (notUpdate) {
        return <Outlet />;
      }
      return <Navigate to={Path.home} />;
    }
    if (notUpdate === false) {
      return <Outlet />;
    }
    return <Navigate to={Path.register} replace />;
  }

  // Redirect them to the /login page, but save the current location they were
  // trying to go to when they were redirected. This allows us to send them
  // along to that page after they login, which is a nicer user experience
  // than dropping them off on the home page.
  const name = encodeURIComponent(location.pathname);
  dispatch(setRoute(location.pathname));
  return (
    <Navigate
      to={`login?redirect=${name}`}
      state={{ from: location }}
      replace
    />
  );
}
