import React from "react";

const pageLogo = require("app/assets/images/buzz-logo.png");

function SplitBackground({ title }) {
  return (
    <div className="md:h-auto h-[50vh] w-full md:w-[50%] bg-gradient-to-t from-[#0E5CB5] to-[#fff] relative px-4">
      <div className="absolute py-4 logo h-[90px]">
        <img src={pageLogo} alt="" className="h-full" />
      </div>
      <div className="flex items-center justify-center h-full font-[700] text-[24px] md:text-[30px]">
        {title}
      </div>
    </div>
  );
}

export default SplitBackground;
