/* eslint-disable class-methods-use-this */
import client from "./client";
import CommonService from "./CommonService";

const baseUrl = "auth";

class AuthService {
  login(data) {
    return CommonService.postModel(`${baseUrl}/sign-in`, data);
  }

  signUp(data) {
    return CommonService.postModel(`${baseUrl}/influencer/sign-up`, data);
  }

  loginWithSocial(platform, data) {
    return CommonService.postModel(`${baseUrl}/${platform}/redirect`, data);
  }

  getAllSnsConnect() {
    return CommonService.getModelList(`${baseUrl}/get-all-service-provider`);
  }

  connectWithSocial(platform, data) {
    return CommonService.postModel(`${baseUrl}/${platform}/link-account`, data);
  }

  disconnectWithSocial(social) {
    return client.put(`${baseUrl}/unlink-account/${social}`);
  }

  getEmailToLoginUser() {
    return client.get(`${baseUrl}/email-to-login`);
  }

  changeLoginId(data) {
    return client.post(`${baseUrl}/email-to-login/create-or-update`, data);
  }
}

export default new AuthService();
