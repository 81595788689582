// i-002-003
import React, { useEffect, useRef, useState } from "react";
import LayoutMain from "app/layouts/LayoutMain";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import InfluencerService from "app/services/InfluencerService";
import { EResponseStatus, MAX_FILE_SIZE, EFileType } from "app/helper/constant";
import { useDispatch } from "react-redux";
import { setActive } from "app/redux/blockUIReducer";
import Button from "@mui/material/Button";
import MainContent from "../../components/common/MainContent";
import SuccessDialog from "../../components/common/SuccessDialog";
import ErrorDialog from "../../components/common/ErrorDialog";
import Path from "../../route/Path";
import { formatHeicFile, ScrollToFieldError } from "../../utils";
import CommonService from "../../services/CommonService";
// import CommonService from "../../services/CommonService";

function ProjectApply() {
  const dispatch = useDispatch();
  const params = useParams();
  const formikRef = useRef();
  const { projectId } = params;
  const [duplicateApply, setDuplicateApply] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [apply, setApply] = useState(false);
  const validationSchema = Yup.object().shape({
    prDescription: Yup.string().max(1000).required(t("validation_field.pr_self_required_message")).trim(),
    prUrl1: Yup.string().max(1000),
    prUrl2: Yup.string().max(1000),
    prUrl3: Yup.string().max(1000),
    file1: Yup.string(),
    file2: Yup.string(),
    file3: Yup.string(),
  });
  const initialValues = {
    prDescription: "",
    prUrl1: "",
    prUrl2: "",
    prUrl3: "",
    imageList: [
      {
        id: "img-1",
        file: "",
      },
      {
        id: "img-2",
        file: "",
      },
      {
        id: "img-3",
        file: "",
      },
    ],
    file1: "",
    file2: "",
    file3: "",
    fileName1: "",
    fileName2: "",
    fileName3: "",
  };
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [message, setMessage] = useState("");

  const openSuccessDiaglog = (mess) => {
    setOpenSuccess(true);
    setMessage(mess);
  };

  const openErrorDiaglog = (mess) => {
    setOpenError(true);
    setMessage(mess);
  };

  const onSuccess = () => {
    navigate(Path.project_applied);
  };

  const onError = () => {
    if (duplicateApply) {
      navigate(Path.home);
    } else {
      setOpenError(false);
    }
  };

  const applyProject = (values) => {
    const body = {
      pr_description: values.prDescription,
      pr_url1: values.prUrl1,
      pr_url2: values.prUrl2,
      pr_url3: values.prUrl3,
      pr_file1: values.file1,
      pr_file2: values.file2,
      pr_file3: values.file3,
      name_file1: values.fileName1,
      name_file2: values.fileName2,
      name_file3: values.fileName3,
    };
    InfluencerService.applyProject(projectId, body)
      .then((response) => {
        if (response.status === EResponseStatus.CREATED) {
          openSuccessDiaglog(t("project.apply.apply_successful"));
        }
      })
      .catch((error) => {
        if (error.response.status === EResponseStatus.FORBIDDEN) {
          const errorMess =
            error.response.data.message || error.response.data.messages[0];
          openErrorDiaglog(t(`common.message.${errorMess}`));
          setDuplicateApply(true);
        } else {
          openErrorDiaglog(t("project.apply.apply_fail"));
        }
      })
      .finally(() => {
        dispatch(setActive(false));
        setApply(false);
      });
  };

  useEffect(() => {
    const { values } = formikRef.current;
    if (apply) {
      applyProject(values);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apply]);

  const onSubmit = (values, context) => {
    const lstFile = [];
    values.imageList.forEach((item, index) => {
      if (item.file) {
        lstFile.push({
          id: index + 1,
          file: item.file,
          file_name: item.file.name,
        });
      }
    });
    if (!lstFile.length) {
      dispatch(setActive(true));
      setApply(true);
    } else {
      dispatch(setActive(true));
      const listPromise = [];
      lstFile.forEach((element, index) => {
        const data = {
          file: element?.file,
          type:
            element?.file?.type &&
            element?.file?.type?.toLowerCase()?.includes("application")
              ? EFileType.PDF
              : EFileType.IMAGE,
        };
        context.setFieldValue(`fileName${index + 1}`, element.file_name);
        listPromise.push(
          CommonService.uploadFile(data).catch(() => {
            dispatch(setActive(false));
            openErrorDiaglog(t("project.apply.apply_fail"));
            return Promise.reject();
          })
        );
      });
      Promise.all([...listPromise]).then((result) => {
        result.forEach((item, index) => {
          context.setFieldValue(
            `file${index + 1}`,
            item.data.data.thumbnail_url || item.data.data.media_url
          );
        });
        setApply(true);
      });
    }
  };

  const onChangeUploadFile = (
    e,
    { values, setFieldValue, setFieldError, id, index }
  ) => {
    // Allowing file type
    const allowedExtensions = /[^\\/.]+(?=(\.png|\.jpeg|\.jpg|\.pdf|\.heic)$)/;

    const errorFileSizeMess = t("common.message.oversize_file", {
      size: MAX_FILE_SIZE / 1024 / 1024,
    });
    const errorFormatMess = t("common.message.wrong_format_file", {
      format: "PNG、JPEG、JPG、PDF、HEIC",
    });

    const files = e.target.files || e.dataTransfer.files;
    const payload = [...(values?.imageList || [])];
    if (!files.length) return;
    if (!allowedExtensions.test(files[0].name.toLowerCase())) {
      setFieldError(id, errorFormatMess);
    } else if (files[0].size > MAX_FILE_SIZE) {
      if (files[0].type === "application/pdf") {
        setFieldError(id, errorFileSizeMess);
      } else {
        setFieldError(id, errorFileSizeMess);
      }
    } else {
      const file = formatHeicFile(files[0]);
      const lstShowPreview = ["image/png", "image/jpg", "image/jpeg"];
      payload[index] = {
        id,
        file: file || files[0],
        showPreview: !!(
          files[0]?.type && lstShowPreview.includes(files[0]?.type)
        ),
      };
      setFieldValue("imageList", payload);
    }
  };

  return (
    <LayoutMain path={Path.project_detail(projectId)}>
      <MainContent>
        <Formik
          innerRef={formikRef}
          validateOnMount
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {(props) => {
            const { values, errors, setFieldValue, setFieldError } = props;
            return (
              <Form className="w-full h-full">
                <ScrollToFieldError />
                <div className="md:ml-12 lg:ml-40">
                  <div className="flex items-center gap-1 mb-8">
                    <Link
                      to={Path.project_detail(projectId)}
                      className="w-7 h-7 items-center justify-center flex pt-1"
                    >
                      <i className="fa-solid fa-chevron-left text-lg" />
                    </Link>
                    <p className="text-[28px] font-bold">
                      {t("project.apply.title")}
                    </p>
                  </div>

                  <div className="mb-10" id="prDescription">
                    <label
                      htmlFor="prDescription"
                      className="text-[17px] font-bold mb-1 block"
                    >
                      {t("validation_field.prDescription")}
                      <span className="required" />
                    </label>
                    <Field
                      as="textarea"
                      id="prDescription"
                      name="prDescription"
                      className="max-w-[568px] w-full h-[268px] border border-solid border-secondary-200 rounded-[8px] px-3 py-2 outline-none focus:border-[#000] focus:border-[2px] resize-none"
                      maxLength={1000}
                    />
                    <ErrorMessage
                      component="div"
                      name="prDescription"
                      className="error-msg"
                    />
                  </div>

                  <div className="mb-10">
                    <label
                      htmlFor="prUrl1"
                      className="text-[17px] font-bold mb-1 block"
                    >
                      {t("project.apply.pr_url")}
                    </label>
                    <div className="flex flex-col">
                      <div className="mb-5" id="prUrl1">
                        <Field
                          name="prUrl1"
                          className="max-w-[568px] w-full border border-solid border-secondary-200 rounded-[8px] px-3 py-1 mb-1 outline-none focus:border-[#000] focus:border-[2px]"
                          maxLength={1000}
                        />
                        <ErrorMessage
                          component="div"
                          name="prUrl1"
                          className="error-msg"
                        />
                      </div>
                      <div className="mb-5" id="prUrl2">
                        <Field
                          name="prUrl2"
                          className="max-w-[568px] w-full border border-solid border-secondary-200 rounded-[8px] px-3 py-1 mb-1 outline-none focus:border-[#000] focus:border-[2px]"
                          maxLength={1000}
                        />
                        <ErrorMessage
                          component="div"
                          name="prUrl2"
                          className="error-msg"
                        />
                      </div>
                      <div className="block" id="prUrl3">
                        <Field
                          name="prUrl3"
                          className="max-w-[568px] w-full border border-solid border-secondary-200 rounded-[8px] px-3 py-1 mb-1 outline-none focus:border-[#000] focus:border-[2px]"
                          maxLength={1000}
                        />
                        <ErrorMessage
                          component="div"
                          name="prUrl3"
                          className="error-msg"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="mb-9">
                    <p className="text-[17px] font-bold mb-1">
                      {t("project.apply.pr_work_file")}
                    </p>
                    <div className="flex flex-col">
                      {values?.imageList?.map(({ id }, index) => {
                        return (
                          <div key={id} className="mb-5 sm:flex items-center">
                            <label
                              htmlFor={id}
                              className="block text-[17px] font-bold text-center w-[216px] flex-shrink-0 border border-solid border-secondary-200 rounded-[8px] px-3 py-1 cursor-pointer mb-2 sm:mr-2"
                            >
                              <span>{t("common.action.select_file")}</span>
                              <input
                                className="w-0 h-0 overflow-hidden"
                                onClick={(e) => {
                                  e.target.value = null;
                                }}
                                onChange={(e) =>
                                  onChangeUploadFile(e, {
                                    values,
                                    setFieldValue,
                                    setFieldError,
                                    id,
                                    index,
                                  })
                                }
                                id={id}
                                type="file"
                                name={id}
                              />
                            </label>
                            <div className="flex items-center overflow-hidden mb-2">
                              {values.imageList[index].showPreview ? (
                                <div className="flex-shrink-0 img-preview w-[40px] h-[40px] mr-2 rounded-full">
                                  <img
                                    src={URL.createObjectURL(
                                      new Blob([values.imageList[index].file])
                                    )}
                                    className="w-full h-full object-cover rounded-full"
                                    alt=""
                                  />
                                </div>
                              ) : null}
                              <div className="truncate">
                                {values.imageList[index].file ? (
                                  <p className="text-[14px] truncate">
                                    {values.imageList[index].file.name}
                                  </p>
                                ) : null}
                                {errors[id] ? (
                                  <p className="error-msg truncate">
                                    {errors[id]}
                                  </p>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>

                  <div className="flex justify-center max-w-[568px]">
                    <Button
                      disabled={!values.prDescription.trim()}
                      type="submit"
                      className="!text-[#fff] !min-w-[100px] !rounded-[8px] !px-4 !py-2"
                      classes={{
                        root: "!bg-[#0E5CB5]",
                      }}
                    >
                      {t("common.action.apply")}
                    </Button>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </MainContent>
      <SuccessDialog
        message={message}
        open={openSuccess}
        onClose={onSuccess}
        onOK={onSuccess}
      />
      <ErrorDialog
        message={message}
        open={openError}
        onClose={onError}
        onOK={onError}
      />
    </LayoutMain>
  );
}

export default ProjectApply;
