export default {
  list: {
    waiting_application_approval: "等待申請批准",
    waiting_completion_approval: "已完成 等待批准",
    completed: "已完成",
    offer_from_operator: "來自企業者的特別提案",
  },
  detail: {
    keep: "儲存",
    unkeep: "已儲存",
    what_want_do: "我們希望您做什麼",
    designation_sns: "指定的社交網站",
    designation_pr: "指定公關方式",
    page_pr: "指定您希望轉址到的頁面",
    tag_acc: "標籤帳戶",
    what_your_post: "貼文中必須包含的內容",
    tag_must_post: "貼文中必須包含的指定標籤",
    reward: "酬勞",
    original_price: "原價",
    post_period: "發布期間",
    already_applied: "已申請此招募",
    show_all_image: "查看所有照片",
    fixed_price: "固定報酬",
    free_supply: "免費提供",
    apply_condition: "申請條件",
    reward_condition: "酬勞條件",
    gender: "性別",
    age_group: "年齡層",
    min_followers: "最少追蹤者人數",
    view_more: "看更多",
    show_less: "顯示較少",
  },
  listAll: {
    more_people: "人以上",
    project_list: "招募案件",
  },
  apply: {
    title: "將自我推薦內容輸入專案中",
    pr_url: "推薦作品URL",
    pr_work_file: "推薦作品檔案",
    apply_successful: "您的申請已完成，<br/>等待業者確認中請稍候。",
    apply_fail: "申請失敗。",
    error_upload: "上傳文件時發生錯誤。請再試一次。",
    url_placeholder: "請輸入您要發布的社群網站的 URL。（自由填寫）",
    message_placeholder: "請輸入您對企業者交付成果的評論。",
    delivery_success: "已提交完成報告。<br />請等待操作員批准。",
  },
  chat: {
    waiting_application_approval_mess: "等待企業者批准申請。",
    waiting_report_approval_mess: "我們正在等待企業者的完成審批。",
    reject_report_mess: "完成報告未獲批准。請更正資訊並提交另一份完成報告。",
    decline_mess: "您的申請未獲批准。",
    cancel_mess: "此交易已取消。",
    start_report_btn: "提交完成報告",
    completed_mess: "已完成。",
    cancel_transaction: "取消交易",
    accept_special_offer: "接受特別提案",
    completed_mess_date: "{{from}} {{- date}} 批准了您的完成報告。",
    open_project_detail: "打開招募案件詳細",
  },
};
