import register from "../pages/Register/translate/en";
import project from "../pages/Projects/translate/en";
import home from "../pages/Home/translate/en";
import auth from "../pages/Login/translate/en";
import accountSetting from "../pages/AccountSettings/translate/en";
import reward from "../pages/Reward/translate/en";
import chat from "../chat/langs/ChatEN";
import favorite from "../pages/Favorite/translate/en";
import profile from "../pages/Profile/translate/en";

export default {
  chat,
  home,
  register,
  project,
  auth,
  accountSetting,
  reward,
  favorite,
  profile,
  common: {
    btn: {
      download_csv: "CSVファイルをダウンロード",
      export: "エクスポート",
      continue: "Continue",
      register: "登録",
    },
    message: {
      in_process: "In Progress",
      create_success: "Registration successful.",
      update_success: "Update successful.",
      delete_success: "Deleted successfully.",
      create_fail: "Registration failed. Please try again.",
      update_fail: "Update failed. Please try again.",
      delete_fail: "Delete failed. Please try again.",
      not_exist: "The deletion target does not exist.",
      wrong_current_password: "Current password is incorrect.",
      no_data_display: "No data to display.",
      select_lang: "Please select your language and region",
      oversize_file: "The maximum image size is ({{size}}MB) .",
      wrong_format_file: "The following files can be uploaded.\n{{format}}",
      cannot_display_data: "Data cannot be loaded. Please try again.",
      project_not_found:
        "The relevant recruitment opportunity is not displayed or has been deleted.",
      project_expired: "The deadline for the relevant recruitment opportunity has expired, so it cannot be displayed.",
      CANT_NOT_APPLY_TO_PROJECT_TWICE: "Duplicate applications for the project are not allowed.",
      INVALID_EMAIL: "メールアドレスが無効です。",
      EMAIL_EXIST:
        "The input email has already used by another account, please choose another.",
      NOT_FOUND: "Account not found.",
      COMMON_EXCEPTION: "Account not found.",
      CAN_NOT_UPDATED: "Registration failed.",
      one_time_service: "1回無料サービス",
      "Request failed with status code 403": "Update failed. Please try again.",
      "Request failed with status code 401": "Update failed. Please try again.",
      saved_to_wishlist: "Saved to favorites.",
      remove_from_wishlist: "Removed from favorites.",
      saved_to_wishlist_error: "Error saving to favorite list.",
      INVALID_PASSWORD: "Current password is incorrect.",
      confirm_password_not_match:
        "The confirm password and password do not match.",
      wrong_information: "The username or password is incorrect.",
      all: "All",
      twitter: "X (FormerTwitter)",
      image: "Photos",
      video: "Videos",
      "image, video": "Videos・Photos",
      no_input_yet: "Not set",
      not_found: "Sorry, what you're looking for couldn't be found!",
      youtube_not_found: "YouTube account not found.",
    },
    action: {
      edit: "Edit",
      cancel: "Cancel",
      save: "Save",
      back: "Back",
      filter: "Filter",
      select_file: "Select File",
      apply: "Apply",
      submit: "Submit",
      clear_all: "Clear All",
      close: "Close",
      ping_platform: "Translation Request",
      continue: "Continue",
      yes: "Yes",
      update: "Update",
    },
    price: "¥",
    date_format: {
      dd_mm_yyyy: "DD/MM/YYYY",
      yyyy_mm_dd: "YYYY/MM/DD",
      yyyy_mm: "YYYY/MM",
      mm_dd: "MM/DD",
      dd: "DD",
    },
    male: "Male",
    female: "Female",
    other: "Not Specified",
    other1: "Not Specified",
    japan: "Japan",
    followers: "Followers",
    followers_1: "Followers",
  },
  validate_message: {
    required: "{{- path}} is required.",
    max_length: "{{- path}} exceeds maximum length{{max}}.",
    wrong_format: "{{- path}} format is incorrect.",
  },
  validation_field: {
    prDescription: "Self-PR",
    prUrl1: "URL1",
    prUrl2: "URL2",
    prUrl3: "URL3",
    prFile1: "File 1",
    prFile2: "File 2",
    prFile3: "File 3",
    name: "Display Name",
    gender: "Gender",
    postcode: "Postal Code",
    email: "Email Address",
    self_introduction: "Self-Introduction",
    instagram_account: "Instagram Account",
    tiktok_account: "Tiktok Account",
    twitter_account: "Twitter Account",
    face_account: "Facebook Account",
    youtube_channel_id: "Youtube Channel ID",
    family_name: "Surname",
    first_name: "Given name",
    current_password: "Current Password",
    new_password: "New Password",
    confirm_new_password: "Confirm Password",
    confirm_new_password1: "Confirm Password",
    url: "URL",
    message: "Message",
    reason: "Reason",
    nickname: "Display Name",
    country: "Country",
    city: "City, Town, Village",
    street: "Prefecture",
    address: "Street Address",
    password: "Password",
    age_group_start: "Preferred Age Range of Influencers",
    age_group_end: "Preferred Age Range of Influencers",
    replyMessage: "Message",
    email_message: "Please enter your email address.",
    email_invalid_message: "Invalid email format.",
    new_password_required_message: "Enter a new password.",
    current_password_not_found: "Please enter current password.",
    name_required_message: "Please enter a display name.",
    gender_required_message: "Please enter your gender.",
    pr_self_required_message: "Please enter a self-introduction.",
  },
  header: {
    favorite: "Favorite Recruitment Cases",
    favorite1: "Favorite",
    homepage: "Influencer Home",
    project_applied: "Application List",
    reward: "Rewards",
    account_settings: "Account",
    log_out: "Logout",
  },
  change_language: "Language and Region",
  or1: "or",
  or2: "",
  or3: "",
  footer: {
    privacy: "Privacy",
    term_of_use: "Terms of Use",
    about_comany: "About the Company",
    currency: "Japanese",
    copyright: "© Copyright 2023 Meikatsu Inc. All Rights Reserved."
  },
  business_operator: "The business operator",
  operator_admin: "The administrator",
  notification_popup: {
    title: "Notifications",
    show_more: "Check details",
  },
  generation1: "Teens",
  generation2: "s",
  generation3: "s and above"
};
