import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { generateSNSIcon } from "../../utils";

function ProfileContent(props) {
  const { t } = useTranslation();
  const { selfIntroduction } = props;
  const { profile } = useSelector((state) => state);
  const listSNS = [
    {
      social: "instagram",
      account: profile?.instagram || "",
      prefix: "@",
    },
    {
      social: "tiktok",
      account: profile?.tiktok || "",
      prefix: "@",
    },
    {
      social: "facebook",
      account: profile?.facebook || "",
      prefix: "@",
    },
    {
      social: "youtube",
      account: profile?.youtube || "",
      prefix: "",
    },
  ];
  return (
    <div className="flex flex-col gap-6">
      <div className="sns-influ flex flex-col gap-1">
        <div className="font-bold text-[20px]">{t("profile.sns_activity")}</div>
        <div className="flex flex-col gap-4">
          {listSNS.map((item) => {
            return (
              <div key={item.social} className="flex items-center gap-2">
                {generateSNSIcon(item.social, "!w-5 !h-5")}
                {item.account
                  ? item.prefix + item.account
                  : t("common.message.no_input_yet")}

              </div>
            );
          })}
        </div>
      </div>

      <div className="sns-influ flex flex-col gap-1">
        <div className="font-bold text-[20px]">
          {t("profile.self_introduction")}
        </div>
        <div className="whitespace-pre-wrap">{selfIntroduction}</div>
      </div>
    </div>
  );
}

export default ProfileContent;
