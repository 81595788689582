import React, { useEffect, useState } from "react";
import MessageFileIcon from "app/chat/components/MessageFileIcon";
import dayjs from "dayjs";
import ImageItem from "../../components/ImageItem";
// import { detailFormatDate } from "../../utils";
const adminAvatar = require("app/assets/images/buzz-icon.png");

const relativeTime = require("dayjs/plugin/relativeTime");

dayjs.extend(relativeTime);

function OpponentMessage({ id, chatDetail, nextChatDetail, avatarUrl }) {
  const [messageContents, setMessageContents] = useState([]);

  const showAppendix =
    !nextChatDetail || nextChatDetail.send_by !== chatDetail.send_by;

  const showSendAt =
    showAppendix ||
    dayjs(nextChatDetail.send_at).diff(chatDetail.send_at, "minute") > 1;

  useEffect(() => {
    const messageSpitCode = new Date().getTime();

    const detectUrlInMessage = (text) => {
      const urlRegex = /(https?:\/\/[^\s]+)/g;
      return text.replace(urlRegex, (url) => {
        return `${messageSpitCode}url=${url}${messageSpitCode}`;
      });
    };

    const messageWithUrl = detectUrlInMessage(chatDetail.message);
    const messageSpit = messageWithUrl.split(messageSpitCode);
    setMessageContents(messageSpit);
  }, [chatDetail.message]);

  return (
    <div className="flex mt-1" id={id}>
      <div className="flex-none w-[50px] h-[50px] mr-3">
        {showAppendix && (
          <div className="w-full h-full relative">
            <ImageItem
              loadavatar="true"
              avatar="true"
              containerclassname="!rounded-full"
              skeletonclassname="!rounded-full"
              role="presentation"
              imagesrc={avatarUrl || adminAvatar}
              alt=""
              imgclassname="w-full h-full object-contain rounded-full border-2 border-green"
            />
            <i className="absolute text-[14px] bg-white rounded-full text-green bottom-0 right-0 fa-solid fa-circle-check" />
          </div>
        )}
      </div>
      <div className="message-box-wrapper flex flex-1">
        <div className="message-box-layout max-w-[90%] flex flex-col items-end">
          <div className="w-full">
            <div className="message-box relative flex items-end">
              {showAppendix &&
                (!chatDetail.mediaFiles || !chatDetail.mediaFiles.length) && (
                  <i className="message-appendix absolute fa-solid fa-play text-indigo-100 text-[10px] -rotate-[90deg] -left-[3.3px] -bottom-[1px] -z-10" />
                )}
              {chatDetail.mediaFiles && chatDetail.mediaFiles.length ? (
                <MessageFileIcon
                  mediaType={chatDetail.mediaFiles[0].media_type}
                  thumbUrl={chatDetail.mediaFiles[0].thumbnail_url}
                  mediaUrl={chatDetail.mediaFiles[0].media_url}
                  fileName={chatDetail.message}
                />
              ) : (
                <div className="message-content bg-gray-200 rounded-md p-2">
                  <span className="text-[12px] md:text-[14px] break-all whitespace-pre-wrap">
                    {messageContents.map((message, index) => {
                      if (message.includes("url=")) {
                        const url = message.replace("url=", "");
                        return (
                          <a
                            // eslint-disable-next-line react/no-array-index-key
                            key={index}
                            className="text-blue-600 underline"
                            href={url}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {url}
                          </a>
                        );
                      }
                      return message;
                    })}
                  </span>
                </div>
              )}
            </div>
          </div>
          {showSendAt && (
            <div className="message-time flex items-start justify-end w-full text-[10px] mb-1">
              {dayjs(chatDetail.send_at).format("MM/DD HH:mm")}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default OpponentMessage;
