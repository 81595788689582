import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import authReducer from "./authReducer";
import blockUIReducer from "./blockUIReducer";
import socialReducer from "./socialReducer";
import profileReducer from "./profileReducer";
import previousRouteReducer from "./previousRouteReducer";
import updateInfoReducer from "./updateInfoReducer";
import unreadMessReducer from "./unreadMessReducer";
import emailReducer from "./emailReducer";

const rootReducer = combineReducers({
  auth: authReducer,
  blockUI: blockUIReducer,
  social: socialReducer,
  profile: profileReducer,
  previousRoute: previousRouteReducer,
  notUpdate: updateInfoReducer,
  unreadMess: unreadMessReducer,
  email: emailReducer,
});

const persistedReducer = persistReducer(
  {
    key: "root",
    storage,
    whitelist: [
      "auth",
      "social",
      "profile",
      "previousRoute",
      "notUpdate",
      "unreadMess",
      "email",
    ],
  },
  rootReducer
);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export default store;

export const persistor = persistStore(store);
