import { useFormikContext } from "formik";
import React, { createContext, useEffect } from "react";
import i18next from "i18next";
import dayjs from "dayjs";
import { gapi } from "gapi-script";
import { Navigate } from "react-router-dom";
import { getCountryCallingCode } from "libphonenumber-js";
import InputBase from "@mui/material/InputBase";
import { styled } from "@mui/material/styles";
import store from "../redux/store";
import { setAuthStateDefault } from "../redux/authReducer";
import { setProfileEmpty } from "../redux/profileReducer";
import { setUpdateStateDefault } from "../redux/updateInfoReducer";
import { setUnreadEmpty } from "../redux/unreadMessReducer";
import Path from "../route/Path";

const durationn = require("dayjs/plugin/duration");

dayjs.extend(durationn);

const FormData = require("form-data");
const lineImage = require("app/assets/images/icon-line.png");
const googleIcon = require("app/assets/images/icon-google.webp");
const tiktokIcon = require("app/assets/images/icon-tiktok.png");
const instagramIcon = require("app/assets/images/icon-instagram.png");
const iconTwitter = require("app/assets/images/icon-twitter.png");
const iconFacbook = require("app/assets/images/icon-facebook.png");
const iconYoutube = require("app/assets/images/icon_youtube.png");

export const lstLanguage = [
  {
    id: 1,
    title: "日本語",
    note: "日本",
    value: "ja",
    locale: "ja",
    currencyFormat: "ja-JP",
  },
  {
    id: 2,
    title: "English",
    note: "United Kingdom",
    value: "en",
    locale: "en",
    currencyFormat: "en-US",
  },
  {
    id: 3,
    title: "繁體中文",
    note: "美國",
    value: "cn",
    locale: "zh-cn",
    currencyFormat: "zh-CN",
  },
  // {
  //   id: 4,
  //   title: "한국어",
  //   note: "대한민국",
  //   value: "ko-KR",
  // },
];

// handle formatNumber locale
const currentLanguage = localStorage.getItem("i18nextLng");

const currencyFormat = lstLanguage.find(
  (item) => item.value === currentLanguage
)?.currencyFormat;

export const generateSNSIcon = (value, style) => {
  return (
    <div
      className={`flex items-center justify-center mr-1 w-4 h-4 ${style || ""}`}
    >
      {
        {
          instagram: (
            <img alt="" src={instagramIcon} className="object-cover" />
          ),
          facebook: <img alt="" src={iconFacbook} className="object-cover" />,
          tiktok: <img alt="" src={tiktokIcon} className="object-cover" />,
          twitter: (
            <img alt="" src={iconTwitter} className="object-cover" />
          ),
          line: <img alt="" src={lineImage} className="object-cover" />,
          google: <img alt="" src={googleIcon} className="object-cover" />,
          youtube: <img alt="" src={iconYoutube} className="object-cover" />,
        }[value]
      }
    </div>
  );
};

export const appendFormData = (model) => {
  const formData = new FormData();
  Object.keys(model).forEach((property) => {
    formData.append(property, model[property] ? model[property] : "");
  });
  return formData;
};

export function ScrollToFieldError() {
  const { submitCount, isValid, errors } = useFormikContext();
  const scrollBehavior = { behavior: "smooth", block: "center" };
  useEffect(() => {
    if (isValid) return;
    if (!errors) return;
    const firstErrors = Object.keys(errors)[0];
    const element = document.getElementById(firstErrors);
    if (!element) return;

    // Scroll to first known error into view
    element.scrollIntoView(scrollBehavior);

    // Formik doesn't (yet) provide a callback for a client-failed submission,
    // thus why this is implemented through a hook that listens to changes on
    // the submit count.
  }, [submitCount]); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
}

export const formatNumber = (value, isPrice) => {
  const regex = /^\d+$/;
  if (regex.test(value)) {
    if (isPrice) {
      return `${i18next.t("common.price")} ${Number(value).toLocaleString(
        currencyFormat,
        {
          maximumFractionDigits: 0,
        }
      )}`;
    }
    return Number(value).toLocaleString(currencyFormat);
  }
  return value;
};

export const projectFeeDisplay = (value) => {
  switch (value) {
    case "one-time free service":
      return i18next.t("common.message.one_time_service");
    default:
      return value;
  }
};

export const checkValueChange = (
  initialValues,
  currentValues,
  allowComparisonField
) => {
  if (allowComparisonField) {
    const objA = {};
    const objB = {};

    allowComparisonField?.forEach((field) => {
      objA[field] = initialValues[field];
      objB[field] = currentValues[field];
    });

    return (
      JSON.stringify(Object.entries(objA).sort()) !==
      JSON.stringify(Object.entries(objB).sort())
    );
  }
  return (
    JSON.stringify(Object.entries(initialValues).sort()) !==
    JSON.stringify(Object.entries(currentValues).sort())
  );
};

export const logout = (e) => {
  if (window.FB) {
    window.FB.getLoginStatus((response) => {
      if (response && response.status === "connected") {
        window.FB.logout(); // invoke fb status to prevent orveride token
      }
    });
  }
  const auth2 = gapi?.auth2?.getAuthInstance();
  auth2?.signOut().then(() => auth2.disconnect()); // invoke google status
  e.preventDefault();
  store.dispatch(setAuthStateDefault());
  store.dispatch(setProfileEmpty(null));
  store.dispatch(setUpdateStateDefault());
  store.dispatch(setUnreadEmpty());
  i18next.changeLanguage("cn");
  dayjs.locale("zh-cn");
  return <Navigate to={Path.login} />;
};

export const removeFieldEmptyValue = (obj) => {
  const newObj = Object.keys(obj)
    .filter((key) => !!obj[key])
    .reduce((current, key) => ({ ...current, [key]: obj[key] }), {});
  return newObj;
};

export const formatUpdatePhoneNumberData = (phone, allowRemoveZero = true) => {
  const findFirstSpacePhoneNumber = phone?.indexOf(" "); // find first space in phone_number
  let phoneNumberWithoutPrefix = "";
  if (findFirstSpacePhoneNumber > 0) {
    phoneNumberWithoutPrefix = phone?.substring(findFirstSpacePhoneNumber + 1); // remove prefix
  } else {
    phoneNumberWithoutPrefix = "";
  }
  if (allowRemoveZero && phoneNumberWithoutPrefix[0] === String(0)) {
    phoneNumberWithoutPrefix = phoneNumberWithoutPrefix.substring(1);
  }
  return phoneNumberWithoutPrefix;
};

export const formatGetPhoneNumberWithPrefix = (phoneNumber, countryKey) => {
  const keyCountry = countryKey || "jp";
  const prefixPhoneNumber =
    getCountryCallingCode(keyCountry?.toUpperCase()) || "+81";
  if (phoneNumber[0] === "+") {
    phoneNumber = phoneNumber.slice(1);
  }
  // if phone number has value then add prefix, if not return value
  const formatPhoneNumber = phoneNumber
    ? `+${prefixPhoneNumber} ${phoneNumber}`
    : "";
  return formatPhoneNumber;
};

export const formatHeicFile = (file) => {
  let fileFormat = null;
  if (!file.type || file?.type === "image/heic") {
    const blob = new Blob([file], { type: "image/heic" });
    fileFormat = new File([blob], file?.name, {
      type: "image/heic",
    });
  }
  return fileFormat;
};

export const getLineUrl = (redirectUri) => {
  const rootUrl = "https://access.line.me/oauth2/v2.1/authorize";
  const csrfState = Math.random().toString(36).substring(2);

  const options = {
    client_id: process.env.REACT_APP_LINE_CLIENT_ID,
    response_type: "code",
    scope: ["openid", "email", "profile"].join(" "),
    state: csrfState,
    redirect_uri: redirectUri,
  };

  const qs = new URLSearchParams(options);

  return `${rootUrl}?${qs.toString()}`;
};

export const getGoogleUrl = (redirectUri) => {
  const rootUrl = "https://accounts.google.com/o/oauth2/v2/auth";
  const csrfState = Math.random().toString(36).substring(2);

  const options = {
    client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    response_type: "code",
    access_type: "offline",
    redirect_uri: redirectUri,
    prompt: "consent",
    scope: [
      "https://www.googleapis.com/auth/userinfo.profile",
      "https://www.googleapis.com/auth/userinfo.email",
      "openid",
    ].join(" "),
    state: csrfState,
  };

  const qs = new URLSearchParams(options);

  return `${rootUrl}?${qs.toString()}`;
};

export const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    borderRadius: 10,
    border: "1px solid #161718FF",
    padding: "6px 30px 6px 10px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    "&:focus": {
      borderRadius: 10,
      borderColor: "#80bdff",
    },
  },
}));

export const trimSpaceFirstCharacter = (value) => {
  let result = "";
  if (value) {
    result = value.trimStart();
  }
  return result;
};

export const getProjectDetailByLanguage = (project) => {
  const language = i18next.resolvedLanguage;
  let result = "";
  let serviceProviderName = "";
  if (Object.keys(project).length > 0) {
    serviceProviderName = project?.service_provider_name;
    result = {
      title: project?.title
        ? `${project?.title} ${
            serviceProviderName ? `@${serviceProviderName}` : ""
          }`
        : "",
      description: project?.description || "",
      text_to_include: project?.text_to_include || "",
      reward_condition:
        project?.fee?.length && project?.fee[0]?.payment_terms
          ? project?.fee[0]?.payment_terms
          : "",
    };

    if (language === "en") {
      result.title = project?.title_en
        ? `${project?.title_en} ${
            serviceProviderName ? `@${serviceProviderName}` : ""
          }`
        : "";
      result.description = project?.description_en || "";
      result.text_to_include = project?.text_to_include_en || "";
      result.reward_condition =
        project?.fee_en?.length && project?.fee_en[0]?.payment_terms
          ? project?.fee_en[0]?.payment_terms
          : "";
    }
    if (language === "cn") {
      result.title = project?.title_cn
        ? `${project?.title_cn} ${
            serviceProviderName ? `@${serviceProviderName}` : ""
          }`
        : "";
      result.description = project?.description_cn || "";
      result.text_to_include = project?.text_to_include_cn || "";
      result.reward_condition =
        project?.fee_cn?.length && project?.fee_cn[0]?.payment_terms
          ? project?.fee_cn[0]?.payment_terms
          : "";
    }
  }

  return result;
};

export const getUpdatedByText = (value) => {
  switch (value) {
    case "service provider":
      return i18next.t("business_operator");
    case "platform_operator":
      return i18next.t("operator_admin");
    default:
      return "";
  }
};

export const getMediaName = (value) => {
  switch (value) {
    case 'instagram':
      return 'Instagram';
    case 'tiktok':
      return 'Tiktok';
    case 'twitter':
      return i18next.t("common.message.twitter");
    case 'facebook':
      return 'Facebook';
    case 'youtube':
      return 'Youtube';
    default:
      return '';
  }
}

export const getGeneration = (value, language) => {
  switch (value) {
    case '10':
      if (language === 'en'){
        return i18next.t("generation1");
      }
      return value + i18next.t("generation1");
    case '60':
      return value + i18next.t("generation3");
    default:
      return value + i18next.t("generation2");
  }
}

export const changeGeneration = (value, language) => {
  switch (value) {
    case '10代':
      if (language === 'en'){
        return i18next.t("generation1");
      }
      return value.replace('代','') + i18next.t("generation1");
    case '60代以上':
      return value.replace('代以上','') + i18next.t("generation3");
    default:
      return value.replace('代','') + i18next.t("generation2");
  }
}

export const extractYouTubeUrl = (text) => {
  const regex = /(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/[^\s]+/g;
  const matches = text.match(regex);
  return matches || [];
};

export const getYouTubeEmbedUrl = (youtubeUrls) => {
  // eslint-disable-next-line no-useless-escape
  const videoIdRegex = /(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
  return youtubeUrls
    .map((url, index) => {
      const match = url.match(videoIdRegex);
      const videoId = match ? match[1] : null;
      return videoId
        ? {
            link: `https://www.youtube.com/embed/${videoId}`,
            id: `${videoId}-${index}`,
          }
        : null;
    })
    .filter((embedUrl) => embedUrl !== null);
};

// remove youtube urls from description after got embed link for iframe
export const removeYouTubeUrls = (text) => {
  const regex = /(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/[^\s]+/g;
  return text
    .replace(regex, "")
    .replace(/\s{2,}/g, " ")
    .trim(); // Replace URLs and clean up extra spaces
};


export const LayoutContext = createContext();
