/* eslint-disable class-methods-use-this */
import client from "./client";

const baseUrl = "user";

class UserService {
  updateInfluencerInfo(data) {
    return client.put(`${baseUrl}/update-influencer-information`, data);
  }

  getUserPersonalInfo() {
    return client.get(`${baseUrl}/personal-information`);
  }

  updateUserPersonalInfo(data) {
    return client.post(`${baseUrl}/personal-information/update`, data);
  }

  getProfile() {
    return client.get(`${baseUrl}/profile`);
  }

  updateProfile(data) {
    return client.put(`${baseUrl}/update-profile`, data);
  }

  changePassword(data) {
    return client.post(`${baseUrl}/change-password`, data);
  }

  withdrawUser(data) {
    return client.put(`${baseUrl}/inactive`, data);
  }

  checkExistEmail(data) {
    return client.post(`${baseUrl}/check-exist-email`, data);
  }

  forgotPassword(data) {
    return client.post(`${baseUrl}/forgot-password`, data);
  }
}

export default new UserService();
