/* eslint-disable jsx-a11y/label-has-associated-control */
// i-001-003
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import LayoutMain from "app/layouts/LayoutMain";
import { EResponseStatus, passwordRegex } from "app/helper/constant";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import UserService from "app/services/UserService";
import { useDispatch, useSelector } from "react-redux";
import { setActive } from "app/redux/blockUIReducer";
import jwtDecode from "jwt-decode";
import Path from "app/route/Path";
import SuccessDialog from "app/components/common/SuccessDialog";
import { setRouteEmpty } from "app/redux/previousRouteReducer";
import SplitBackground from "app/components/SplitBackground";
import { setUpdateState } from "app/redux/updateInfoReducer";
import { setProfileStore } from "app/redux/profileReducer";
import AuthService from "app/services/AuthService";
import { setAuthToken, setAuthUser } from "app/redux/authReducer";
import { setEmail } from "app/redux/emailReducer";
import ChangeLanguageButton from "app/components/ChangeLanguageButton";

function ChangePassword() {
  const previousRoute = useSelector((state) => state.previousRoute);
  const email = useSelector((state) => state.email);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [openSuccess, setOpenSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [loginRes, setLoginRes] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const validationSchema = Yup.object().shape({
    current_password: Yup.string().required(),
    new_password: Yup.string()
      .required(t("validation_field.new_password_required_message"))
      .matches(passwordRegex, t("auth.wrong_format_new_pass")),
    confirm_new_password: Yup.string()
      .required(t("auth.require_confirm_pass"))
      .matches(passwordRegex, t("auth.wrong_format_new_pass"))
      .when("new_password", {
        is: (val) => !!(val && val.length > 0),
        then: Yup.string().oneOf(
          [Yup.ref("new_password")],
          t("common.message.confirm_password_not_match")
        ),
      }),
  });

  const initialValues = {
    current_password: "",
    new_password: "",
    confirm_new_password: "",
  };

  const openSuccessDiaglog = (mess) => {
    setOpenSuccess(true);
    setMessage(mess);
  };

  const onSuccess = () => {
    if (
      loginRes?.data?.data?.is_first_login ||
      loginRes?.data?.data?.not_updated
    ) {
      navigate(Path.register);
    } else {
      navigate(previousRoute || Path.home);
      dispatch(setRouteEmpty());
      dispatch(setEmail(null));
      UserService.getProfile().then((res) => {
        if (res.status === EResponseStatus.SUCCESS) {
          dispatch(setProfileStore(res?.data?.data));
        }
      });
    }
  };

  const changePassword = async (values) => {
    dispatch(setActive(true));
    const signInPayload = {
      email,
      password: values.current_password,
      social_id_provider: "email_influencer",
    };

    const changePasswordPayload = {
      current_password: values.current_password,
      new_password: values.new_password,
    };

    const loginResponse = await AuthService.login(signInPayload).catch(() => {
      dispatch(setActive(false));
      setErrorMessage(t("common.message.wrong_current_password"));
      return Promise.reject();
    });

    setLoginRes(loginResponse);

    const user = jwtDecode(loginResponse?.data?.data?.accessToken);
    const token = {
      accessToken: loginResponse?.data?.data?.accessToken,
      refreshToken: loginResponse?.data?.data?.refreshToken,
    };
    dispatch(setAuthUser(user));
    dispatch(setAuthToken(token));
    dispatch(
      setUpdateState(
        loginResponse?.data?.data?.not_updated
          ? loginResponse?.data?.data?.not_updated
          : false
      )
    );

    await UserService.changePassword(changePasswordPayload)
      .then((response) => {
        if (response.status === EResponseStatus.CREATED) {
          openSuccessDiaglog(t("common.message.update_success"));
        }
      })
      .catch((error) => {
        if (error.response.status === EResponseStatus.BAD_REQUEST) {
          const errorMess = error.response.data.message;
          setErrorMessage(t(`common.message.${errorMess}`));
        } else {
          setErrorMessage(t(`common.message.update_fail`));
        }
      })
      .finally(() => dispatch(setActive(false)));
  };

  const isDisableBtn = (values) => {
    return (
      !values?.current_password ||
      !values?.new_password ||
      !values?.confirm_new_password
    );
  };

  return (
    <LayoutMain customHeader showFooter={false} showMobileNav={false}>
      <div className="flex flex-wrap flex-1">
        <SplitBackground title={t("auth.change_pass_left_title")} />
        <main className="w-full md:w-[50%] h-[50vh] md:h-auto flex justify-center items-center">
          <div className="custom-container md:flex justify-center items-center w-full">
            <Formik
              validateOnMount
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={changePassword}
            >
              {({ values, handleChange }) => {
                return (
                  <Form className="w-full max-w-[568px] mx-auto px-6">
                    {errorMessage && (
                      <div className="error-msg mb-4">{errorMessage}</div>
                    )}
                    <div className="w-full">
                      <div className="flex justify-end mb-4 md:mb-6">
                        <ChangeLanguageButton className="hover:bg-secondary transition-all duration-200 ease-linear p-2 -m-2" />
                      </div>
                      <div className="current-password-field">
                        <Field
                          id="current_password"
                          name="current_password"
                          placeholder={t("auth.tempo_password")}
                          onChange={(e) => {
                            handleChange(e);
                            setErrorMessage("");
                          }}
                          maxLength={64}
                          type="password"
                          className="w-full h-14 bg-gray-50 border-2 border-slate-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 focus:outline-none  p-2.5 "
                        />
                        <ErrorMessage
                          component="div"
                          name="current_password"
                          className="error-msg"
                        />
                      </div>

                      <div className="my-5 md:my-7 text-sm">
                        {t("auth.password_format_notice")}
                      </div>

                      <div className="flex flex-col gap-4 mb-5 md:mb-10">
                        <div className="new-password-field">
                          <Field
                            id="new_password"
                            name="new_password"
                            placeholder={t("validation_field.new_password")}
                            maxLength={64}
                            onChange={(e) => {
                              handleChange(e);
                              setErrorMessage("");
                            }}
                            type="password"
                            className="w-full h-14 bg-gray-50 border-2 border-slate-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 focus:outline-none  p-2.5 "
                          />
                          <ErrorMessage
                            component="div"
                            name="new_password"
                            className="error-msg"
                          />
                        </div>

                        <div className="confirm-pass-field">
                          <Field
                            id="confirm_new_password"
                            name="confirm_new_password"
                            onChange={(e) => {
                              handleChange(e);
                              setErrorMessage("");
                            }}
                            placeholder={t(
                              "validation_field.confirm_new_password1"
                            )}
                            type="password"
                            className="w-full h-14 bg-gray-50 border-2 border-slate-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 focus:outline-none  p-2.5 "
                          />
                          <ErrorMessage
                            component="div"
                            name="confirm_new_password"
                            className="error-msg"
                          />
                        </div>
                      </div>

                      <div className="flex justify-center items-center">
                        <Button
                          type="submit"
                          disabled={isDisableBtn(values)}
                          className=" !text-[#fff] !min-w-[200px] !rounded-[4px] !px-2 !py-[6px]"
                          classes={{
                            root: "!bg-[#0E5CB5]",
                          }}
                        >
                          {t("common.action.continue")}
                        </Button>
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </main>
      </div>
      <SuccessDialog
        message={message}
        open={openSuccess}
        onClose={onSuccess}
        onOK={onSuccess}
      />
    </LayoutMain>
  );
}

export default ChangePassword;
