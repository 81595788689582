import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Keyboard } from "swiper";
import BannerItem from "./common/BannerItem";

function Banner(props) {
  const { banners } = props;

  const breakpoints = {
    576: {
      slidesPerView: banners?.length < 2 ? banners?.length : 2,
      slidesPerGroup: banners?.length < 2 ? banners?.length : 2,
    },
    1024: {
      slidesPerView: banners?.length < 3 ? banners?.length : 3,
      slidesPerGroup: banners?.length < 3 ? banners?.length : 3,
    },
    1536: {
      slidesPerView: banners?.length < 4 ? banners?.length : 4,
      slidesPerGroup: banners?.length < 4 ? banners?.length : 4,
    },
  };

  return (
    <div className="swiper-area mb-8">
      <Swiper
        modules={[Autoplay, Keyboard]}
        keyboard={{
          enabled: true,
        }}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        speed={1000}
        rewind
        className="mySwiper rounded-[8px]"
        slidesPerView={1}
        slidesPerGroup={1}
        spaceBetween={12}
        breakpoints={breakpoints}
      >
        {banners?.map((banner) => {
          return (
            <SwiperSlide key={banner?.banner_id} className="w-100">
              <figure className="relative pt-[50%]">
                <BannerItem banner={banner} />
              </figure>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
}

export default Banner;
