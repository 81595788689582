/* eslint-disable camelcase */
import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";

import Path from "app/route/Path";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import NameSettingField from "./NameSettingField";
import BirthdaySettingField from "./BirthdaySettingField";
import PhoneNumberSettingField from "./PhoneNumberSettingField";
import GovernmentIdSettingField from "./GovernmentIdSettingField";
import AddressSettingField from "./AddressSettingField";
import NicknameSettingField from "./NicknameSettingField";
import GenderSettingField from "./GenderSettingField";
import LayoutMain from "../../../layouts/LayoutMain";
import MainContent from "../../../components/common/MainContent";
import UserService from "../../../services/UserService";
import { EResponseStatus } from "../../../helper/constant";
import { UserProvider } from "./UserContext";
import SuccessDialog from "../../../components/common/SuccessDialog";
import ErrorDialog from "../../../components/common/ErrorDialog";
// import PaypalSettingField from "./PaypalSettingField";
import { formatGetPersonalInfoData } from "../../../utils/personal-info";

function PersonalInfo() {
  const [showEdit, setShowEdit] = useState("");
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState({});
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [loadError, setLoadError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const profile = useSelector((state) => state?.profile);

  const openErrorDialog = (mess) => {
    setErrorMessage(mess);
    setOpenError(true);
  };

  const handleErrorClose = () => {
    if (loadError) {
      navigate(Path.account_settings);
    } else {
      setOpenError(false);
      setErrorMessage("");
    }
  };

  const getUserPersonalInfo = useCallback(() => {
    UserService.getUserPersonalInfo()
      .then((response) => {
        if (response.status === EResponseStatus.SUCCESS) {
          const { data } = response.data;
          setUserInfo(formatGetPersonalInfoData(data));
        }
      })
      .catch(() => {
        openErrorDialog(t("common.message.cannot_display_data"));
        setLoadError(true);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getUserPersonalInfo();
  }, [getUserPersonalInfo]);

  return (
    <LayoutMain showMobileHeader path={Path.account_settings}>
      <MainContent>
        <div className="h-full w-full -mt-8 md:-mt-0">
          <div className="w-full px-4 max-w-[568px] md:ml-12 lg:ml-40">
            <div className="w-full pb-10">
              <ol className="hidden md:block text-sm leading-[18px] text-REGULAR_TEXT_COLOR font-semibold">
                <li className="inline-block hover:underline">
                  <Link to={Path.account_settings}>
                    <span>{t("header.account_settings")}</span>
                  </Link>
                </li>
                <li className="inline-block">
                  <div className="inline-block mx-4">
                    <svg
                      fill="#767676"
                      viewBox="0 0 18 18"
                      style={{ height: 10, width: 10 }}
                    >
                      <path
                        fillRule="#767676"
                        d="M4.29 1.71A1 1 0 115.71.3l8 8a1 1 0 010 1.41l-8 8a1 1 0 11-1.42-1.41l7.29-7.29z"
                      />
                    </svg>
                  </div>
                  <span>{t("accountSetting.personal_info.personal_info")}</span>
                </li>
              </ol>

              <h1 className="text-[32px] leading-9 text-REGULAR_TEXT_COLOR font-extrabold mt-3">
                {t("accountSetting.personal_info.personal_info")}
              </h1>
            </div>

            <div className="w-full h-auto flex pb-10">
              <div className="w-full">
                <div className="w-full h-auto flex flex-col">
                  <UserProvider
                    value={useMemo(
                      () => ({ userInfo, setUserInfo, profile }),
                      [userInfo, setUserInfo, profile]
                    )}
                  >
                    <NameSettingField
                      showEdit={showEdit}
                      setShowEdit={setShowEdit}
                      setOpenSuccess={(value) => setOpenSuccess(value)}
                      setOpenError={(value) => setOpenError(value)}
                    />
                    <NicknameSettingField
                      showEdit={showEdit}
                      setShowEdit={setShowEdit}
                      setOpenSuccess={(value) => setOpenSuccess(value)}
                      setOpenError={(value) => setOpenError(value)}
                    />
                    <GenderSettingField
                      showEdit={showEdit}
                      setShowEdit={setShowEdit}
                      setOpenSuccess={(value) => setOpenSuccess(value)}
                      setOpenError={(value) => setOpenError(value)}
                      setMessage={(value) => setErrorMessage(value)}
                    />
                    <BirthdaySettingField
                      showEdit={showEdit}
                      setShowEdit={setShowEdit}
                      setOpenSuccess={(value) => setOpenSuccess(value)}
                      setOpenError={(value) => setOpenError(value)}
                      setMessage={(value) => setErrorMessage(value)}
                    />

                    <PhoneNumberSettingField
                      showEdit={showEdit}
                      setShowEdit={setShowEdit}
                      setOpenSuccess={(value) => setOpenSuccess(value)}
                      setOpenError={(value) => setOpenError(value)}
                    />
                    <GovernmentIdSettingField
                      showEdit={showEdit}
                      setShowEdit={setShowEdit}
                      setOpenSuccess={(value) => setOpenSuccess(value)}
                      setOpenError={(value) => setOpenError(value)}
                    />
                    <AddressSettingField
                      showEdit={showEdit}
                      setShowEdit={setShowEdit}
                      setOpenSuccess={(value) => setOpenSuccess(value)}
                      setOpenError={(value) => setOpenError(value)}
                    />
                    {/* <PaypalSettingField
                      showEdit={showEdit}
                      setShowEdit={setShowEdit}
                      setOpenSuccess={(value) => setOpenSuccess(value)}
                      setOpenError={(value) => setOpenError(value)}
                    /> */}
                  </UserProvider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </MainContent>
      <SuccessDialog
        message={t("common.message.update_success")}
        open={openSuccess}
        onClose={() => setOpenSuccess(false)}
        onOK={() => setOpenSuccess(false)}
      />
      <ErrorDialog
        message={errorMessage || t("common.message.update_fail")}
        open={openError}
        onClose={handleErrorClose}
        onOK={handleErrorClose}
      />
    </LayoutMain>
  );
}

export default PersonalInfo;
