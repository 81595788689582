// i-002-006
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import ProjectItem from "app/components/ProjectItem";
import Pagination from "@mui/material/Pagination";
import { PER_PAGE } from "app/helper/constant";
import MainContent from "../../components/common/MainContent";
import LayoutMain from "../../layouts/LayoutMain";
import useCoreComponent from "../../hooks/useCoreComponent";
import Path from "../../route/Path";

const endpoint = `influencer/ad-project/list-favorite-ad-project-influencer`;

function FavoritePage() {
  const { t } = useTranslation();
  // const [status, setStatus] = useState(EFacilityType.ACCOMMODATION);
  const [page, setPage] = useState(1);
  const { response, errorMess, totalPages, isLoading } = useCoreComponent(
    endpoint,
    { page, per_page: PER_PAGE }
  );

  const [data, setData] = useState([]);

  useEffect(() => {
    setData(response);
  }, [response]);

  // const handleTabChange = (event, newValue) => {
  //   setStatus(newValue);
  //   setPage(1);
  //   setData([]);
  // };

  const handleChangePage = (selectedPage) => {
    setPage(selectedPage);
    window.scrollTo(0, 0);
  };

  const updateList = (index) => {
    const lstTemp = [...data];
    lstTemp.splice(index, 1);
    setData(lstTemp);
  };

  return (
    <LayoutMain>
      <MainContent>
        <div className="top-page flex flex-col h-full w-full">
          <div className="flex items-center justify-between mb-5 pb-3 border-b border-solid border-secondary-300">
            <Link
              to={Path.home}
              className="w-7 h-7 flex items-center justify-center pt-1"
            >
              <i className="fa-solid fa-chevron-left" />
            </Link>
            <p className="flex-1 text-[27px] font-bold">
              {t("favorite.title")}
            </p>
          </div>

          {/* List Favorite */}
          {(() => {
            if (!isLoading) {
              if (errorMess) {
                return (
                  <p className="text-[20px] font-[600] text-center">
                    {errorMess}
                  </p>
                );
              }
              if (!data.length) {
                return (
                  <p className="text-[20px] font-[600] text-center">
                    {t("common.message.no_data_display")}
                  </p>
                );
              }
              return (
                <div className="list-project flex flex-col flex-1">
                  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-6">
                    {data.map((project, index) => {
                      return (
                        <div key={project.ad_project_id} className="relative">
                          <ProjectItem
                            project={project}
                            isLoading={isLoading}
                            deleteItem
                            updateList={() => updateList(index)}
                            linkUrl={Path.project_detail(project.ad_project_id)}
                          />
                        </div>
                      );
                    })}
                  </div>
                  <div className="flex justify-center pt-10 flex-1 items-end">
                    <Pagination
                      page={page}
                      count={totalPages}
                      onChange={(event, selectedPage) =>
                        handleChangePage(selectedPage)
                      }
                      showFirstButton
                      showLastButton
                    />
                  </div>
                </div>
              );
            }
            return null;
          })()}
        </div>
      </MainContent>
    </LayoutMain>
  );
}

export default FavoritePage;
