// i-002-005
import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { useTranslation } from "react-i18next";
import TabWithBatch from "app/components/TabWithBatch";
import { Button, Tooltip } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import MainContent from "../../components/common/MainContent";
import LayoutMain from "../../layouts/LayoutMain";
import {
  EResponseStatus,
  ERewardStatus,
  PER_PAGE,
} from "../../helper/constant";
import { formatNumber, trimSpaceFirstCharacter } from "../../utils";
import useCoreComponent from "../../hooks/useCoreComponent";
import Path from "../../route/Path";
import InfluencerService from "../../services/InfluencerService";
import { setActive } from "../../redux/blockUIReducer";
import SuccessDialog from "../../components/common/SuccessDialog";
import ErrorDialog from "../../components/common/ErrorDialog";
import ImageItem from "../../components/ImageItem";

const endpoint = `influencer/ad-project/applied/list`;

function UnsortIcon() {
  return (
    <div className="w-5 h-5 flex items-center justify-center">
      <i className="text-[12px] text-[#DDD] fa-solid fa-sort" />
    </div>
  );
}

function SortedDescendingIcon() {
  return (
    <div className="w-5 h-5 flex items-center justify-center">
      <i className="text-[12px] text-[#DDD] fa-solid fa-sort-down" />
    </div>
  );
}

function SortedAscendingIcon() {
  return (
    <div className="w-5 h-5 flex items-center justify-center">
      <i className="text-[12px] text-[#DDD] fa-solid fa-sort-up" />
    </div>
  );
}

function RewardPage() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState(ERewardStatus.PREPARING_PAYMENT);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalReward, setTotalReward] = useState(null);
  const { response, errorMess, isLoading, totalItem } = useCoreComponent(
    endpoint,
    { page, per_page: PER_PAGE, payment_status: status }
  );

  const columns = [
    {
      field: "title",
      headerName: t("reward.title"),
      sortable: true,
      minWidth: 400,
      flex: 1,
      headerClassName: "custom",
      valueGetter: (row) => {
        return trimSpaceFirstCharacter(row.value);
      },
      renderCell: (item) => {
        const id = item?.row?.ad_project_id;
        const itemImage = item?.row?.thumbnail_url;
        const title = item?.row?.title;
        return (
          <Link
            to={Path.project_detail(id)}
            className="flex h-full items-center w-full"
          >
            <div className="w-[100px] flex-shrink-0 h-full mr-2">
              <ImageItem
                lazy="true"
                containerclassname="!rounded-none"
                skeletonclassname="!h-[85%] !top-[50%] translate-y-[-50%]"
                role="presentation"
                imagesrc={itemImage}
                alt=""
                imgclassname="object-cover w-full h-full rounded-[4px] p-1"
              />
              <ImageItem />
            </div>
            <div className="truncate">
              <Tooltip title={title}>
                <span className="block w-full truncate">{title}</span>
              </Tooltip>
            </div>
          </Link>
        );
      },
    },
    {
      field: "fee",
      headerName: t("reward.fee"),
      sortable: true,
      headerClassName: "custom",
      width: 250,
      valueFormatter: (item) => {
        const fee = formatNumber(item.value, true) || "";
        return fee;
      },
    },
    {
      field: "finished_at",
      headerName: t("reward.finished_at"),
      sortable: true,
      headerClassName: "custom",
      width: 250,
      renderCell: (item) => {
        const finishedAt = item?.row?.finished_at;
        const format = finishedAt
          ? dayjs(finishedAt).format(t("common.date_format.yyyy_mm_dd"))
          : "";
        return format;
      },
    },
  ];

  useEffect(() => {
    const params = {
      payment_status: status,
    };
    InfluencerService.getTotalFeeByPage(params).then((res) => {
      if (res.status === EResponseStatus.SUCCESS) {
        setTotalReward(res.data.data);
      }
    });
  }, [status]);

  useEffect(() => {
    const list = response || [];
    setData(list);
  }, [response, isLoading]);

  const openSuccessDiaglog = (mess) => {
    setOpenSuccess(true);
    setMessage(mess);
  };

  const openErrorDiaglog = (mess) => {
    setOpenError(true);
    setMessage(mess);
  };

  const handleTabChange = (event, newValue) => {
    setStatus(newValue);
    setPage(1);
    setTotalReward(0);
  };

  const onPaymentReady = () => {
    dispatch(setActive(true));
    InfluencerService.getPaymentReady()
      .then((res) => {
        if (res.status === EResponseStatus.SUCCESS) {
          setData([]);
          setTotalReward(0);
          openSuccessDiaglog(t("common.message.update_success"));
        }
      })
      .catch(() => openErrorDiaglog(t("common.message.update_fail")))
      .finally(() => dispatch(setActive(false)));
  };

  const onPaginationModelChange = (model) => {
    const newPage = model?.page;
    setPage(newPage + 1);
  };

  return (
    <LayoutMain>
      <MainContent>
        <div className="top-page flex flex-col h-full w-full">
          <div className="flex items-center justify-between mb-5 pb-3 border-b border-solid border-secondary-300">
            <Link
              to={Path.home}
              className="w-7 h-7 flex items-center justify-center pt-1"
            >
              <i className="fa-solid fa-chevron-left" />
            </Link>
            <p className="flex-1 text-[27px] font-bold">{t("header.reward")}</p>
          </div>
          <Box
            sx={{
              width: "100%",
              borderBottom: 1,
              borderColor: "divider",
              marginBottom: 5,
            }}
          >
            <Tabs
              value={status}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
              allowScrollButtonsMobile
            >
              <Tab
                value={ERewardStatus.PREPARING_PAYMENT}
                label={
                  <TabWithBatch>{t("reward.prepare_payment")}</TabWithBatch>
                }
              />
              <Tab
                value={ERewardStatus.PAYABLE}
                label={<TabWithBatch>{t("reward.payable")}</TabWithBatch>}
              />
              <Tab
                value={ERewardStatus.PAYMENT_REQUEST}
                label={
                  <TabWithBatch>{t("reward.payment_request")}</TabWithBatch>
                }
              />
              <Tab
                value={ERewardStatus.PAID}
                label={<TabWithBatch> {t("reward.paid")}</TabWithBatch>}
              />
              <Tab
                value={ERewardStatus.ALL}
                label={<TabWithBatch> {t("reward.all")}</TabWithBatch>}
              />
            </Tabs>
          </Box>

          <div className="flex flex-wrap sm:flex-nowrap items-center mb-10">
            <div className="flex items-center text-[30px] font-[700] mb-4 sm:mb-0 mr-10 whitespace-nowrap">
              <p className="mr-6">{t("reward.price")}</p>
              <p>{formatNumber(totalReward, true) || ""}</p>
            </div>

            {status === ERewardStatus.PAYABLE ? (
              <div className="flex flex-wrap sm:flex-nowrap items-center">
                <Button
                  onClick={onPaymentReady}
                  className="!text-white !min-w-[120px] !rounded-[12px] !px-4 !py-2 !font-[700] !mr-10 !mb-4 sm:!mb-0"
                  classes={{
                    root: "!bg-black",
                  }}
                  disabled={totalReward < 50000}
                >
                  {t("reward.request_pay")}
                </Button>
                <p>{t("reward.notice")}</p>
              </div>
            ) : null}
          </div>

          {/* Table Group */}
          <div>
            {errorMess ? (
              <p className="text-[20px] font-[600] text-center">{errorMess}</p>
            ) : (
              <div className="w-full">
                <DataGrid
                  autoHeight
                  getRowId={(item) => item.ad_project_id}
                  rows={data}
                  columns={columns}
                  loading={isLoading}
                  rowCount={totalItem}
                  paginationMode="server"
                  disableColumnMenu
                  slots={{
                    columnUnsortedIcon: UnsortIcon,
                    columnSortedDescendingIcon: SortedDescendingIcon,
                    columnSortedAscendingIcon: SortedAscendingIcon,
                  }}
                  paginationModel={{
                    pageSize: PER_PAGE,
                    page: page - 1,
                  }}
                  pageSizeOptions={[PER_PAGE]}
                  onPaginationModelChange={(model) => {
                    onPaginationModelChange(model);
                  }}
                  localeText={{
                    noResultsOverlayLabel: t("common.message.no_data_display"),
                    noRowsLabel: t("common.message.no_data_display"),
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </MainContent>
      <SuccessDialog
        message={message}
        open={openSuccess}
        onClose={() => setOpenSuccess(false)}
        onOK={() => setOpenSuccess(false)}
      />
      <ErrorDialog
        message={message}
        open={openError}
        onClose={() => setOpenError(false)}
        onOK={() => setOpenError(false)}
      />
    </LayoutMain>
  );
}

export default RewardPage;
