import React, { useState, useEffect } from "react";
import DehazeIcon from "@mui/icons-material/Dehaze";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Popover from "@mui/material/Popover";
import { useSelector, useDispatch } from "react-redux";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Path from "app/route/Path";
import { useTranslation } from "react-i18next";
import ChangeLanguageButton from "app/components/ChangeLanguageButton";
import ImageItem from "../ImageItem";
import InfluencerService from "../../services/InfluencerService";
import { EResponseStatus } from "../../helper/constant";
import { setUnreadEmpty, setUnreadMess } from "../../redux/unreadMessReducer";
import { logout } from "../../utils";

function HeaderInfo({ children, resetFilter }) {
  const user = useSelector((state) => state.auth.user);
  const avatar = useSelector((state) => state.profile?.avatar_url);
  const unreadMess = useSelector((state) => state.unreadMess);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    if (!unreadMess && location.pathname !== Path.project_applied) {
      InfluencerService.getListAppliedUnreadMess().then((res) => {
        if (res.status === EResponseStatus.SUCCESS) {
          const { data } = res.data;
          if (
            data.inProgress ||
            data.specialOfferFromOperator ||
            data.waitApprovalFinished
          ) {
            dispatch(setUnreadMess(true));
          }
        }
      });
    }
    if (location.pathname === Path.project_applied) {
      dispatch(setUnreadEmpty());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleMenu = (event) => {
    if (user) {
      setAnchorEl(anchorEl ? null : event.currentTarget);
    } else {
      navigate(Path.login);
    }
  };

  const handleNavigateHome = () => {
    if (location.pathname === Path.home) {
      resetFilter();
    } else {
      navigate(Path.home);
    }
  };

  return (
    <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
      <div className="flex other-info items-center">
        {children}
        <div className="flex items-center">
          <ChangeLanguageButton className="hover:bg-secondary h-10 w-10 mr-3 transition-all duration-200 ease-linear" />
          <button
            type="button"
            onClick={(event) => toggleMenu(event)}
            className="hover:shadow-md flex items-center p-1 rounded-3xl border border-solid border-secondary-300 cursor-pointer transition-all duration-200 ease-linear relative"
          >
            <DehazeIcon fontSize="small" className="ml-1 mr-2" />
            <div className="user-avatar h-8 w-8 rounded-full relative">
              {unreadMess && (
                <div className="text-[10px] bg-[#8F0B6A] text-white min-w-[16px] min-h-[16px] px-1 flex items-center justify-center rounded-full absolute -top-1 -right-1 z-10">
                  <i className="fa-solid fa-exclamation" />
                </div>
              )}
              <ImageItem
                avatar="true"
                containerclassname="!rounded-full"
                skeletonclassname="!rounded-full"
                role="presentation"
                imagesrc={avatar}
                loadavatar="true"
                alt=""
                imgclassname="w-full h-full object-cover rounded-full overflow-hidden"
              />
            </div>
            {/* Sub Menu */}
            <Popover
              id={anchorEl ? "menu-popover" : undefined}
              open={!!anchorEl}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              PaperProps={{
                classes: {
                  root: "sub-menu z-10 w-56 text-left font-bold !rounded-3xl py-4 bg-white shadow-2xl",
                },
              }}
              onClose={() => setAnchorEl(null)}
              className="z-[1032]"
            >
              <button
                type="button"
                className="py-2 px-4 hover:bg-secondary flex md:hidden"
                onClick={handleNavigateHome}
              >
                <span>{t("header.homepage")}</span>
              </button>
              <Link
                to={Path.project_applied}
                className="py-2 px-4 hover:bg-secondary flex"
              >
                <span>{t("header.project_applied")}</span>
                {unreadMess && (
                  <span className="text-[10px] bg-[#8F0B6A] text-white w-[16px] h-[16px] px-1 flex items-center justify-center rounded-full z-10">
                    <i className="fa-solid fa-exclamation" />
                  </span>
                )}
              </Link>
              <Link to={Path.reward} className="py-2 px-4 hover:bg-secondary">
                {t("header.reward")}
              </Link>
              <Link to={Path.favorite} className="py-2 px-4 hover:bg-secondary">
                {t("header.favorite")}
              </Link>
              <Link
                to={Path.account_settings}
                className="py-2 px-4 hover:bg-secondary"
              >
                {t("header.account_settings")}
              </Link>
              <button
                type="button"
                onClick={(e) => logout(e)}
                className="py-2 px-4 hover:bg-secondary w-full text-left"
              >
                {t("header.log_out")}
              </button>
            </Popover>
          </button>
        </div>
      </div>
    </ClickAwayListener>
  );
}

export default HeaderInfo;
