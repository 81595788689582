import React, { useContext } from "react";
import { LayoutContext } from "app/utils";
import LanguageIcon from "@mui/icons-material/Language";
import cx from "classnames";

function ChangeLanguageButton({ className }) {
  const { isChangeLangOpen, setIsChangeLangOpen } = useContext(LayoutContext);

  return (
    <button
      type="button"
      onClick={() => setIsChangeLangOpen(!isChangeLangOpen)}
      className={cx(
        "flex items-center justify-center rounded-full cursor-pointer",
        className
      )}
    >
      <LanguageIcon fontSize="small" />
    </button>
  );
}

export default ChangeLanguageButton;
