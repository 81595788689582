import register from "../pages/Register/translate/cn";
import project from "../pages/Projects/translate/cn";
import home from "../pages/Home/translate/cn";
import auth from "../pages/Login/translate/cn";
import accountSetting from "../pages/AccountSettings/translate/cn";
import reward from "../pages/Reward/translate/cn";
import chat from "../chat/langs/cn";
import favorite from "../pages/Favorite/translate/cn";
import profile from "../pages/Profile/translate/cn";

export default {
  chat,
  home,
  register,
  project,
  auth,
  accountSetting,
  reward,
  favorite,
  profile,
  common: {
    btn: {
      download_csv: "CSVファイルをダウンロード",
      export: "エクスポート",
      continue: "繼續",
      register: "登録",
    },
    message: {
      in_process: "進行中",
      create_success: "註冊已完成。",
      update_success: "更新完成。",
      delete_success: "削除されました。",
      create_fail: "註冊失敗。請重試。",
      update_fail: "更新失敗。請重試。",
      delete_fail: "削除に失敗しました。もう一度お試しください。",
      not_exist: "削除対象が存在しません。",
      wrong_current_password: "目前密碼不正確。",
      no_data_display: "無數據顯示。",
      select_lang: "請選擇語言和地區",
      oversize_file: "最大影像大小為 ({{size}}MB)",
      wrong_format_file: "可以上傳以下文件。\n{{format}}",
      cannot_display_data: "無法讀取資料。請重試。",
      project_not_found: "相關招募案件無法顯示，因為它已被隱藏或刪除",
      project_expired: "無法顯示適用的招募案件，因為其有效期限已過",
      CANT_NOT_APPLY_TO_PROJECT_TWICE: "不允許重複申請該項目。",
      INVALID_EMAIL: "メールアドレスが無効です。",
      EMAIL_EXIST: "您輸入的電子郵件地址無效。",
      NOT_FOUND: "{{field}}未找到帳戶。",
      COMMON_EXCEPTION: "{{field}}未找到帳戶。",
      CAN_NOT_UPDATED: "註冊失敗。",
      one_time_service: "1回無料サービス",
      "Request failed with status code 403": "更新失敗。請重試。",
      "Request failed with status code 401": "更新失敗。請重試。",
      saved_to_wishlist: "已儲存至收藏夾。",
      remove_from_wishlist: "已從收藏夾中刪除。",
      saved_to_wishlist_error: "儲存到收藏夾清單時發生錯誤。",
      INVALID_PASSWORD: "目前密碼不正確。",
      confirm_password_not_match: "密碼不符。",
      wrong_information: "使用者名稱或密碼不正確。",
      all: "全部",
      twitter: "X (舊Twitter)",
      image: "照片",
      video: "影片",
      "image, video": "影片・照片",
      no_input_yet: "未設定",
      not_found: "抱歉，我們找不到您要找的內容！",
      youtube_not_found: "未找到 YouTube 帳戶。",
    },
    action: {
      edit: "編輯",
      cancel: "取消",
      save: "儲存",
      back: "返回",
      filter: "篩選條件",
      select_file: "選擇文件",
      apply: "申請工作",
      submit: "提交",
      clear_all: "全部清除",
      close: "關閉",
      ping_platform: "請求翻譯",
      continue: "繼續",
      yes: "儲存",
      update: "更新",
    },
    price: "¥",
    date_format: {
      dd_mm_yyyy: "DD日MM月YYYY年",
      yyyy_mm_dd: "YYYY年MM月DD日",
      yyyy_mm: "YYYY年MM月",
      mm_dd: "MM月DD日",
      dd: "DD日",
    },
    male: "男性",
    female: "女性",
    other: "無指定性別",
    other1: "無指定性別",
    japan: "日文",
    followers: "追蹤人數",
    followers_1: "追蹤人數",
  },
  validate_message: {
    required: "{{- path}}为必填项。",
    max_length: "{{- path}}超出最大長度{{max}}。",
    wrong_format: "{{- path}}格式不正確。",
  },
  validation_field: {
    prDescription: "自我推薦",
    prUrl1: "URL1",
    prUrl2: "URL2",
    prUrl3: "URL3",
    prFile1: "文件 1",
    prFile2: "文件 2",
    prFile3: "文件 3",
    name: "顯示名",
    gender: "性別",
    postcode: "郵遞區號",
    email: "電子郵件地址",
    self_introduction: "自我介紹",
    instagram_account: "Instagram 帳戶",
    tiktok_account: "Tiktok 帳戶",
    twitter_account: "Twitter 帳戶",
    face_account: "Facebook 帳戶",
    youtube_channel_id: "Youtube 頻道 ID",
    family_name: "姓氏",
    first_name: "名稱",
    current_password: "目前密碼",
    new_password: "新密碼",
    confirm_new_password: "確認密碼",
    confirm_new_password1: "確認密碼",
    url: "URL",
    message: "信息",
    reason: "原因",
    nickname: "顯示名",
    country: "國家",
    city: "縣市",
    street: "州/省",
    address: "地址",
    password: "密碼",
    age_group_start: "網紅的要求年齡範圍",
    age_group_end: "網紅的要求年齡範圍",
    replyMessage: "信息",
    email_message: "請輸入電子郵件。",
    email_invalid_message: "電子郵件帳號格式錯誤。",
    new_password_required_message: "請輸入新密碼。",
    current_password_not_found: "目前密碼为必填项。",
    name_required_message: "請輸入顯示名稱。",
    gender_required_message: "請輸入您的性別。",
    pr_self_required_message: "請輸入您的自我宣傳",
  },
  header: {
    favorite: "我最愛的招募案件",
    favorite1: "我的最愛列表",
    homepage: "網紅首頁",
    project_applied: "申請一覽表",
    reward: "報酬",
    account_settings: "帳戶",
    log_out: "登出",
  },
  change_language: "語言和地區",
  or1: "或",
  or2: "是",
  or3: "",
  footer: {
    privacy: "隱私權條款",
    term_of_use: "條款與條件",
    about_comany: "公司企業資訊",
    currency: "日文",
    copyright: "© Copyright 2023 Meikatsu Inc. All Rights Reserved."
  },
  business_operator: "業者",
  operator_admin: "營運方",
  notification_popup: {
    title: "通知",
    show_more: "請確認詳細資料",
  },
  generation1: "幾歲",
  generation2: "幾歲",
  generation3: "歲以上",
};
