import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";

function TabWithBatch({
  children,
  isUnread,
  setShowRightNotice,
  setShowLeftNotice,
  countMessage = {},
  dataValue,
}) {
  const trackVisibilityElement = () => {
    if (Object.keys(countMessage).length > 0) {
      if (Object.values(countMessage).some((item) => item)) {
        let invisibleList = [];
        const parent = document
          .querySelector(".MuiTabs-scroller")
          .getBoundingClientRect();

        const callback = (entries) => {
          entries.forEach((entry) => {
            const { isIntersecting, target, boundingClientRect } = entry || {};
            const { value } = target?.dataset || {};
            const checkPosition = boundingClientRect.left - parent.left;
            // console.log("boundingClientRect.left", entry);
            // console.log("parent.left", parent.left);
            // if checkPosition > 0 => show/hide notice to the right, otherwise show/hide to the left
            // if element is not visible in the viewpoint
            if (!isIntersecting) {
              // console.log("not intersecting");
              // console.log("value", value);
              // console.log("checkPosition", checkPosition);
              invisibleList.push(value);
              if (checkPosition > 0) {
                setShowRightNotice(
                  invisibleList.some((item) => item === value)
                );
                // console.log("show to right");
              } else {
                setShowLeftNotice(invisibleList.some((item) => item === value));
                // console.log("show to left");
              }
              // if element is visible in the viewpoint
            } else if (isIntersecting) {
              // console.log("intersecting");
              // console.log("value", value);
              // console.log("checkPosition", checkPosition);
              invisibleList = invisibleList.filter((item) => item !== value);
              if (checkPosition > 0) {
                setShowRightNotice(
                  invisibleList.some((item) => item === value)
                );
                // console.log("clear right");
              } else {
                setShowLeftNotice(invisibleList.some((item) => item === value));
                // console.log("clear left");
              }
            }
          });
        };
        const elements = document.querySelectorAll(`.tab`);
        const observer = new IntersectionObserver(callback, {
          // specific the root view-point to track visibility of element
          root: document.querySelector(".MuiTabs-scroller"),
          threshold: 0.1,
        });
        if (elements) {
          elements.forEach((ele) => {
            observer.observe(ele);
          });
        }
      } else {
        setShowLeftNotice(false);
        setShowRightNotice(false);
        // console.log("clear all");
      }
    }
  };

  useEffect(() => {
    trackVisibilityElement();
  });

  return (
    <Box className="relative">
      <Typography component="div" className="!font-[700] !text-[14px]">
        {children}
      </Typography>
      {isUnread && (
        <Typography
          component="div"
          data-value={dataValue}
          className="tab !text-[10px] flex items-center justify-center text-white absolute -right-4 -top-3 bg-[#8F0B6A] rounded-full w-[20px] h-[20px]"
        >
          <i className="fa-solid fa-exclamation" />
        </Typography>
      )}
    </Box>
  );
}

export default TabWithBatch;
