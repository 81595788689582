/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect } from "react";
import { ErrorMessage, Field } from "formik";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  EFileType,
  EResponseStatus,
  MAX_FILE_SIZE,
} from "../../helper/constant";
import CommonService from "../../services/CommonService";
import { setActive } from "../../redux/blockUIReducer";

function UploadAvatar({
  setFieldValue,
  openErrorDiaglog,
  updateProfile,
  values,
  uploaded,
  setUploaded,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onFileChange = (event) => {
    // Allowing file type
    const allowedExtensions = /[^\\/.]+(?=(\.png|\.jpeg|\.jpg|\.heic)$)/;

    const errorImageSizeMess = t("common.message.oversize_file", {
      size: MAX_FILE_SIZE / 1024 / 1024,
    });
    const errorFormatMess = t("common.message.wrong_format_file", {
      format: "PNG、JPEG、JPG、HEIC",
    });
    const files = event.target.files || event.dataTransfer.files;
    if (!files.length) return;
    if (!allowedExtensions.test(files[0].name.toLowerCase())) {
      openErrorDiaglog(errorFormatMess);
    } else if (files[0].size > MAX_FILE_SIZE) {
      openErrorDiaglog(errorImageSizeMess);
    } else {
      let fileFormat = null;
      if (!files[0].type) {
        const blob = new Blob([files[0]], { type: "image/heic" });
        fileFormat = new File([blob], files[0].name, { type: "image/heic" });
      }
      dispatch(setActive(true));
      const data = {
        file: fileFormat || files[0],
        type: EFileType.IMAGE,
      };
      CommonService.uploadFile(data)
        .then((response) => {
          if (response.status === EResponseStatus.CREATED) {
            setFieldValue(
              "avatar_url",
              response.data.data.thumbnail_url || response.data.data.media_url
            );
            setUploaded(true);
          }
        })
        .catch(() => {
          openErrorDiaglog(t("project.apply.error_upload"));
        })
        .finally(() => dispatch(setActive(false)));
    }
  };

  useEffect(() => {
    if (uploaded) {
      updateProfile(values);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploaded]);

  return (
    <div className="upload-avatar">
      <Field
        onChange={(event) => {
          onFileChange(event);
        }}
        hidden
        value=""
        id="avatar_url"
        name="avatar_url"
        type="file"
        className="h-0 w-0 overflow-hidden"
      />
      <ErrorMessage component="div" name="avatar_url" className="error-msg" />
      <label
        htmlFor="avatar_url"
        className="text-sm font-semibold hover:text-black underline cursor-pointer"
      >
        {t("profile.upload_avatar")}
      </label>
    </div>
  );
}

export default UploadAvatar;
