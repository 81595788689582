export default {
  for_influencer: "成為網紅",
  open_door: "開啟大門",
  Start_registration: "開始註冊",
  Twitter: "X (舊Twitter)",
  Google: "繼續使用谷歌",
  Line: "以LINE繼續",
  Login_or_Register: "登入或註冊",
  welcome: "歡迎來到Buzz Traveler",
  register_label: "登入會員",
  register_mess:
    "感謝您註冊成為會員。\n我們已將臨時密碼的說明傳送到您輸入的電子郵件地址。\n請點選電子郵件中提供的變更密碼的連結，輸入電子郵件中提供的臨時密碼，然後設定密碼。",
  enter_password: "請輸入密碼",
  forgot_password_mess: "如果您忘記密碼，請點擊這裡",
  change_pass_info_title: "密碼重置訊息",
  change_pass_info_mess:
    "一封用於重設密碼的電子郵件已傳送至您註冊的電子郵件地址。請點選電子郵件中提供的連結，輸入電子郵件中提供的臨時密碼，然後設定新密碼。",
  change_pass_left_title: "請設定該密碼",
  wrong_format_new_pass:
    "請輸入 8 至 64 個字元的密碼，包括半角字母數字字元和符號。",
  require_confirm_pass: "請輸入您的新密碼（用於確認）。",
  tempo_password: "郵件中裡的臨時密碼",
  password_format_notice:
    "請輸入 8 至 64 個字元的密碼，包括半角字母數字字元和符號。",
};
