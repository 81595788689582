/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { Button, Rating } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import useMediaQuery from "@mui/material/useMediaQuery";
import UserService from "app/services/UserService";
import LayoutMain from "../../layouts/LayoutMain";
import MainContent from "../../components/common/MainContent";
import ProfileContent from "./ProfileContent";
import SuccessDialog from "../../components/common/SuccessDialog";
import ErrorDialog from "../../components/common/ErrorDialog";
import InfoDialog from "../../components/common/InfoDialog";
import ImageItem from "../../components/ImageItem";
import { setProfileStore } from "../../redux/profileReducer";
import Path from "../../route/Path";
import { checkValueChange, generateSNSIcon } from "../../utils";
import UploadAvatar from "./UploadAvatar";
import { updateProfileAction } from "../../utils/profile";
import { EResponseStatus, onlyNumberRegex } from "../../helper/constant";

const listSNS = ["instagram", "tiktok", "facebook", "youtube"];

function Profile() {
  const matches = useMediaQuery("(min-width:1128px)");
  const { profile } = useSelector((state) => state);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [showEditProfile, setShowEditProfile] = useState(false);
  const formikRef = useRef();
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [openCancel, setOpenCancel] = useState(false);
  const [message, setMessage] = useState("");
  const [uploaded, setUploaded] = useState(false);
  const validationSchema = Yup.object().shape({
    avatar_url: Yup.string(),
    self_introduction: Yup.string(),
    instagram: Yup.string(),
    instagram_followers: Yup.string(),
    tiktok: Yup.string(),
    tiktok_followers: Yup.string(),
    facebook: Yup.string(),
    facebook_followers: Yup.string(),
    youtube: Yup.string(),
    youtube_followers: Yup.string(),
  });
  const initialValues = {
    avatar_url: "",
    self_introduction: "",
    instagram: "",
    instagram_followers: "",
    tiktok: "",
    tiktok_followers: "",
    facebook: "",
    facebook_followers: "",
    youtube: "",
    youtube_followers: "",
  };
  const [formValues, setFormValues] = useState({});

  const formatDetailData = (data) => {
    const formatData = {
      ...data,
      avatar_url: data.avatar_url || "",
      self_introduction: data.self_introduction || "",
      instagram: data.instagram || "",
      instagram_followers: Number(data.instagram_followers) || "",
      tiktok: data.tiktok || "",
      tiktok_followers: Number(data.tiktok_followers) || "",
      facebook: data.facebook || "",
      facebook_followers: Number(data.facebook_followers) || "",
      youtube: data.youtube || "",
    };
    return formatData;
  };

  // confirm cancel
  const onConfirm = () => {
    const { setValues } = formikRef.current;
    setShowEditProfile(false);
    setValues(formatDetailData(profile));
    setOpenCancel(false);
  };

  const openSuccessDiaglog = (mess) => {
    setOpenSuccess(true);
    setMessage(mess || t("common.message.update_success"));
  };

  const openErrorDiaglog = (mess) => {
    setOpenError(true);
    setMessage(mess || t("common.message.update_fail"));
  };

  useEffect(() => {
    // get user profile
    UserService.getProfile().then((res) => {
      if (res.status === EResponseStatus.SUCCESS) {
        dispatch(setProfileStore(res.data.data));
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // set init formik values from profile store
  useEffect(() => {
    if (formikRef && formikRef.current && profile) {
      const { setValues } = formikRef.current;
      const formatData = formatDetailData(profile);
      setValues(formatData);
      setFormValues(formatData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  const handleFollowersChange = (e, setFieldValue) => {
    const { value = "", name = "" } = e.target;
    if (value === "" || onlyNumberRegex.test(value)) {
      setFieldValue(name, value);
    }
  };

  const handleSocialChange = (e, setFieldValue, followersName, values) => {
    const { name = "", value = "" } = e.target;
    setFieldValue(name, value);
    if (value === "") {
      setFieldValue(followersName, "");
    }
    if (value === formValues[name] && !values[followersName]) {
      setFieldValue(followersName, formValues[followersName]);
    }
  };

  const updateProfile = (values) => {
    // const { setValues } = context;
    updateProfileAction({
      profile,
      values,
      callbackSuccess: () => {
        dispatch(setProfileStore(values));
        setShowEditProfile(false);
        openSuccessDiaglog();
      },
      callbackError: (error) => {
        // setValues(formValues);
        if (error?.response?.status === EResponseStatus.BAD_REQUEST) {
          let field = "";
          let capitalizeField = "";
          let errorMess = "";
          const errorResponse =
            error?.response.data.message || error?.response.data.messages[0];
          const splitLine = errorResponse.indexOf("|");
          if (splitLine === -1) {
            errorMess = errorResponse;
          } else {
            field = errorResponse.substring(0, splitLine);
            capitalizeField = field.charAt(0).toUpperCase() + field.slice(1);
            errorMess = errorResponse.substring(splitLine + 1);
          }
          if (field === 'youtube') {
            openErrorDiaglog(
              t(`common.message.youtube_not_found`)
            );
          }else {
            openErrorDiaglog(
              t(`common.message.${errorMess}`, { field: capitalizeField })
            );
          }
        } else {
          openErrorDiaglog();
        }
      },
      callbackFinally: () => {
        setUploaded(false);
      },
    });
  };

  return (
    <LayoutMain showMobileHeader path={Path.account_settings} showToXL>
      <MainContent>
        <Formik
          innerRef={formikRef}
          validateOnMount
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={updateProfile}
        >
          {(props) => {
            const { setFieldValue, values } = props;
            const isValuesHasChanged = checkValueChange(
              formValues || {},
              values || {},
              false
            );
            return (
              <Form className="w-full h-full">
                <div className="h-full w-full">
                  <div className="w-full px-4 pb-9 md:max-w-[680px] xl:max-w-[1080px] mx-auto text-REGULAR_TEXT_COLOR">
                    <div className="w-full flex">
                      <div className="hidden xl:block w-1/3 pr-10">
                        <div className="w-full h-[400px] p-6 pb-10 rounded-xl border border-border">
                          <div className="w-full flex flex-col items-center h-full border-b border-border">
                            <div className="w-[128px] h-[128px] mb-3">
                              <ImageItem
                                avatar="true"
                                loadavatar="true"
                                skeletonclassname="!rounded-full"
                                containerclassname="!rounded-full"
                                role="presentation"
                                imagesrc={values.avatar_url}
                                alt=""
                                imgclassname="w-full h-full object-cover rounded-full overflow-hidden"
                              />
                            </div>
                            {matches && (
                              <UploadAvatar
                                setFieldValue={setFieldValue}
                                openErrorDiaglog={openErrorDiaglog}
                                updateProfile={updateProfile}
                                values={values}
                                uploaded={uploaded}
                                setUploaded={setUploaded}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="w-full xl:w-2/3 flex flex-col gap-6">
                        <div className="flex items-center justify-between gap-2">
                          <div className="flex-1">
                            {/* <h1 className="text-[26px] leading-9 font-semibold mb-2">{`こんにちは、${profile.first_name}です`}</h1> */}

                            <p className="text-sm font-normal leading-[18px] text-danger-DESCRIPTION">
                              {t("profile.register_as", {
                                date: dayjs(profile?.created_at).format(
                                  t("common.date_format.yyyy_mm_dd")
                                ),
                              })}
                            </p>

                            <button
                              type="button"
                              className={`text-sm pt-4 font-semibold hover:text-black underline ${
                                showEditProfile
                                  ? "cursor-not-allowed opacity-30"
                                  : "cursor-pointer"
                              }`}
                              disabled={showEditProfile}
                              onClick={() => {
                                setShowEditProfile(true);
                              }}
                            >
                              {t("profile.edit_profile")}
                            </button>
                          </div>
                          <div className="xl:hidden flex flex-col items-center">
                            <div className="shrink-0 block">
                              <div className="w-[88px] h-[88px]">
                                <ImageItem
                                  loadavatar="true"
                                  skeletonclassname="!rounded-full"
                                  avatar="true"
                                  containerclassname="!rounded-full"
                                  role="presentation"
                                  imagesrc={values.avatar_url}
                                  alt=""
                                  imgclassname="w-full h-full object-cover rounded-full overflow-hidden"
                                />
                              </div>
                            </div>
                            {!matches && (
                              <UploadAvatar
                                setFieldValue={setFieldValue}
                                openErrorDiaglog={openErrorDiaglog}
                                updateProfile={updateProfile}
                                values={values}
                                uploaded={uploaded}
                                setUploaded={setUploaded}
                              />
                            )}
                          </div>
                        </div>

                        <div className="rating-influ flex flex-col gap-1">
                          <div className="font-bold text-[20px]">
                            {t("profile.rating_from_admin")}
                          </div>
                          <Rating
                            size="small"
                            name="read-only"
                            precision={0.1}
                            value={profile?.rating}
                            readOnly
                          />
                        </div>

                        {showEditProfile ? (
                          <div className="flex flex-col gap-6">
                            <div className="sns-influ flex flex-col gap-1">
                              <div className="font-bold text-[20px]">
                                {t("profile.sns_activity")}
                              </div>
                              <div className="flex flex-col gap-7 items-start">
                                {listSNS.map((item) => {
                                  return (
                                    <div
                                      key={item}
                                      className="flex flex-col md:flex-row gap-4 w-full items-start"
                                    >
                                      <div className="flex items-center gap-2 w-full">
                                        {generateSNSIcon(item, "!w-5 !h-5")}
                                        <div className="flex items-center w-full">
                                          {item === "youtube" ? "" : <div>@</div>}
                                          <Field
                                            name={item}
                                            className={item === "youtube" ? "ml-4 max-w-[230px] w-full px-2 py-1 rounded-lg !outline-none border border-solid border-secondary-200 focus:border-[#000] focus:border-[2px]" : "max-w-[230px] w-full px-2 py-1 rounded-lg !outline-none border border-solid border-secondary-200 focus:border-[#000] focus:border-[2px]"}
                                            onChange={(e) =>
                                              handleSocialChange(
                                                e,
                                                setFieldValue,
                                                `${item}_followers`,
                                                values
                                              )
                                            }
                                          />
                                        </div>
                                      </div>
                                      <ErrorMessage
                                        component="div"
                                        name={item}
                                        className="error-msg ml-8"
                                      />
                                      {item !== "youtube" && (
                                        <div className="flex items-center gap-2 w-full">
                                          <label
                                            className="font-bold text-sm flex-shrink-0"
                                            htmlFor={`${item}_followers`}
                                          >
                                            {t("common.followers_1")}
                                          </label>
                                          <Field
                                            disabled={!values[item]}
                                            id={`${item}_followers`}
                                            name={`${item}_followers`}
                                            className="max-w-[198px] md:max-w-[230px] w-full px-2 py-1 rounded-lg !outline-none border border-solid border-secondary-200 focus:border-[#000] focus:border-[2px]"
                                            maxLength={8}
                                            onChange={(e) =>
                                              handleFollowersChange(
                                                e,
                                                setFieldValue
                                              )
                                            }
                                          />
                                        </div>
                                      )}
                                    </div>
                                  );
                                })}
                              </div>
                            </div>

                            <div className="self-intro-influ">
                              <label
                                htmlFor="self_introduction"
                                className="mb-1 inline-block text-[20px] font-bold"
                              >
                                {t("profile.self_introduction")}
                              </label>
                              <div className="form-input">
                                <Field
                                  as="textarea"
                                  id="self_introduction"
                                  name="self_introduction"
                                  className="min-h-[200px] w-full p-2.5 rounded-lg !outline-none border border-solid border-secondary-200 focus:border-[#000] focus:border-[2px]"
                                />
                                <ErrorMessage
                                  component="div"
                                  name="self_introduction"
                                  className="error-msg"
                                />
                              </div>
                            </div>

                            <div className="w-full">
                              <div className="flex items-center justify-between">
                                <button
                                  type="button"
                                  className="text-base font-semibold leading-5 px-3 py-2 underline regular-btn-bg rounded-md"
                                  onClick={() => {
                                    if (isValuesHasChanged) {
                                      setOpenCancel(true);
                                    } else {
                                      onConfirm();
                                    }
                                  }}
                                >
                                  {t("common.action.cancel")}
                                </button>
                                <Button
                                  disabled={!isValuesHasChanged}
                                  variant="contained"
                                  type="submit"
                                  className="!text-base !font-semibold !text-white !bg-REGULAR_TEXT_COLOR !px-6 !py-3 !rounded-lg"
                                >
                                  {t("common.action.save")}
                                </Button>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <ProfileContent
                            selfIntroduction={values.self_introduction}
                            address={values.address}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </MainContent>
      <SuccessDialog
        message={message}
        open={openSuccess}
        onClose={() => setOpenSuccess(false)}
        onOK={() => setOpenSuccess(false)}
      />
      <ErrorDialog
        message={message}
        open={openError}
        onClose={() => setOpenError(false)}
        onOK={() => setOpenError(false)}
      />
      <InfoDialog
        open={openCancel}
        setOpen={setOpenCancel}
        onSubmit={onConfirm}
      >
        {t("profile.cancel_change_confirm")}
      </InfoDialog>
    </LayoutMain>
  );
}

export default Profile;
