/* eslint-disable react/no-danger */
import React from "react";
import Header from "app/components/common/Header";
import MainContent from "app/components/common/MainContent";
import LayoutMain from "app/layouts/LayoutMain";

function PrivacyPolicy() {
  const content = `個人情報保護方針<br />
                当社は、NCホテルが提唱する日本初の世界基準モデル「新都市型ホテル（高機能、高品質、環境対応型）」のコンセプトに基づき、
                社名に込められた「いつも気持ちの良い環境（Always Pleasant
                Amenity）」を提供し、ゲストが誇りをもって宿泊し、
                スタッフも誇りをもってゲストに心地よいおもてなしをすることを事業の使命としております。
                この事業を遂行するにあたり、当社は、お客様、及びお取引先の皆様から個人情報を提供いただく立場にあります。
                このため、個人情報の保護水準をさらに高める施策として、以下の方針のもと個人情報を取扱い、継続的に改善いたします。<br /><br />
                1. 個人情報の取得<br/>
                当社は、個人情報を業務上必要な範囲内で適切に取得します。`;

  return (
    <LayoutMain>
      <Header />
      <MainContent>
        <div className="grid grid-cols-12 gap-4 mt-24 privacy">
          <div className="col-span-12 lg:col-span-7 xl:col-span-8">
            <div className="flex flex-col h-full">
              <div className="mt-5">
                <div className="flex items-center justify-between text-xl font-bold">
                  <p className="mr-3">プライバシーポリシー</p>
                </div>
              </div>

              <p
                dangerouslySetInnerHTML={{ __html: content }}
                className="content px-3 my-8 flex-1"
              />
            </div>
          </div>
        </div>
      </MainContent>
    </LayoutMain>
  );
}

export default PrivacyPolicy;
