export default {
  prepare_payment: "準備付款",
  payable: "可付款",
  payment_request: "要求付款",
  paid: "已付款",
  all: "所有",
  price: "金額",
  request_pay: "付款申請",
  notice: "※當可供支付的金額超過 ¥ 50,000 時，可申請付款。",
  title: "招募案件名稱",
  fee: "報酬金額",
  finished_at: "批准完成日期",
};
