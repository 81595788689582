import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null,
  token: {
    accessToken: null,
    refreshToken: null,
  },
  showNotify: true,
};

const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthUser: (state, action) => {
      state.user = action.payload;
    },

    setAuthToken: (state, action) => {
      state.token = action.payload;
    },

    setShowNotify: (state, action) => {
      state.showNotify = action.payload;
    },

    setAuthStateDefault: (state) => {
      state.user = null;
      state.token = {
        accessToken: null,
        refreshToken: null,
      };
      state.showNotify = true;
    },
  },
});

export const { setAuthUser, setAuthToken, setShowNotify, setAuthStateDefault } =
  slice.actions;

export default slice.reducer;
