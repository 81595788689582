import React, { useState, useEffect } from "react";
import AuthService from "app/services/AuthService";
import { EResponseStatus } from "app/helper/constant";
import { useDispatch, useSelector } from "react-redux";
import { setActive } from "app/redux/blockUIReducer";
import SuccessDialog from "app/components/common/SuccessDialog";
import ErrorDialog from "app/components/common/ErrorDialog";
import { setSocial, setSocialNull } from "app/redux/socialReducer";
import { useLocation } from "react-router-dom";
import InfoDialog from "app/components/common/InfoDialog";
import jwtDecode from "jwt-decode";
import axios from "axios";
import { appendFormData, getGoogleUrl, getLineUrl } from "app/utils";
import { useTranslation } from "react-i18next";

const LIST_SNS_CONNECTED = [
  // {
  //   name: "Facebook",
  //   value: "facebook_influencer",
  // },
  {
    name: "Google",
    value: "google_influencer",
  },
  // {
  //   name: "Tiktok",
  //   value: "tiktok_influencer",
  // },
  {
    name: "LINE",
    value: "line_influencer",
  },
];

function SNSConnected() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const social = useSelector((state) => state.social);
  const redirectUri = `${window.location.origin}/account-settings/login-security`;
  const [lstSnsConnected, setLstSnsConnected] = useState([]);
  const [errorMess, setErrorMess] = useState("");
  const [successMess, setSuccessMess] = useState("");
  const [infoMess, setInfoMess] = useState("");
  const { social_id_provider: socialProvider } =
    useSelector((state) => state.auth.user) || {};

  // social to disconnect
  const [value, setValue] = useState("");

  // get code query in url
  const paramSearch = new URLSearchParams(location.search);
  const code = paramSearch.get("code");

  const getAllSnsConnected = () => {
    AuthService.getAllSnsConnect().then((response) => {
      if (response.status === EResponseStatus.SUCCESS) {
        setLstSnsConnected(response.data.data);
      }
    });
  };

  useEffect(() => {
    getAllSnsConnected();
  }, []);

  const getLinkedAccount = (platform, data) => {
    dispatch(setActive(true));
    AuthService.connectWithSocial(platform, data)
      .then((response) => {
        if (response.status === EResponseStatus.CREATED) {
          setSuccessMess(t("accountSetting.login_security.linked_success"));
          getAllSnsConnected();
        }
      })
      .catch((error) => {
        if (
          error?.response?.status === EResponseStatus.NOT_FOUND &&
          error?.response?.data?.message === "DATA_EXIST"
        ) {
          setErrorMess(t("accountSetting.login_security.email_exist"));
        } else {
          setErrorMess(t("accountSetting.login_security.linked_fail"));
        }
      })
      .finally(() => {
        dispatch(setActive(false));
        dispatch(setSocialNull(null));
      });
  };

  const disonnectSocialConnected = () => {
    setInfoMess("");
    dispatch(setActive(true));
    AuthService.disconnectWithSocial(value)
      .then((response) => {
        if (response.status === EResponseStatus.SUCCESS) {
          setSuccessMess(t("accountSetting.login_security.unlink_success"));
          getAllSnsConnected();
        }
      })
      .catch(() => {
        setErrorMess(t("accountSetting.login_security.unlink_fail"));
      })
      .finally(() => {
        dispatch(setActive(false));
      });
  };

  const connectLine = (codes) => {
    const data = {
      grant_type: "authorization_code",
      code: codes,
      redirect_uri: redirectUri,
      client_id: process.env.REACT_APP_LINE_CLIENT_ID,
      client_secret: process.env.REACT_APP_LINE_CLIENT_SECRET,
      social_id_provider: `${social}_influencer`,
    };
    getLinkedAccount(social, data);
  };

  const connectGoogle = (codes) => {
    const data = {
      grant_type: "authorization_code",
      code: codes,
      redirect_uri: redirectUri,
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      client_secret: process.env.REACT_APP_GOOGLE_CLIENT_SECRET,
    };

    // exchange code to get account info
    axios
      .post("https://oauth2.googleapis.com/token", appendFormData(data))
      .then((res) => {
        if (res.status === EResponseStatus.SUCCESS) {
          const info = jwtDecode(res?.data?.id_token);
          const payload = {
            login_id: info?.email,
            firstName: info?.given_name || "",
            lastName: info?.family_name || "",
            picture: info?.picture || "",
            displayName: info?.name || "",
            accessToken: res?.data?.access_token,
            social_id_provider: `${social}_influencer`,
          };
          getLinkedAccount(social, payload);
        }
      });
  };

  const renderConnectBtnWithType = (sns) => {
    if (sns === "google_influencer") {
      return (
        <button
          disabled={socialProvider === sns}
          type="button"
          onClick={() => {
            if (lstSnsConnected.includes(sns)) {
              setValue("google");
              setInfoMess(
                t("accountSetting.login_security.unlink_notice", {
                  sns: "Google",
                })
              );
            } else {
              dispatch(setSocial("google"));
              window.location.href = getGoogleUrl(redirectUri);
            }
          }}
        >
          {lstSnsConnected.includes(sns)
            ? t("accountSetting.login_security.disconnect")
            : t("accountSetting.login_security.connecting")}
        </button>
      );
    }
    if (sns === "line_influencer") {
      return (
        <button
          disabled={socialProvider === sns}
          type="button"
          onClick={() => {
            if (lstSnsConnected.includes(sns)) {
              setValue("line");
              setInfoMess(
                t("accountSetting.login_security.unlink_notice", {
                  sns: "LINE",
                })
              );
            } else {
              dispatch(setSocial("line"));
              window.location.href = getLineUrl(redirectUri);
            }
          }}
        >
          {lstSnsConnected.includes(sns)
            ? t("accountSetting.login_security.disconnect")
            : t("accountSetting.login_security.connecting")}
        </button>
      );
    }
    return "";
  };

  useEffect(() => {
    if (code) {
      if (social === "line") {
        connectLine(code);
      }
      if (social === "google") {
        connectGoogle(code);
      }
    } else {
      dispatch(setSocial(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {LIST_SNS_CONNECTED.map((item, index) => {
        return (
          <div
            key={item.value}
            className={`w-full h-auto flex items-start justify-between py-6 ${
              index !== LIST_SNS_CONNECTED.length - 1
                ? "border-b border-border"
                : ""
            }`}
          >
            <div className="flex-1 flex flex-col text-base">
              <div className="w-full font-semibold">{item.name}</div>
              <div className="w-full font-normal mt-2">
                {lstSnsConnected.includes(item.value)
                  ? t("accountSetting.login_security.connected")
                  : t("accountSetting.login_security.not_connect")}
              </div>
            </div>

            <div className="text-[#008489] font-semibold hover:underline cursor-pointer">
              {renderConnectBtnWithType(item.value)}
            </div>
          </div>
        );
      })}
      <SuccessDialog
        message={successMess}
        open={!!successMess}
        onClose={() => setSuccessMess("")}
        onOK={() => setSuccessMess("")}
      />
      <ErrorDialog
        message={errorMess}
        open={!!errorMess}
        onClose={() => setErrorMess("")}
        onOK={() => setErrorMess("")}
      />
      <InfoDialog
        open={!!infoMess}
        setOpen={() => {
          setInfoMess("");
          setValue("");
        }}
        onSubmit={() => disonnectSocialConnected()}
      >
        {infoMess}
      </InfoDialog>
    </>
  );
}

export default SNSConnected;
