import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import Path from "./Path";

export default function UnAuthRoute() {
  const token = useSelector((state) => state.auth.token?.accessToken);
  const location = useLocation();

  if (token) {
    if (location.pathname === Path.login && !location.search) {
      return <Navigate to={Path.home} />;
    }
    return <Outlet />;
  }
  return <Outlet />;
}
