/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from "react";
import cx from "classnames";
import { Skeleton } from "@mui/material";

function VideoItem(props) {
  const {
    imagesrc,
    containerclassname,
    accessible,
    skeletonclassname,
    videoclassname,
  } = props;
  const [imageSoure, setImageSource] = useState(imagesrc);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (imagesrc) {
      setImageSource(imagesrc);
    }
  }, [imagesrc, isLoading]);

  return (
    <div
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
      tabIndex={accessible ? 0 : -1}
      className={`relative h-full overflow-hidden ${containerclassname}`}
    >
      <Skeleton
        animation="wave"
        variant="rectangular"
        className={cx(
          `!absolute !w-full !h-full top-0 left-0 z-10 p-1 ${skeletonclassname}`,
          {
            block: isLoading,
            "!hidden": !isLoading,
          }
        )}
      />
      <video
        {...props}
        onLoadedData={() => setTimeout(() => setIsLoading(false), 500)}
        className={cx(videoclassname, {
          visible: !isLoading,
          invisible: isLoading,
        })}
      >
        <track kind="captions" />
        <source src={imageSoure} type="video/mp4" />
      </video>
    </div>
  );
}

export default VideoItem;
