import React from "react";
import MainContent from "app/components/common/MainContent";
import LayoutMain from "app/layouts/LayoutMain";
import PcContent from "./PcContent";

function AccountSettings() {

  return (
    <LayoutMain>

      <MainContent>
        <PcContent />
      </MainContent>
    </LayoutMain>
  );
}

export default AccountSettings;
