import React, { useCallback, useContext, useEffect, useRef } from "react";
import CloseIcon from "@mui/icons-material/Close";
import _ from "lodash";
import SearchIcon from "@mui/icons-material/Search";
import { HomeContext } from "app/pages/Home/Home";
import { useTranslation } from "react-i18next";
import i18next from 'i18next';

function AddressSearchInput(props) {
  const { t } = useTranslation();
  const { filterValues, setFilterValues } = props;
  const {
    showSearchPlacesAround,
    setShowSearchPlacesAround,
    searchPlacesAround,
    setSearchPlacesAround,
  } = useContext(HomeContext);
  const searchBoxnRef = useRef();

  const handleClickDocument = useCallback(
    (e) => {
      if (searchBoxnRef.current && searchBoxnRef.current.contains(e.target))
        return;
      setShowSearchPlacesAround(false);
    },
    [setShowSearchPlacesAround]
  );

  useEffect(() => {
    // eslint-disable-next-line no-undef
    document.addEventListener("click", handleClickDocument);

    return () => {
      // eslint-disable-next-line no-undef
      document.removeEventListener("click", handleClickDocument);
    };
  }, [handleClickDocument]);

  const showPosition = (position) => {
    setFilterValues({
      ...filterValues,
      longitude: position?.coords?.longitude,
      latitude: position?.coords?.latitude,
      page: 1,
    });
  };

  const errorCallback = () => {};

  const updateSearchPlaceNearParams = (newValue) => {
    if (newValue) {
      navigator.geolocation.getCurrentPosition(showPosition, errorCallback, {
        enableHighAccuracy: true,
      });
    } else {
      setFilterValues({
        ...filterValues,
        longitude: "",
        latitude: "",
        page: 1,
      });
    }
    setSearchPlacesAround(newValue);
  };

  const onChangeSearch = (event) => {
    const { value } = event.target;
    setFilterValues({
      ...filterValues,
      address: value,
      locale: value ? i18next.resolvedLanguage : "",
      page: 1,
    });
  };

  const debouncedChangeHandler = _.debounce((event) => {
    onChangeSearch(event);
  }, 1000);

  return (
    <div className="flex-1 h-auto min-w-[250px] relative" ref={searchBoxnRef}>
      <label htmlFor="search_address" className="w-full h-12 block relative">
        <div className="absolute top-1/2 right-2 -translate-y-1/2 w-8 h-8 bg-[#0E5CB5] rounded-full ">
          <span className="w-full h-full flex items-center justify-center text-white text-base">
            <SearchIcon color="inherit" fontSize="inherit" />
          </span>
        </div>

        <input
          maxLength={256}
          key={filterValues?.address}
          id="search_address"
          type="text"
          className={`block w-full h-full text-base leading-5 text-REGULAR_TEXT_COLOR pl-5 pr-12 rounded-full outline-none shadow-md ${
            filterValues?.address || searchPlacesAround
              ? "border-2 border-black"
              : "border border-border"
          }`}
          style={{ WebkitAppearance: "none" }}
          placeholder={t("home.search_placeholder")}
          defaultValue={filterValues?.address}
          onChange={(e) => {
            debouncedChangeHandler(e);
          }}
          onFocus={() => setShowSearchPlacesAround(true)}
        />
      </label>

      {(searchPlacesAround || filterValues?.address) && (
        <div className="w-[14px] h-[14px] absolute -top-[2px] right-[1px] bg-black rounded-full" />
      )}

      <div
        className={`z-[1020] absolute top-16 w-full h-auto rounded-lg shadow-sm border border-[#dddddd] bg-white py-4 ${
          !showSearchPlacesAround ? "hidden" : ""
        }`}
        style={{ WebkitAppearance: "none" }}
      >
        <div
          role="presentation"
          className={`cursor-pointer w-full h-16 pl-5 pr-4 flex items-center justify-between bg-white hover:bg-[#f2f2f2] relative ${
            searchPlacesAround ? "bg-[#f2f2f2]" : "bg-white hover:bg-[#f2f2f2]"
          }`}
          onClick={() => {
            if (!searchPlacesAround) updateSearchPlaceNearParams(true);
          }}
        >
          <div className="flex items-center">
            <div className="w-10 h-10 rounded-full border-2 border-[#dddddd] flex items-center justify-center mr-3">
              <svg width="24" height="24" viewBox="0 0 24 24">
                <path
                  fillRule="evenodd"
                  d="M19.497 4.612a.85.85 0 011.08 1.072l-5.055 15.29c-.254.769-1.337.781-1.608.019l-2.487-6.988-7.068-2.673c-.746-.282-.728-1.344.028-1.6l15.11-5.12zm-.754 1.84L6.586 10.57l5.653 2.138a.85.85 0 01.5.51l1.953 5.487L18.743 6.45z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            <p className="text-base font-semibold">{t("home.places_around")}</p>
          </div>

          {searchPlacesAround ? (
            <button
              type="button"
              className="w-8 h-8 rounded-full hover:bg-gray-200 flex items-center justify-center"
              onClick={(e) => {
                e.stopPropagation();
                updateSearchPlaceNearParams(false);
              }}
            >
              <CloseIcon />
            </button>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default AddressSearchInput;
