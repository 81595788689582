// i-002-002
import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import LayoutMain from "app/layouts/LayoutMain";
import { Link, useNavigate, useParams } from "react-router-dom";
import InfluencerService from "app/services/InfluencerService";
import {
  EProjectReward,
  EResponseStatus,
  toastDefaultOptions,
} from "app/helper/constant";
import dayjs from "dayjs";
import AppsIcon from "@mui/icons-material/Apps";
import { Button, useMediaQuery } from "@mui/material";
import {
  changeGeneration,
  extractYouTubeUrl,
  formatNumber,
  generateSNSIcon, getMediaName,
  getProjectDetailByLanguage,
  getYouTubeEmbedUrl,
  projectFeeDisplay,
  removeYouTubeUrls,
} from "app/utils";
import MainContent from "app/components/common/MainContent";
import SkeletonItem from "app/components/SkeletonItem";
import { toast } from "react-toastify";
import ListImageComponent from "./ListImageComponent";
import Path from "../../route/Path";
import ErrorDialog from "../../components/common/ErrorDialog";
import Media from "../../components/Media";

const utc = require("dayjs/plugin/utc");
const timezone = require("dayjs/plugin/timezone");

dayjs.extend(utc);
dayjs.extend(timezone);

function Detail() {
  const navigate = useNavigate();
  const params = useParams();
  const { t, i18n } = useTranslation();
  const [favorite, setFavorite] = useState(false);
  const [project, setProject] = useState({});
  const { projectId } = params;
  const [isLoading, setIsLoading] = useState(true);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [viewMore, setViewMore] = useState(false);
  const [loadError, setLoadError] = useState(false);
  const [message, setMessage] = useState("");
  const [description, setDescription] = useState("");
  const [originalDescrip, setOriginalDescrip] = useState("");
  const [imageSelected, setImageSelected] = useState("");
  const [openError, setOpenError] = useState(false);
  const [embedYoutubeList, setEmbedYoutubeList] = useState([]);
  const isPC = useMediaQuery("(min-width:768px)");
  const subStringAt = isPC ? 90 : 70;

  const openErrorDiaglog = (mess) => {
    setOpenError(true);
    setMessage(mess);
  };

  const handleClose = () => {
    if (loadError) {
      navigate(Path.home);
    } else {
      setOpenError(false);
    }
  };

  const handleSubStringDescription = useCallback(
    (value, isViewMore) => {
      setOriginalDescrip(value);
      if (value && value?.length >= subStringAt && !isViewMore) {
        const text = value;
        const result = `${text.substring(0, subStringAt)}...`;
        setDescription(result);
      } else {
        setDescription(value);
      }
    },
    [subStringAt]
  );

  const handleEmbedYoutubeUrl = useCallback(
    (desrip, isViewMore) => {
      if (desrip) {
        const youtubeUrls = extractYouTubeUrl(desrip);
        if (youtubeUrls?.length) {
          const embedLinkList = getYouTubeEmbedUrl(youtubeUrls);
          setEmbedYoutubeList(embedLinkList);
          const cleanedString = removeYouTubeUrls(desrip);
          handleSubStringDescription(cleanedString, isViewMore);
        } else {
          setEmbedYoutubeList([]);
          handleSubStringDescription(desrip, isViewMore);
        }
      } else {
        setEmbedYoutubeList([]);
        handleSubStringDescription(desrip, isViewMore);
      }
    },
    [handleSubStringDescription]
  );

  useEffect(() => {
    setIsLoading(true);
    InfluencerService.getAdProjectDetail(projectId)
      .then((response) => {
        if (response.status === EResponseStatus.SUCCESS) {
          // #663 redirect when project end recruitment
          const endRecruitmentAt = response.data.data.end_recruitment_at;
          if (
            endRecruitmentAt &&
            dayjs(endRecruitmentAt).tz("Asia/Tokyo").isBefore(dayjs())
          ) {
            openErrorDiaglog(t("common.message.project_expired"));
            setLoadError(true);
          } else {
            setProject(response.data.data);
            setFavorite(response.data.data.is_favorite);
            handleEmbedYoutubeUrl(
              getProjectDetailByLanguage(response.data.data)?.description
            );
          }
        }
      })
      .catch((error) => {
        if (error.response.status === EResponseStatus.NOT_FOUND) {
          openErrorDiaglog(t("common.message.project_not_found"));
        } else {
          openErrorDiaglog(t("common.message.cannot_display_data"));
        }
        setLoadError(true);
      })
      .finally(() => setIsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, navigate]);

  useEffect(() => {
    if (Object.keys(project).length > 0) {
      handleEmbedYoutubeUrl(
        getProjectDetailByLanguage(project)?.description,
        viewMore
      );
    }
  }, [t, project, handleEmbedYoutubeUrl, viewMore]);

  useEffect(() => {
    if (viewMore) {
      setDescription(originalDescrip);
    } else {
      const result = `${originalDescrip.substring(0, subStringAt)}...`;
      setDescription(result);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewMore]);

  const markFavorite = () => {
    InfluencerService.markFavorite(project.ad_project_id)
      .then((response) => {
        if (response.status === EResponseStatus.CREATED) {
          setFavorite(response.data.data.is_favorite);
          toast.success(
            response.data.data.is_favorite
              ? t("common.message.saved_to_wishlist")
              : t("common.message.remove_from_wishlist"),
            toastDefaultOptions
          );
        }
      })
      .catch(() => {
        toast.error(
          t("common.message.saved_to_wishlist_error"),
          toastDefaultOptions
        );
      });
  };

  const showAllImages = (event, url) => {
    if (project?.project_media?.length) {
      setIsDrawerOpen(true);
      setImageSelected(url || "");
    }
  };

  return (
    <LayoutMain path={Path.home}>
      <MainContent>
        {Object.keys(project).length > 0 && (
          <div className="top-page max-w-[1280px] mx-auto w-full">
            <div className="flex items-baseline justify-between mb-8 border-secondary-300">
              <SkeletonItem
                isLoading={isLoading}
                variant="rounded"
                width={150}
                height={40}
              >
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-1">
                    <Link
                      to={Path.home}
                      className="w-7 h-7 items-center justify-center flex pt-1"
                    >
                      <i className="fa-solid fa-chevron-left text-lg" />
                    </Link>
                    <p className="flex-1 text-[27px] font-bold word-break mr-2">
                      {getProjectDetailByLanguage(project)?.title}
                    </p>
                  </div>
                </div>
              </SkeletonItem>
              <SkeletonItem
                isLoading={isLoading}
                variant="rounded"
                width={80}
                height={40}
              >
                <div>
                  <button
                    type="button"
                    onClick={markFavorite}
                    className="flex items-center"
                  >
                    <svg
                      viewBox="0 0 32 32"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      role="presentation"
                      focusable="false"
                      className={`block h-5 w-5 stroke-2 ${
                        favorite
                          ? "fill-[#FF385C]"
                          : "fill-[#fff] stroke-[#000]"
                      }`}
                    >
                      <path d="m16 28c7-4.733 14-10 14-17 0-1.792-.683-3.583-2.05-4.95-1.367-1.366-3.158-2.05-4.95-2.05-1.791 0-3.583.684-4.949 2.05l-2.051 2.051-2.05-2.051c-1.367-1.366-3.158-2.05-4.95-2.05-1.791 0-3.583.684-4.949 2.05-1.367 1.367-2.051 3.158-2.051 4.95 0 7 7 12.267 14 17z" />
                    </svg>
                    <p className="ml-1 whitespace-nowrap">
                      {favorite
                        ? t("project.detail.unkeep")
                        : t("project.detail.keep")}
                    </p>
                  </button>
                </div>
              </SkeletonItem>
            </div>

            <div className="list-product mb-2 relative -mx-4 md:mx-0">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-1.5 md:grid-rows-[360px] lg:grid-rows-[400px] xl:grid-rows-[500px]">
                <button
                  onClick={(event) =>
                    showAllImages(
                      event,
                      project.project_media[0]
                        ? project?.project_media[0].thumbnail_url
                        : ""
                    )
                  }
                  type="button"
                  className="w-full md:rounded-l-lg"
                >
                  <SkeletonItem
                    containerClassName="h-full"
                    isLoading={isLoading}
                    variant="rectangular"
                    width="100%"
                    className="!h-[300px] md:!h-full"
                  >
                    {(() => {
                      const isVideoProps = `${
                        project.project_media && project.project_media[0]
                          ? project.project_media[0].thumbnail_url
                          : ""
                      }`
                        ?.toLowerCase()
                        ?.includes(".mp4");
                      return (
                        <Media
                          data={
                            project.project_media && project.project_media[0]
                              ? project.project_media[0].thumbnail_url
                              : ""
                          }
                          containerClassName="!rounded-none"
                          isVideoProps={isVideoProps}
                          className="w-full h-full"
                          videoClass="w-full h-full object-cover cursor-pointer md:rounded-l-lg"
                          imageClassName="w-full h-full object-cover cursor-pointer md:rounded-l-lg"
                        />
                      );
                    })()}
                  </SkeletonItem>
                </button>
                <div className="hidden md:grid grid-cols-2 grid-rows-2 gap-1.5">
                  <button
                    onClick={(event) =>
                      showAllImages(
                        event,
                        project.project_media[1]
                          ? project.project_media[1].thumbnail_url
                          : ""
                      )
                    }
                    type="button"
                    className="w-full"
                  >
                    <SkeletonItem
                      containerClassName="h-full"
                      isLoading={isLoading}
                      variant="rectangular"
                      width="100%"
                      height="100%"
                    >
                      {(() => {
                        const isVideoProps = `${
                          project.project_media && project.project_media[1]
                            ? project.project_media[1].thumbnail_url
                            : ""
                        }`
                          ?.toLowerCase()
                          ?.includes(".mp4");
                        return (
                          <Media
                            data={
                              !isLoading &&
                              project.project_media &&
                              project.project_media[1]
                                ? project.project_media[1].thumbnail_url
                                : ""
                            }
                            containerClassName="!rounded-none"
                            isVideoProps={isVideoProps}
                            className="w-full h-full"
                            videoClass="w-full h-full object-cover cursor-pointer"
                            imageClassName="w-full h-full object-cover cursor-pointer"
                          />
                        );
                      })()}
                    </SkeletonItem>
                  </button>
                  <button
                    onClick={(event) =>
                      showAllImages(
                        event,
                        project.project_media[2]
                          ? project.project_media[2].thumbnail_url
                          : ""
                      )
                    }
                    type="button"
                    className="w-full md:rounded-tr-lg"
                  >
                    <SkeletonItem
                      containerClassName="h-full"
                      isLoading={isLoading}
                      variant="rectangular"
                      width="100%"
                      height="100%"
                    >
                      {(() => {
                        const isVideoProps = `${
                          project.project_media && project.project_media[2]
                            ? project.project_media[2].thumbnail_url
                            : ""
                        }`
                          ?.toLowerCase()
                          ?.includes(".mp4");
                        return (
                          <Media
                            data={
                              !isLoading &&
                              project.project_media &&
                              project.project_media[2]
                                ? project.project_media[2].thumbnail_url
                                : ""
                            }
                            containerClassName="!rounded-none"
                            isVideoProps={isVideoProps}
                            className="w-full h-full"
                            videoClass="w-full h-full object-cover cursor-pointer md:rounded-tr-lg"
                            imageClassName="w-full h-full object-cover cursor-pointer md:rounded-tr-lg"
                          />
                        );
                      })()}
                    </SkeletonItem>
                  </button>
                  <button
                    onClick={(event) =>
                      showAllImages(
                        event,
                        project.project_media[3]
                          ? project.project_media[3].thumbnail_url
                          : ""
                      )
                    }
                    type="button"
                    className="w-full"
                  >
                    <SkeletonItem
                      containerClassName="h-full"
                      isLoading={isLoading}
                      variant="rectangular"
                      width="100%"
                      height="100%"
                    >
                      {(() => {
                        const isVideoProps = `${
                          project.project_media && project.project_media[3]
                            ? project.project_media[3].thumbnail_url
                            : ""
                        }`
                          ?.toLowerCase()
                          ?.includes(".mp4");
                        return (
                          <Media
                            data={
                              !isLoading &&
                              project.project_media &&
                              project.project_media[3]
                                ? project.project_media[3].thumbnail_url
                                : ""
                            }
                            containerClassName="!rounded-none"
                            isVideoProps={isVideoProps}
                            className="w-full h-full"
                            videoClass="w-full h-full object-cover cursor-pointer"
                            imageClassName="w-full h-full object-cover cursor-pointer"
                          />
                        );
                      })()}
                    </SkeletonItem>
                  </button>
                  <button
                    onClick={(event) =>
                      showAllImages(
                        event,
                        project.project_media[4]
                          ? project.project_media[4].thumbnail_url
                          : ""
                      )
                    }
                    type="button"
                    className="w-full md:rounded-br-lg"
                  >
                    <SkeletonItem
                      containerClassName="h-full"
                      isLoading={isLoading}
                      variant="rectangular"
                      width="100%"
                      height="100%"
                    >
                      {(() => {
                        const isVideoProps = `${
                          project.project_media && project.project_media[4]
                            ? project.project_media[4].thumbnail_url
                            : ""
                        }`
                          ?.toLowerCase()
                          ?.includes(".mp4");
                        return (
                          <Media
                            data={
                              !isLoading &&
                              project.project_media &&
                              project.project_media[4]
                                ? project.project_media[4].thumbnail_url
                                : ""
                            }
                            containerClassName="!rounded-none"
                            isVideoProps={isVideoProps}
                            className="w-full h-full"
                            videoClass="w-full h-full object-cover cursor-pointer md:rounded-br-lg"
                            imageClassName="w-full h-full object-cover cursor-pointer md:rounded-br-lg"
                          />
                        );
                      })()}
                    </SkeletonItem>
                  </button>
                </div>
              </div>

              {isLoading ? null : (
                <div className="absolute bottom-3 right-3 md:right-0 lg:right-3">
                  {project?.project_media?.length >= 5 ? (
                    <button
                      type="button"
                      onClick={(event) => showAllImages(event)}
                      className="hidden md:flex items-center justify-between border border-solid border-black py-1 px-2 rounded-[8px] bg-white"
                    >
                      <AppsIcon fontSize="small" className="mr-2" />
                      <p className="font-[700] text-[14px]">
                        {t("project.detail.show_all_image")}
                      </p>
                    </button>
                  ) : null}
                  {project?.project_media?.length > 1 ? (
                    <button
                      type="button"
                      onClick={(event) => showAllImages(event)}
                      className="md:hidden bg-[#00000080] rounded-[4px] py-1 px-3 text-white text-[14px]"
                    >
                      {project.project_media
                        ? `1/${project.project_media.length}`
                        : ""}
                    </button>
                  ) : null}
                </div>
              )}
            </div>

            <div className="flex flex-wrap">
              <div className="block w-full md:w-auto md:flex-1 lg:max-w-[70%] md:mr-4">
                <SkeletonItem
                  isLoading={isLoading}
                  className="!mb-4"
                  variant="rounded"
                  width={200}
                  height={40}
                >
                  <p className="mb-4 word-break whitespace-pre-wrap">
                    {description}
                    {originalDescrip &&
                    originalDescrip.length >= subStringAt ? (
                      <button
                        type="button"
                        className="ml-1 font-[700]"
                        onClick={() => setViewMore(!viewMore)}
                      >
                        {viewMore
                          ? t("project.detail.show_less")
                          : t("project.detail.view_more")}
                      </button>
                    ) : null}
                  </p>
                </SkeletonItem>

                {embedYoutubeList?.length
                  ? embedYoutubeList?.map((item) => {
                      return (
                        <iframe
                          key={item.id}
                          className="mb-4 max-w-full"
                          width="560"
                          height="315"
                          src={item.link}
                          title={item.id}
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          referrerPolicy="strict-origin-when-cross-origin"
                          allowFullScreen
                        />
                      );
                    })
                  : null}

                <SkeletonItem
                  isLoading={isLoading}
                  variant="rounded"
                  width="100%"
                  className="!w-full lg:!w-[400px] !mb-5"
                  height={280}
                >
                  <div className="block mb-5">
                    <div className="font-[700] border-l-2 border-solid border-secondary-200 mb-2">
                      <p className="ml-3">{t("project.detail.what_want_do")}</p>
                    </div>
                    <div className="flex flex-wrap sm:flex-nowrap mb-0.5">
                      <div className="w-full sm:w-auto min-w-[260px] bg-neutral-500 text-white py-2 px-4 sm:mr-3">
                        {t("project.detail.designation_sns")}
                      </div>
                      <div className="flex flex-wrap">
                        {project?.ad_media?.map((item) => {
                          return (
                            <div key={item} className="flex items-center p-2">
                              <div className="relative top-[1px]">
                                {generateSNSIcon(item)}
                              </div>
                              <p className="capitalize">{getMediaName(item)}</p>
                            </div>
                          );
                        })}
                      </div>
                    </div>

                    <div className="flex flex-wrap sm:flex-nowrap mb-0.5">
                      <div className="w-full sm:w-auto min-w-[260px] bg-neutral-500 text-white py-2 px-4 sm:mr-3">
                        {t("project.detail.designation_pr")}
                      </div>
                      <p className="p-2">
                        {t(`common.message.${project.ad_method?.join("")}`)}
                      </p>
                    </div>

                    <div className="flex flex-wrap sm:flex-nowrap mb-0.5">
                      <div className="w-full sm:w-auto min-w-[260px] bg-neutral-500 text-white py-2 px-4 sm:mr-3">
                        {t("project.detail.page_pr")}
                      </div>
                      <p className="p-2 word-break whitespace-pre-wrap">
                        {project.ad_target_url}
                      </p>
                    </div>

                    <div className="flex flex-wrap sm:flex-nowrap mb-0.5">
                      <div className="w-full sm:w-auto min-w-[260px] bg-neutral-500 text-white py-2 px-4 sm:mr-3">
                        {t("project.detail.tag_acc")}
                      </div>
                      <p className="p-2 word-break whitespace-pre-wrap">
                        {project.ad_target_username}
                      </p>
                    </div>

                    <div className="flex flex-wrap sm:flex-nowrap mb-0.5">
                      <div className="w-full sm:w-auto min-w-[260px] bg-neutral-500 text-white py-2 px-4 sm:mr-3">
                        {t("project.detail.what_your_post")}
                      </div>
                      <p className="p-2 word-break whitespace-pre-wrap">
                        {getProjectDetailByLanguage(project)?.text_to_include}
                      </p>
                    </div>

                    <div className="flex flex-wrap sm:flex-nowrap mb-0.5">
                      <div className="w-full sm:w-auto min-w-[260px] bg-neutral-500 text-white py-2 px-4 sm:mr-3">
                        {t("project.detail.tag_must_post")}
                      </div>
                      <p className="p-2 word-break whitespace-pre-wrap">
                        {project.tags_to_include}
                      </p>
                    </div>
                  </div>
                </SkeletonItem>
                <SkeletonItem
                  isLoading={isLoading}
                  variant="rounded"
                  width="100%"
                  className="!w-full lg:!w-[400px]"
                  height={280}
                >
                  <div className="block">
                    <div className="font-[700] border-l-2 border-solid border-secondary-200 mb-2">
                      <p className="ml-3">
                        {t("project.detail.apply_condition")}
                      </p>
                    </div>

                    <div className="flex flex-wrap sm:flex-nowrap mb-0.5">
                      <div className="w-full sm:w-auto min-w-[260px] bg-neutral-500 text-white py-2 px-4 sm:mr-3">
                        {t("project.detail.reward_condition")}
                      </div>
                      <p className="p-2">
                        {getProjectDetailByLanguage(project)?.reward_condition}
                      </p>
                    </div>

                    <div className="flex flex-wrap sm:flex-nowrap mb-0.5">
                      <div className="w-full sm:w-auto min-w-[260px] bg-neutral-500 text-white py-2 px-4 sm:mr-3">
                        {t("project.detail.gender")}
                      </div>
                      <p className="p-2">
                        {t(`common.${project.desired_conditions?.gender}`)}
                      </p>
                    </div>

                    <div className="flex flex-wrap sm:flex-nowrap mb-0.5">
                      <div className="w-full sm:w-auto min-w-[260px] bg-neutral-500 text-white py-2 px-4 sm:mr-3">
                        {t("project.detail.age_group")}
                      </div>
                      <p className="p-2">
                        {(() => {
                          const ageGroup =
                            project?.desired_conditions?.age_group;
                          if (ageGroup.length) {
                            const start = changeGeneration(
                              ageGroup[0],
                              i18n.language
                            );
                            const end = changeGeneration(
                              ageGroup[ageGroup.length - 1],
                              i18n.language
                            );
                            return `${start}～${end}`;
                          }
                          return "";
                        })()}
                      </p>
                    </div>

                    <div className="flex flex-wrap sm:flex-nowrap mb-0.5">
                      <div className="w-full sm:w-auto min-w-[260px] bg-neutral-500 text-white py-2 px-4 sm:mr-3">
                        {t("project.detail.min_followers")}
                      </div>
                      <div className="flex items-baseline p-2">
                        {project.ad_media.map((item) => {
                          return <div>{generateSNSIcon(item)}</div>;
                        })}
                        <p className="word-break">
                          {project.desired_conditions?.min_followers
                            ? formatNumber(
                                project.desired_conditions.min_followers
                              )
                            : ""}
                        </p>
                      </div>
                    </div>
                  </div>
                </SkeletonItem>
              </div>

              <SkeletonItem
                isLoading={isLoading}
                className="!mt-2 lg:!mt-5"
                variant="rounded"
                width={255}
                height={320}
              >
                <div className="mt-2 lg:mt-5 w-[290px]">
                  {project.fee &&
                    project.fee[0] &&
                    project.fee[0].contract_type ===
                      EProjectReward.FIXED_PRICE && (
                      <div className="reward mb-2">
                        <div className="font-[700] border-l-[3px] border-solid border-secondary-200">
                          <p className="ml-3"> {t("project.detail.reward")}</p>
                        </div>
                        <p className="ml-3 word-break">
                          {project.fee && project.fee[0]
                            ? formatNumber(
                                projectFeeDisplay(project.fee[0].fee),
                                true
                              )
                            : ""}
                        </p>
                      </div>
                    )}

                  {project.fee &&
                    project.fee[0] &&
                    project.fee[0].contract_type === EProjectReward.FREE && (
                      <div className="price mb-2">
                        <div className="font-[700] border-l-[3px] border-solid border-secondary-200">
                          <p className="ml-3">
                            {t("project.detail.original_price")}
                          </p>
                        </div>
                        <p className="ml-3 word-break">
                          {project?.price
                            ? formatNumber(
                                projectFeeDisplay(project?.price),
                                true
                              )
                            : t("common.message.no_input_yet")}
                        </p>
                      </div>
                    )}

                  <div className="font-[700] border-l-[3px] border-solid border-secondary-200">
                    <p className="ml-3"> {t("project.detail.post_period")}</p>
                  </div>
                  {project.start_recruitment_at &&
                  project.end_recruitment_at ? (
                    <p className="mb-2 ml-3">
                      {dayjs(project.start_recruitment_at)
                        .tz("Asia/Tokyo")
                        .format(t("common.date_format.yyyy_mm_dd"))}
                      ~
                      {dayjs(project.end_recruitment_at)
                        .tz("Asia/Tokyo")
                        .format(t("common.date_format.yyyy_mm_dd"))}
                    </p>
                  ) : (
                    <p className="mb-2 ml-3">{t("common.other1")}</p>
                  )}

                  <Button
                    onClick={() =>
                      navigate(Path.project_apply(project.ad_project_id))
                    }
                    variant="rounded"
                    disabled={project.is_applied}
                    className="!bg-[#0E5CB5] !text-[#fff] !font-bold !px-3 !py-2 !rounded-md !w-full"
                  >
                    {project.is_applied
                      ? t("project.detail.already_applied")
                      : t("common.action.apply")}
                  </Button>
                </div>
              </SkeletonItem>
            </div>
          </div>
        )}
      </MainContent>
      {/* <ApplyPopup
          projectId={project.ad_project_id}
          openPopup={openPopup}
          setPopupState={(state) => setOpenPopup(state)}
        /> */}
      <ListImageComponent
        lstImage={project.project_media ? project.project_media : []}
        isFavorite={favorite}
        isDrawerOpen={isDrawerOpen}
        setDrawerState={(state) => setIsDrawerOpen(state)}
        imageSelected={imageSelected}
        markFavorite={markFavorite}
      />
      <ErrorDialog
        message={message}
        open={openError}
        onClose={handleClose}
        onOK={handleClose}
      />
    </LayoutMain>
  );
}

export default Detail;
