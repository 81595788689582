export default {
  list: {
    waiting_application_approval: "応募承認待ち",
    waiting_completion_approval: "完了承認待ち",
    completed: "完了済み",
    offer_from_operator: "事業者からの特別オファー",
  },
  detail: {
    keep: "保存",
    unkeep: "保存済み",
    what_want_do: "あなたにやってほしいこと",
    designation_sns: "SNSの指定",
    designation_pr: "PR方法の指定",
    page_pr: "PR指定先ページ",
    tag_acc: "タグ付けアカウント",
    what_your_post: "必ず投稿に含める内容",
    tag_must_post: "必ず投稿に含める指定タグ",
    reward: "報酬",
    original_price: "本来の提供価格",
    post_period: "掲載期間",
    already_applied: "この募集案件に応募済み",
    show_all_image: "すべての写真を表示",
    fixed_price: "固定報酬",
    free_supply: "無償提供",
    apply_condition: "応募条件",
    reward_condition: "報酬条件",
    gender: "性別",
    age_group: "年齢層",
    min_followers: "最低フォロワー数",
    view_more: "もっと見る",
    show_less: "表示を減らす",
  },
  listAll: {
    more_people: "人以上",
    project_list: "募集案件一覧",
  },
  apply: {
    title: "案件への自己PR内容入力",
    pr_url: "PR作品URL",
    pr_work_file: "PR作品ファイル",
    apply_successful:
      "応募完了しました。<br />事業者が確認するまで少々お待ち下さい。",
    apply_fail: "適用に失敗しました。",
    error_upload:
      "ファイルのアップロード中にエラーが発生しました。 もう一度やり直してください。",
    url_placeholder: "投稿SNSのURLを入力下さい。（任意）",
    message_placeholder: "納品物に対する、事業者へのコメントを入力下さい。",
    delivery_success:
      "完了報告をしました。<br />事業者が承認するまでお待ち下さい。",
  },
  chat: {
    waiting_application_approval_mess: "事業者からの応募承認待ちです。",
    waiting_report_approval_mess: "事業者からの完了承認待ちです。",
    reject_report_mess:
      "完了報告は承認されませんでした。指摘された内容を修正して再度完了報告をしてください。",
    decline_mess: "応募は承認されませんでした。",
    cancel_mess: "この取引はキャンセルになりました。",
    start_report_btn: "完了報告をする",
    completed_mess: "完了しました。",
    cancel_transaction: "取引をキャンセル",
    accept_special_offer: "特別オファーを承諾する",
    completed_mess_date: "{{- date}} に{{from}}が完了報告を承認しました。",
    open_project_detail: "募集案件詳細を開く",
  },
};
