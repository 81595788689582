/* eslint-disable no-shadow */
// i-002-004
import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import ProjectItem from "app/components/ProjectItem";
import Pagination from "@mui/material/Pagination";
import { EProjectStatus, EResponseStatus, PER_PAGE } from "app/helper/constant";
import TabWithBatch from "app/components/TabWithBatch";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { Typography } from "@mui/material";
import MainContent from "../../components/common/MainContent";
import LayoutMain from "../../layouts/LayoutMain";
import useCoreComponent from "../../hooks/useCoreComponent";
import InfluencerService from "../../services/InfluencerService";
import AccountInfo from "./AccountInfo";
import {
  socketIO,
  SOCKET_LISTENER,
  SOCKET_NAMESPACE,
  SOCKET_TYPE,
} from "../../context/socket";
import Path from "../../route/Path";

const endpoint = `influencer/ad-project/get-list-applied`;

function Projects() {
  const { t } = useTranslation();
  const [status, setStatus] = useState(
    EProjectStatus.WAIT_APPROVAL_RECRUITMENT
  );
  const [project, setProject] = useState({});
  const [page, setPage] = useState(1);
  const [countMessage, setCountMessage] = useState({});
  const [statusApplied, setStatusApplied] = useState("");
  const [isOpenChat, setIsOpenChat] = useState(false);
  const { response, errorMess, totalPages, isLoading } = useCoreComponent(
    endpoint,
    { page, per_page: PER_PAGE, status: status || null }
  );
  const [showRightNotice, setShowRightNotice] = useState(false);
  const [showLeftNotice, setShowLeftNotice] = useState(false);

  const getListAppliedUnreadMess = useCallback(() => {
    InfluencerService.getListAppliedUnreadMess().then((res) => {
      if (res.status === EResponseStatus.SUCCESS) {
        const { data } = res.data;
        delete data.inProgress;
        delete data.completed;
        setCountMessage(data);
      }
    });
  }, []);

  // set batch for new mess
  const handleNewMessage = useCallback(
    (newMess) => {
      if (!isOpenChat) {
        getListAppliedUnreadMess();
        response.forEach((item) => {
          if (item.application_id === newMess.chat_room_id) {
            item.is_have_unread_msg = true;
          }
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [response, isOpenChat]
  );

  // listen to post mess event socket to trigger notify for new mess
  useEffect(() => {
    const socket = socketIO(SOCKET_TYPE.CHAT, SOCKET_NAMESPACE.CHAT);

    socket.on(SOCKET_LISTENER.POST_MESSAGE, handleNewMessage);
    return () => {
      socket.off(SOCKET_LISTENER.POST_MESSAGE, handleNewMessage);
    };
  }, [handleNewMessage]);

  // get unread mess first render
  useEffect(() => {
    getListAppliedUnreadMess();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTabChange = (event, newValue) => {
    getListAppliedUnreadMess();
    setStatus(newValue);
    setPage(1);
  };

  const handleChangePage = (selectedPage) => {
    setPage(selectedPage);
    window.scrollTo(0, 0);
  };

  const checkUnread = (item) => {
    const countObject = { ...countMessage };
    response.forEach((project) => {
      if (project.ad_project_id === item.ad_project_id) {
        project.is_have_unread_msg = false;
      }
    });
    const allRead = response.every((project) => !project.is_have_unread_msg);
    if (allRead) {
      if (status === EProjectStatus.IN_PROGRESS_AND_REJECT_REPORT) {
        setCountMessage({ ...countObject, inProgressAndRejectReport: false });
      }
      if (status === EProjectStatus.WAIT_APPROVAL_FINISHED) {
        setCountMessage({ ...countObject, waitApprovalFinished: false });
      }
      if (status === EProjectStatus.SPECIAL_OFFER_FROM_OPERATOR) {
        setCountMessage({ ...countObject, specialOfferFromOperator: false });
      }
    }
  };

  const openChat = (item) => {
    setIsOpenChat(true);
    setProject(item);
  };

  useEffect(() => {
    if (project.is_have_unread_msg) {
      if (
        (status.toUpperCase().includes(statusApplied) && statusApplied) ||
        status === EProjectStatus.SPECIAL_OFFER_FROM_OPERATOR
      ) {
        InfluencerService.setReadMsg(project.application_id)
          .then(() => {})
          .finally(() => checkUnread(project));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusApplied]);

  const renderScrollButton = (props) => {
    if (props.direction === "left") {
      return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <button type="button" {...props}>
          <div className="flex items-center relative h-full">
            <KeyboardArrowLeftIcon />
            {showLeftNotice && (
              <Typography
                component="div"
                className="!text-[10px] flex items-center justify-center text-white absolute right-0 top-0 bg-[#8F0B6A] rounded-full w-[20px] h-[20px]"
              >
                <i className="fa-solid fa-exclamation" />
              </Typography>
            )}
          </div>
        </button>
      );
    }
    if (props.direction === "right") {
      return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <button type="button" {...props}>
          <div className="flex items-center relative h-full">
            <KeyboardArrowRightIcon />
            {showRightNotice && (
              <Typography
                component="div"
                className="!text-[10px] flex items-center justify-center text-white absolute right-0 top-0 bg-[#8F0B6A] rounded-full w-[20px] h-[20px]"
              >
                <i className="fa-solid fa-exclamation" />
              </Typography>
            )}
          </div>
        </button>
      );
    }
    return null;
  };

  return (
    <LayoutMain>
      <MainContent>
        <div className="top-page flex flex-col h-full w-full">
          <div className="flex items-center justify-between mb-5 pb-3 border-b border-solid border-secondary-300">
            <Link
              to={Path.home}
              className="w-7 h-7 flex items-center justify-center pt-1"
            >
              <i className="fa-solid fa-chevron-left" />
            </Link>
            <p className="flex-1 text-[27px] font-bold">
              {t("header.project_applied")}
            </p>
          </div>

          <Box
            sx={{
              width: "100%",
              borderBottom: 1,
              borderColor: "divider",
              marginBottom: 2,
            }}
          >
            <Tabs
              className="custom-tab"
              value={status}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
              allowScrollButtonsMobile
              ScrollButtonComponent={(props) => renderScrollButton(props)}
            >
              <Tab
                value={EProjectStatus.WAIT_APPROVAL_RECRUITMENT}
                label={
                  <TabWithBatch>
                    {t("project.list.waiting_application_approval")}
                  </TabWithBatch>
                }
              />
              <Tab
                value={EProjectStatus.IN_PROGRESS_AND_REJECT_REPORT}
                label={
                  <TabWithBatch
                    setShowLeftNotice={setShowLeftNotice}
                    setShowRightNotice={setShowRightNotice}
                    countMessage={countMessage}
                    dataValue="inProgressAndRejectReport"
                    isUnread={countMessage.inProgressAndRejectReport}
                  >
                    {t("common.message.in_process")}
                  </TabWithBatch>
                }
              />
              <Tab
                value={EProjectStatus.WAIT_APPROVAL_FINISHED}
                label={
                  <TabWithBatch
                    setShowLeftNotice={setShowLeftNotice}
                    setShowRightNotice={setShowRightNotice}
                    dataValue="waitApprovalFinished"
                    isUnread={countMessage.waitApprovalFinished}
                  >
                    {t("project.list.waiting_completion_approval")}
                  </TabWithBatch>
                }
              />
              <Tab
                value={EProjectStatus.COMPLETED}
                label={
                  <TabWithBatch>{t("project.list.completed")}</TabWithBatch>
                }
              />
              <Tab
                value={EProjectStatus.SPECIAL_OFFER_FROM_OPERATOR}
                label={
                  <TabWithBatch
                    setShowLeftNotice={setShowLeftNotice}
                    setShowRightNotice={setShowRightNotice}
                    isUnread={countMessage.specialOfferFromOperator}
                    dataValue="specialOfferFromOperator"
                  >
                    {t("project.list.offer_from_operator")}
                  </TabWithBatch>
                }
              />
              <Tab
                value={EProjectStatus.ALL}
                label={<TabWithBatch>{t("common.message.all")}</TabWithBatch>}
              />
            </Tabs>
          </Box>

          {/* List Project */}
          {(() => {
            if (!isLoading) {
              if (errorMess) {
                return (
                  <p className="text-[20px] font-[600] text-center">
                    {errorMess}
                  </p>
                );
              }
              if (!response.length) {
                return (
                  <p className="text-[20px] font-[600] text-center">
                    {t("common.message.no_data_display")}
                  </p>
                );
              }
              return (
                <div className="list-project flex flex-col flex-1">
                  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-6">
                    {response.map((project) => {
                      return (
                        <div key={project.ad_project_id} className="relative">
                          {(() => {
                            if (
                              status ===
                                EProjectStatus.IN_PROGRESS_AND_REJECT_REPORT ||
                              status === EProjectStatus.WAIT_APPROVAL_FINISHED
                            ) {
                              if (project.is_have_unread_msg) {
                                return (
                                  <div className="!text-[10px] flex items-center justify-center text-white absolute -right-2 -top-2 z-[1000] bg-[#8F0B6A] rounded-full w-[20px] h-[20px]">
                                    <i className="fa-solid fa-exclamation" />
                                  </div>
                                );
                              }
                            }
                            return null;
                          })()}
                          <ProjectItem
                            project={project}
                            isLoading={isLoading}
                            openChat={() => openChat(project)}
                          />
                        </div>
                      );
                    })}
                  </div>
                  <div className="flex justify-center pt-10 flex-1 items-end">
                    <Pagination
                      page={page}
                      count={totalPages}
                      onChange={(event, selectedPage) =>
                        handleChangePage(selectedPage)
                      }
                      showFirstButton
                      showLastButton
                    />
                  </div>
                </div>
              );
            }
            return null;
          })()}
        </div>
        <SwipeableDrawer
          anchor="right"
          open={isOpenChat}
          onOpen={() => setIsOpenChat(true)}
          onClose={() => {
            setIsOpenChat(false);
            setStatusApplied("");
          }}
          classes={{
            paper: "!w-[960px] !max-w-full",
          }}
        >
          <AccountInfo
            setStatusApplied={(value) => setStatusApplied(value)}
            data={project}
            onCloseDrawer={() => {
              setIsOpenChat(false);
              setStatusApplied("");
            }}
          />
        </SwipeableDrawer>
      </MainContent>
    </LayoutMain>
  );
}

export default Projects;
