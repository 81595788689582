import { Skeleton } from "@mui/material";
import React from "react";

function SkeletonItem({
  containerClassName,
  className,
  width,
  height,
  variant,
  isLoading,
  children,
}) {
  return (
    <div className={containerClassName}>
      {isLoading ? (
        <Skeleton
          className={className}
          animation="wave"
          variant={variant}
          width={width}
          height={height}
        />
      ) : (
        children
      )}
    </div>
  );
}

export default SkeletonItem;
