import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Path from "../../route/Path";
import Home from "../../assets/images/home.jpeg";

function MobileNav({ className, resetFilter }) {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleNavigateHome = () => {
    if (location.pathname === Path.home) {
      resetFilter();
    } else {
      navigate(Path.home);
    }
  };

  return (
    <div
      style={{ boxShadow: "rgba(0, 0, 0, 0.08) 0 -1px 0px" }}
      className={`sticky bottom-0 left-0 right-0 z-[1031] bg-white px-2 ${className}`}
    >
      <div className="flex items-center justify-between h-[70px]">
        <button
          type="button"
          className="flex flex-col items-center flex-1 mx-1"
          onClick={handleNavigateHome}
        >
          <img
            src={Home}
            alt="Girl in a jacket"
            className={`block w-6 h-6 overflow-visible fill-[none] ${
              Path.home === location.pathname ? "opacity-[1]" : "opacity-[0.5]"
            }`}
          />
          <p
            className={`pt-0.5 text-[10px] font-[700] ${
              Path.home === location.pathname ? "opacity-[1]" : "opacity-[0.5]"
            }`}
          >
            HOME
          </p>
        </button>
        <Link
          to={Path.favorite}
          className="flex flex-col items-center flex-1 mx-1"
        >
          <svg
            strokeWidth={3}
            viewBox="0 0 32 32"
            xmlns="http://www.w3.org/2000/svg"
            role="presentation"
            focusable="false"
            className={`block w-6 h-6 overflow-visible fill-[none] ${
              Path.favorite === location.pathname
                ? "stroke-[#4B4B4B]"
                : "stroke-[#B0B0B0]"
            }`}
          >
            <path d="m16 28c7-4.733 14-10 14-17 0-1.792-.683-3.583-2.05-4.95-1.367-1.366-3.158-2.05-4.95-2.05-1.791 0-3.583.684-4.949 2.05l-2.051 2.051-2.05-2.051c-1.367-1.366-3.158-2.05-4.95-2.05-1.791 0-3.583.684-4.949 2.05-1.367 1.367-2.051 3.158-2.051 4.95 0 7 7 12.267 14 17z" />
          </svg>
          <p
            className={`pt-0.5 text-[10px] font-[700] ${
              Path.favorite === location.pathname
                ? "opacity-[1]"
                : "opacity-[0.5]"
            }`}
          >
            {t("header.favorite1")}
          </p>
        </Link>
        <Link
          to={Path.project_applied}
          className="flex flex-col items-center flex-1 mx-1"
        >
          <svg
            viewBox="0 0 32 32"
            xmlns="http://www.w3.org/2000/svg"
            role="presentation"
            focusable="false"
            className={`block w-6 h-6 overflow-visible ${
              Path.project_applied === location.pathname
                ? "fill-[#4B4B4B]"
                : "fill-[#B0B0B0]"
            }`}
          >
            <path d="m26 2c2.6887547 0 4.8818181 2.12230671 4.9953805 4.78311038l.0046195.21688962v14c0 2.6887547-2.1223067 4.8818181-4.7831104 4.9953805l-.2168896.0046195h-5.845l-4.1548949 5.0792674-4.1561051-5.0792674h-5.844c-2.61406707 0-4.75959878-2.0060384-4.98113724-4.5626572l-.01424325-.2204532-.00461951-.2168896v-14c0-2.6887547 2.12230671-4.88181811 4.78311038-4.99538049l.21688962-.00461951zm0 2h-20c-1.59768088 0-2.90366088 1.24891996-2.99490731 2.82372721l-.00509269.17627279v14c0 1.5976809 1.24891996 2.9036609 2.82372721 2.9949073l.17627279.0050927h6.7918643l3.2071357 3.92 3.2080542-3.92h6.7929458c1.5976809 0 2.9036609-1.24892 2.9949073-2.8237272l.0050927-.1762728v-14c0-1.59768088-1.24892-2.90366088-2.8237272-2.99490731z" />
          </svg>
          <p
            className={`pt-0.5 text-[10px] font-[700] ${
              Path.project_applied === location.pathname
                ? "opacity-[1]"
                : "opacity-[0.5]"
            }`}
          >
            {t("header.project_applied")}
          </p>
        </Link>

        <Link
          to={Path.account_settings}
          className="flex flex-col items-center flex-1 mx-1"
        >
          <svg
            viewBox="0 0 32 32"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            role="presentation"
            focusable="false"
            className={`block w-6 h-6 overflow-visible ${
              Path.account_settings === location.pathname
                ? "fill-[#4B4B4B]"
                : "fill-[#B0B0B0]"
            }`}
          >
            <path d="m16 1c8.2842712 0 15 6.71572875 15 15 0 8.2842712-6.7157288 15-15 15-8.28427125 0-15-6.7157288-15-15 0-8.28427125 6.71572875-15 15-15zm0 8c-2.7614237 0-5 2.2385763-5 5 0 2.0143973 1.2022141 3.7998876 2.9996346 4.5835001l.0003231 2.0984999-.1499943.0278452c-2.8326474.5613112-5.31897338 2.2230336-6.93575953 4.5872979 2.34343054 2.291067 5.54974273 3.7028569 9.08579613 3.7028569 3.5355506 0 6.7414538-1.4113884 9.0850203-3.701476-1.6141801-2.3628535-4.0978119-4.0247647-6.929184-4.5867938l-.1558786-.0287302.001228-2.0991413c1.7288399-.7547474 2.9066959-2.4357565 2.9936498-4.355479l.0051645-.2283797c0-2.7614237-2.2385763-5-5-5zm0-6c-7.17970175 0-13 5.82029825-13 13 0 2.9045768.95257276 5.5866683 2.56235849 7.7509147 1.42074739-1.9134907 3.33951478-3.4002416 5.53860831-4.2955956l.3480332-.1363191-.0229565-.0189706c-1.43704227-1.2411241-2.34462949-3.045583-2.42083359-5.0285539l-.00520991-.2714755c0-3.8659932 3.1340068-7 7-7s7 3.1340068 7 7c0 1.9941317-.8415062 3.8279876-2.224566 5.1193683l-.225434.2006317.0447787.0163138c2.3268368.8792152 4.3570558 2.4138611 5.8430586 4.4127726 1.6098837-2.1632453 2.5621627-4.8449575 2.5621627-7.7490864 0-7.17970175-5.8202983-13-13-13z" />
          </svg>
          <p
            className={`pt-0.5 text-[10px] font-[700] ${
              Path.account_settings === location.pathname
                ? "opacity-[1]"
                : "opacity-[0.5]"
            }`}
          >
            {t("header.account_settings")}
          </p>
        </Link>
      </div>
    </div>
  );
}

export default MobileNav;
