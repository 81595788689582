/* eslint-disable react/no-array-index-key */
import React from "react";
import Drawer from "@mui/material/Drawer";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Keyboard } from "swiper";
import { useTranslation } from "react-i18next";
import Media from "../../components/Media";

function ImageSlider({
  selectedIndex,
  lstImage,
  isSliderOpen,
  setSliderState,
  isFavorite,
  markFavorite,
}) {
  const { t } = useTranslation();
  
  return (
    <Drawer
      anchor="bottom"
      open={isSliderOpen}
      onClose={() => setSliderState(false)}
      className="!z-[1031]"
      classes={{
        paper: "!top-0 !bg-black !text-white",
      }}
      transitionDuration={600}
    >
      <header className="px-4 top-0 left-0 right-0 z-[1031] pt-5 md:px-8">
        <div className="flex items-center justify-center md:justify-between relative h-16">
          <button
            type="button"
            onClick={() => setSliderState(false)}
            className="w-7 h-7 md:w-auto md:h-auto flex items-center justify-center absolute md:relative left-0 hover:bg-secondary-200 px-3 py-1 rounded-full md:rounded-[8px]"
          >
            <div className="hidden md:flex items-center">
              <div className="flex items-center justify-center mr-2">
                <i className="text-[18px] fa-solid fa-xmark" />
              </div>
              <div className="text-[14px] font-[700]">
                {t("common.action.close")}
              </div>
            </div>
            <i className="md:!hidden fa-solid fa-chevron-left" />
          </button>

          <div className="custom-fraction !leading-[32px] !w-auto absolute !bottom-0 !top-[50%] !translate-y-[-50%] !left-[50%] !translate-x-[-50%]" />

          <button
            type="button"
            onClick={markFavorite}
            className="hidden md:flex items-center"
          >
            <svg
              viewBox="0 0 32 32"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              role="presentation"
              focusable="false"
              className={`block h-5 w-5 stroke-2 ${
                isFavorite
                  ? "fill-[#f00] stroke-[none]"
                  : "fill-[#000] stroke-[#fff]"
              }`}
            >
              <path d="m16 28c7-4.733 14-10 14-17 0-1.792-.683-3.583-2.05-4.95-1.367-1.366-3.158-2.05-4.95-2.05-1.791 0-3.583.684-4.949 2.05l-2.051 2.051-2.05-2.051c-1.367-1.366-3.158-2.05-4.95-2.05-1.791 0-3.583.684-4.949 2.05-1.367 1.367-2.051 3.158-2.051 4.95 0 7 7 12.267 14 17z" />
            </svg>
          </button>
        </div>
      </header>

      <main className="flex-1 md:container px-0 mb-[60px]">
        <div className="flex items-center justify-between h-full">
          <div className="w-12 h-12 flex-shrink-0 hidden md:block rounded-full ml-5">
            <button
              type="button"
              className="w-full h-full swiper-button-prev-custom hidden md:flex items-center justify-center border-2 border-solid border-[#B0B0B0] rounded-full p-1 hover:bg-secondary-200"
            >
              <i className="text-[12px] fa-solid fa-chevron-left" />
            </button>
          </div>

          <div className="w-full md:w-[75%]">
            <Swiper
              keyboard={{
                enabled: true,
              }}
              navigation={{
                prevEl: ".swiper-button-prev-custom",
                nextEl: ".swiper-button-next-custom",
              }}
              pagination={{
                type: "fraction",
                el: ".custom-fraction",
                renderCustom(swiper, current, total) {
                  return `${current}/${total - 1}`;
                },
              }}
              initialSlide={selectedIndex || 0}
              modules={[Keyboard, Pagination, Navigation]}
              className="mySwiper h-full"
              slidesPerView={1}
            >
              {lstImage.map((photo, index) => {
                return (
                  <SwiperSlide
                    key={index}
                    className="!h-auto flex items-center justify-center"
                  >
                    <div className="w-full xl:w-[70%] mx-auto flex justify-center h-full max-h-[800px]">
                      {(() => {
                        const isVideoProps = `${photo.thumbnail_url}`
                          ?.toLowerCase()
                          ?.includes(".mp4");
                        return (
                          <Media
                            lazy="true"
                            controls
                            data={photo.thumbnail_url}
                            // containerClassName="!rounded-none"
                            isVideoProps={isVideoProps}
                            className="w-full h-full"
                            videoClass="w-full image-item h-full object-contain"
                            imageClassName="w-full image-item h-full object-contain"
                          />
                        );
                      })()}
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>

          <div className="w-12 h-12 flex-shrink-0 hidden md:block rounded-full mr-5">
            <button
              type="button"
              className="w-full h-full swiper-button-next-custom hidden md:flex items-center justify-center border-2 border-solid border-[#B0B0B0] rounded-full p-1 hover:bg-secondary-200"
            >
              <i className="text-[12px] fa-solid fa-chevron-right" />
            </button>
          </div>
        </div>
      </main>
    </Drawer>
  );
}

export default ImageSlider;
