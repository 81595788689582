import React from "react";
import Radio from "@mui/material/Radio";
import { Button } from "@mui/material";
import { Field, ErrorMessage } from "formik";
import { useTranslation } from "react-i18next";

const listReason = [
  {
    id: 1,
    value: "accountSetting.widthdraw.step_1_first_reason",
    label: "accountSetting.widthdraw.step_1_first_reason",
    showInput: false,
  },
  {
    id: 2,
    value: "accountSetting.widthdraw.step_1_second_reason",
    label: "accountSetting.widthdraw.step_1_second_reason",
    showInput: false,
  },
  {
    id: 3,
    value: "accountSetting.widthdraw.step_1_third_reason",
    label: "accountSetting.widthdraw.step_1_third_reason",
    showInput: false,
  },
  {
    id: 4,
    value: "accountSetting.widthdraw.step_1_fourth_reason",
    label: "accountSetting.widthdraw.step_1_fourth_reason",
    showInput: true,
  },
];

function Step1({ setFieldValue, values, setStep }) {
  const { t } = useTranslation();
  return (
    <>
      <h1 className="text-[32px] leading-9 text-REGULAR_TEXT_COLOR font-extrabold mb-10">
        {t("accountSetting.widthdraw.step_1_title")}
      </h1>

      <div className="mb-10">
        {listReason.map((item) => {
          return (
            <div key={item.id} className="py-4 border-b border-border">
              <div className="flex justify-between items-center ">
                <label htmlFor={String(item.id)}>{t(item.label)}</label>
                <Radio
                  name="selected"
                  id={String(item.id)}
                  checked={values.selected === item.label}
                  onChange={() => {
                    setFieldValue("selected", item.label);
                    if (
                      item.value !==
                      "accountSetting.widthdraw.step_1_fourth_reason"
                    ) {
                      setFieldValue("reason", t(item.value));
                    } else {
                      setFieldValue("reason", "");
                    }
                  }}
                  value={item.value}
                />
              </div>
              {values.selected ===
                "accountSetting.widthdraw.step_1_fourth_reason" &&
                item.showInput && (
                  <>
                    <Field
                      as="textarea"
                      name="reason"
                      className="max-w-full w-[400px] h-[200px] border border-solid border-secondary-200 resize-none px-3 py-2 rounded-[4px]"
                      maxLength={1000}
                    />
                    <ErrorMessage
                      component="div"
                      name="reason"
                      className="error-msg mt-1"
                    />
                  </>
                )}
            </div>
          );
        })}
      </div>

      <div className="w-full flex justify-end">
        <Button
          disabled={!values.reason}
          variant="contained"
          className="!px-6 !py-2.5 !rounded-md !bg-[#008489] !text-base !font-extrabold !text-white"
          onClick={() => setStep(2)}
        >
          {t("common.btn.continue")}
        </Button>
      </div>
    </>
  );
}

export default Step1;
