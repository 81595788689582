/* eslint-disable react/no-array-index-key */
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Path from "../../route/Path";

function PcContent() {
  const { t } = useTranslation();
  const profile = useSelector((state) => state.profile) || {};
  const { nickname } = profile;
  const email = profile?.email_address?.main;

  const infoDisplay = [nickname, email].filter((item) => item);

  return (
    <div className="h-full">
      <div className="w-full md:w-[696px] xl:w-[1080px] mx-auto max-w-full">
        <div className="w-full h-auto">
          <div className="w-full pt-6 pb-14">
            <div className="flex items-center">
              <Link
                to={Path.home}
                className="w-7 h-7 flex items-center justify-center pt-1"
              >
                <i className="fa-solid fa-chevron-left" />
              </Link>
              <h1 className="text-[32px] font-semibold leading-9 text-REGULAR_TEXT_COLOR">
                {t("header.account_settings")}
              </h1>
            </div>
            <div className="flex items-center flex-wrap w-full text-lg leading-6 text-REGULAR_TEXT_COLOR pt-2">
              {infoDisplay.map((item, index) => {
                return (
                  <span
                    key={index}
                    className={`${
                      item === nickname ? "font-semibold" : "font-normal"
                    } mr-1`}
                  >{`${item},`}</span>
                );
              })}
              <Link
                to={Path.profile}
                className="font-semibold underline cursor-pointer inline-block"
              >
                {t("accountSetting.personal_info.go_to_profile")}
              </Link>
            </div>
          </div>

          <div className="w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-x-4 gap-y-4 pb-2">
            <Link to={Path.account_settings_personal_info}>
              <div
                className=" w-full h-[156px] p-4 rounded-xl"
                style={{ boxShadow: "rgb(0 0 0 / 12%) 0px 6px 16px" }}
              >
                <div className="flex flex-col h-full justify-between">
                  <div>
                    <svg
                      fill="#222222"
                      display="block"
                      viewBox="0 0 32 32"
                      style={{ height: 32, width: 32 }}
                    >
                      <path d="M29 5a2 2 0 011.995 1.85L31 7v18a2 2 0 01-1.85 1.995L29 27H3a2 2 0 01-1.995-1.85L1 25V7a2 2 0 011.85-1.995L3 5zm0 2H3v18h26zm-3 12v2h-8v-2zm-16-8a3 3 0 012.495 4.667A4.995 4.995 0 0115 20h-2a3.001 3.001 0 00-2-2.829V14a1 1 0 00-1.993-.117L9 14v3.17A3.001 3.001 0 007 20H5a4.998 4.998 0 012.506-4.334A3 3 0 0110 11zm16 4v2h-8v-2zm0-4v2h-8v-2z" />
                    </svg>
                  </div>

                  <div>
                    <div className="text-base left-5 text-REGULAR_TEXT_COLOR font-semibold mb-2">
                      {t("accountSetting.personal_info.personal_info")}
                    </div>

                    <div className="text-sm leading-[18px] text-danger-DESCRIPTION font-normal">
                      {t("accountSetting.personal_info.personal_info_notice")}
                    </div>
                  </div>
                </div>
              </div>
            </Link>

            <Link to={Path.account_settings_login_security}>
              <div
                className=" w-full h-[156px] p-4 rounded-xl"
                style={{ boxShadow: "rgb(0 0 0 / 12%) 0px 6px 16px" }}
              >
                <div className="flex flex-col h-full justify-between">
                  <div>
                    <svg
                      fill="#222222"
                      display="block"
                      viewBox="0 0 32 32"
                      style={{ height: 32, width: 32 }}
                    >
                      <path d="M16 .798l.555.37C20.398 3.73 24.208 5 28 5h1v12.5C29 25.574 23.21 31 16 31S3 25.574 3 17.5V5h1c3.792 0 7.602-1.27 11.445-3.832L16 .798zm-1 3.005c-3.2 1.866-6.418 2.92-9.648 3.149L5 6.972V17.5c0 6.558 4.347 10.991 10 11.459zm2 0V28.96c5.654-.468 10-4.901 10-11.459V6.972l-.352-.02c-3.23-.23-6.448-1.282-9.647-3.148z" />
                    </svg>
                  </div>

                  <div>
                    <div className="text-base left-5 text-REGULAR_TEXT_COLOR font-semibold mb-2">
                      {t("accountSetting.personal_info.login_security")}
                    </div>

                    <div className="text-sm leading-[18px] text-danger-DESCRIPTION font-normal">
                      {t("accountSetting.personal_info.login_security_notice")}
                    </div>
                  </div>
                </div>
              </div>
            </Link>

            <button type="button" disabled className="text-left">
              <div
                className=" w-full h-[156px] p-4 rounded-xl bg-gray-100"
                style={{ boxShadow: "rgb(0 0 0 / 12%) 0px 6px 16px" }}
              >
                <div className="flex flex-col h-full justify-between">
                  <div>
                    <svg
                      fill="#222222"
                      display="block"
                      viewBox="0 0 32 32"
                      style={{ height: 32, width: 32 }}
                    >
                      <path d="M24 31a7 7 0 000-14H8a7 7 0 000 14zm5-7a5 5 0 11-5-5 5.006 5.006 0 015 5zM3 24a5.006 5.006 0 015-5h11.11a6.978 6.978 0 000 10H8a5.006 5.006 0 01-5-5zM24 1H8a7 7 0 000 14h16a7 7 0 000-14zM3 8a5 5 0 115 5 5.006 5.006 0 01-5-5zm21 5H12.89a6.978 6.978 0 000-10H24a5 5 0 010 10z" />
                    </svg>
                  </div>

                  <div>
                    <div className="text-base left-5 text-REGULAR_TEXT_COLOR font-semibold mb-2">
                      {t("accountSetting.personal_info.global_setting")}
                    </div>

                    <div className="text-sm leading-[18px] text-danger-DESCRIPTION font-normal">
                      {t("accountSetting.personal_info.global_setting_notice")}
                    </div>
                  </div>
                </div>
              </div>
            </button>
          </div>

          <div className="w-full text-center my-12">
            <Link
              to={Path.withdraw}
              className="text-center text-sm leading-5 text-REGULAR_TEXT_COLOR font-semibold underline"
            >
              {t("accountSetting.personal_info.withdraw")}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PcContent;
