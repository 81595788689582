/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Drawer from "@mui/material/Drawer";
import { useTranslation } from "react-i18next";
import ImageSlider from "./ImageSlider";
import Media from '../../components/Media';

function ListImageComponent({
  imageSelected,
  lstImage,
  isDrawerOpen,
  setDrawerState,
  isFavorite,
  markFavorite,
}) {
  const [state, setState] = useState(false);
  const [isSliderOpen, setIsSliderOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    setState(isDrawerOpen);
    const element = document.querySelector(
      `.image-item[src^="${imageSelected}"]`
    );
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [imageSelected, state, setDrawerState]);

  const handleClass = (index) => {
    if (Math.ceil((index + 1) / 3) % 2 !== 1) {
      if (index % 2 === 0) {
        return "col-span-2 row-span-2 pb-2";
      }
      return "col-span-2 row-span-1 pb-2";
    }
    if (index % 2 === 0) {
      return "col-span-2 row-span-1 pb-2";
    }
    return "col-span-2 row-span-2 pb-2";
  };

  const getOrderValue = (index) => {
    if (Math.ceil((index + 1) / 3) % 2 !== 1) {
      if (index % 2 === 0) {
        return index - 1;
      }
      return index + 1;
    }
    if (index % 2 === 0) {
      return index + 1;
    }
    return index + 1;
  };

  return (
    <Drawer
      anchor="bottom"
      open={isDrawerOpen}
      onClose={() => setDrawerState(false)}
      className="!z-[1031]"
      classes={{
        paper: "!top-0 !bg-white",
      }}
      transitionDuration={600}
    >
      <header className="px-4">
        <div className="flex items-center justify-between relative h-16">
          <button
            type="button"
            onClick={() => setDrawerState(false)}
            className="w-7 h-7 flex items-center justify-center"
          >
            <i className="fa-solid fa-chevron-left" />
          </button>

          <button
            type="button"
            onClick={markFavorite}
            className="flex items-center"
          >
            <svg
              viewBox="0 0 32 32"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              role="presentation"
              focusable="false"
              className={`block h-5 w-5 stroke-2 stroke-[#000] ${
                isFavorite ? "fill-[#f00]" : "fill-[#fff]"
              }`}
            >
              <path d="m16 28c7-4.733 14-10 14-17 0-1.792-.683-3.583-2.05-4.95-1.367-1.366-3.158-2.05-4.95-2.05-1.791 0-3.583.684-4.949 2.05l-2.051 2.051-2.05-2.051c-1.367-1.366-3.158-2.05-4.95-2.05-1.791 0-3.583.684-4.949 2.05-1.367 1.367-2.051 3.158-2.051 4.95 0 7 7 12.267 14 17z" />
            </svg>
            <p className="ml-1 whitespace-nowrap">
              {isFavorite
                ? t("project.detail.unkeep")
                : t("common.action.save")}
            </p>
          </button>
        </div>
      </header>
      <main className="flex-1 overflow-auto">
        <div className="max-w-[800px] mx-auto py-4">
          <div className="grid custom grid-cols-4 gap-x-2 -mb-2">
            {lstImage.map((item, index) => {
              return (
                <button
                  type="button"
                  onClick={() => {
                    setIsSliderOpen(true);
                    setSelectedIndex(index);
                  }}
                  key={item.thumbnail_url}
                  style={{ order: getOrderValue(index) }}
                  className={`cursor-pointer item ${handleClass(index)}`}
                >
                  {(() => {
                    const isVideoProps = `${item.thumbnail_url}`
                      ?.toLowerCase()
                      ?.includes(".mp4");
                    return (
                      <Media
                        data={item.thumbnail_url}
                        containerClassName="!rounded-none"
                        isVideoProps={isVideoProps}
                        className="w-full h-full"
                        videoClass="w-full image-item h-full object-cover"
                        imageClassName="w-full image-item h-full object-cover"
                      />
                    );
                  })()}
                </button>
              );
            })}
          </div>
        </div>
      </main>
      <ImageSlider
        lstImage={lstImage}
        selectedIndex={selectedIndex}
        isFavorite={isFavorite}
        isSliderOpen={isSliderOpen}
        setSliderState={(value) => setIsSliderOpen(value)}
        markFavorite={markFavorite}
      />
    </Drawer>
  );
}

export default ListImageComponent;
