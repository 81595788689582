/* eslint-disable jsx-a11y/label-has-associated-control */
// i-001-003
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import LayoutMain from "app/layouts/LayoutMain";
import {
  EResponseStatus,
  GENDER,
  emailRegex,
  onlyNumberRegex,
} from "app/helper/constant";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import UserService from "app/services/UserService";
import { useDispatch, useSelector } from "react-redux";
import { setActive } from "app/redux/blockUIReducer";
import {
  BootstrapInput,
  generateSNSIcon,
  logout,
  ScrollToFieldError,
} from "app/utils";
import ChangeLanguageButton from "app/components/ChangeLanguageButton";
import Path from "../../route/Path";
import SuccessDialog from "../../components/common/SuccessDialog";
import ErrorDialog from "../../components/common/ErrorDialog";
import { setRouteEmpty } from "../../redux/previousRouteReducer";
import SplitBackground from "../../components/SplitBackground";
import { setUpdateStateDefault } from "../../redux/updateInfoReducer";
import { setProfileStore } from "../../redux/profileReducer";
import { setEmail } from "../../redux/emailReducer";

function Register() {
  const previousRoute = useSelector((state) => state.previousRoute);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [cannotUpdate, setCannotUpdate] = useState(false);
  const email = useSelector((state) => state.email);
  const [message, setMessage] = useState("");
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t("validation_field.name_required_message")).trim().max(30),
    gender: Yup.string().required(t("validation_field.gender_required_message")),
    instagram_account: Yup.string().max(50),
    tiktok_account: Yup.string(),
    // twitter_account: Yup.string().max(50),
    face_account: Yup.string(),
    youtube_channel_id: Yup.string(),
    email: Yup.string().required().trim().matches(emailRegex).max(64),
    self_introduction: Yup.string(),
  });
  const initialValues = {
    name: "",
    gender: "",
    instagram_account: "",
    instagram_followers: "",
    tiktok_account: "",
    tiktok_followers: "",
    // twitter_account: "",
    face_account: "",
    face_followers: "",
    youtube_channel_id: "",
    email: email || "",
    self_introduction: "",
  };

  const openSuccessDiaglog = (mess) => {
    setOpenSuccess(true);
    setMessage(mess);
  };

  const openErrorDiaglog = (mess) => {
    setOpenError(true);
    setMessage(mess);
  };

  const onSuccess = () => {
    navigate(previousRoute || Path.home);
    dispatch(setRouteEmpty());
    dispatch(setUpdateStateDefault());
    dispatch(setEmail(null));
    UserService.getProfile().then((res) => {
      if (res.status === EResponseStatus.SUCCESS) {
        dispatch(setProfileStore(res.data.data));
      }
    });
  };

  const onError = () => {
    if (cannotUpdate) {
      navigate(Path.login);
    } else {
      setOpenError(false);
    }
  };

  const register = (values) => {
    dispatch(setActive(true));
    const data = {
      nickname: values.name,
      gender: values.gender,
      address: {
        country: "",
        city: "",
        street: "",
        apartment: "",
        address: "",
        postcode: "",
      },
      paypal: {
        account: "",
      },
      instagram: values.instagram_account,
      instagram_followers: values.instagram_followers
        ? Number(values.instagram_followers)
        : 0,
      tiktok: values.tiktok_account,
      tiktok_followers: values.tiktok_followers
        ? Number(values.tiktok_followers)
        : 0,
      // twitter: values.twitter_account,
      facebook: values.face_account,
      facebook_followers: values.face_followers
        ? Number(values.face_followers)
        : 0,
      youtube: values.youtube_channel_id,
      email_address: {
        main: values.email,
      },
      self_introduction: values.self_introduction,
    };
    UserService.updateInfluencerInfo(data)
      .then((response) => {
        if (response.status === EResponseStatus.SUCCESS) {
          openSuccessDiaglog(t("common.message.create_success"));
        }
      })
      .catch((error) => {
        if (error.response.status === EResponseStatus.BAD_REQUEST) {
          let field = "";
          let capitalizeField = "";
          let errorMess = "";
          const errorResponse =
            error.response.data.message || error.response.data.messages[0];
          const splitLine = errorResponse.indexOf("|");
          if (splitLine === -1) {
            errorMess = errorResponse;
            if (error.response.data.message === "CAN_NOT_UPDATED") {
              setCannotUpdate(true);
            }
          } else {
            field = errorResponse.substring(0, splitLine);
            capitalizeField = field.charAt(0).toUpperCase() + field.slice(1);
            errorMess = errorResponse.substring(splitLine + 1);
          }
          if (field === 'youtube') {
            openErrorDiaglog(
              t(`common.message.youtube_not_found`)
            );
          }else {
            openErrorDiaglog(
              t(`common.message.${errorMess}`, { field: capitalizeField })
            );
          }
        } else {
          openErrorDiaglog(t("common.message.create_fail"));
        }
      })
      .finally(() => dispatch(setActive(false)));
  };

  const handleFollowersChange = (e, setFieldValue) => {
    const { value = "", name = "" } = e.target;
    if (value === "" || onlyNumberRegex.test(value)) {
      setFieldValue(name, value);
    }
  };

  const handleSocialChange = (e, setFieldValue, followersName) => {
    const { name = "", value = "" } = e.target;
    setFieldValue(name, value);
    if (value === "") {
      setFieldValue(followersName, "");
    }
  };

  const isDisableBtn = (values) => {
    if (
      !values?.name?.trim() ||
      !values?.gender ||
      !values?.email?.trim() ||
      (!values?.instagram_account?.trim() &&
        !values?.tiktok_account?.trim() &&
        !values?.face_account?.trim() &&
        !values?.youtube_channel_id?.trim())
    ) {
      return true;
    }
    return false;
  };

  return (
    <LayoutMain customHeader>
      <div className="flex flex-wrap flex-1">
        <SplitBackground title={t("register.add.enter_information")} />
        <main className="w-full md:w-[50%] pb-5">
          <div className="custom-container md:flex justify-center items-center min-h-screen">
            <Formik
              validateOnMount
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={register}
            >
              {({ values, setFieldValue, handleBlur }) => {
                return (
                  <Form>
                    <ScrollToFieldError />
                    <div className="max-w-[568px] mx-auto mt-8">
                      <div className="flex justify-end mb-10">
                        <ChangeLanguageButton className="hover:bg-secondary transition-all duration-200 ease-linear p-2 -m-2" />
                      </div>

                      <div className="mb-10">
                        <label
                          htmlFor="name"
                          className="text-[17px] font-bold inline-block"
                        >
                          {t("validation_field.name")}
                          <span className="required" />
                          <span className="text-[13px] mb-1 ml-2 font-weight-[500] text-[#9D9D9D]">
                            {t("register.add.name_note")}
                          </span>
                        </label>
                        <Field
                          id="name"
                          name="name"
                          className="w-full border border-solid border-secondary-200 rounded-[8px] px-3 py-1 outline-none focus:border-[#000] focus:border-[2px]"
                          maxLength={30}
                        />
                        <ErrorMessage
                          component="div"
                          name="name"
                          className="error-msg"
                        />
                      </div>

                      <div className="mb-10">
                        <p className="text-[17px] font-bold mb-1 inline-block">
                          {t("project.detail.gender")}
                          <span className="required" />
                        </p>
                        <div className="flex flex-col">
                          <FormControl className="max-w-[216px]">
                            <Select
                              value={values.gender}
                              onChange={(e) =>
                                setFieldValue("gender", e.target.value)
                              }
                              onBlur={(e) => handleBlur(e)}
                              name="gender"
                              input={<BootstrapInput />}
                            >
                              <MenuItem value={GENDER.MALE}>
                                {t("common.male")}
                              </MenuItem>
                              <MenuItem value={GENDER.FEMALE}>
                                {t("common.female")}
                              </MenuItem>
                              <MenuItem value={GENDER.OTHERS}>
                                {t("common.other")}
                              </MenuItem>
                            </Select>
                            <ErrorMessage
                              component="div"
                              name="gender"
                              className="error-msg"
                            />
                          </FormControl>
                        </div>
                      </div>

                      <div className="mb-10">
                        <label
                          htmlFor="email"
                          className="text-[17px] font-bold mb-1 inline-block"
                        >
                          {t("validation_field.email")}
                          <span className="required" />
                        </label>
                        <Field
                          id="email"
                          name="email"
                          disabled={email}
                          className="w-full border border-solid border-secondary-200 rounded-[8px] px-3 py-1 outline-none focus:border-[#000] focus:border-[2px]"
                          maxLength={64}
                        />
                        <ErrorMessage
                          component="div"
                          name="email"
                          className="error-msg"
                        />
                      </div>

                      <div className="mb-4 font-bold text-[14px]">
                        <div className="mb-2">{t("register.add.notice1")}</div>
                        <div>{t("register.add.notice2")}</div>
                      </div>

                      <div className="mb-6 grid grid-cols-1 sm:grid-cols-2 gap-6">
                        {/* insta */}
                        <div>
                          <label
                            htmlFor="instagram_account"
                            className="text-[17px] font-bold mb-1 flex items-center"
                          >
                            {generateSNSIcon("instagram", "!w-6 !h-6")}
                            <p>{t("register.add.user_name")}</p>
                          </label>
                          <div className="prefix-social-acc">
                            <Field
                              id="instagram_account"
                              name="instagram_account"
                              className="w-full border border-solid border-secondary-200 rounded-[8px] pr-3 pl-[22px] py-1 outline-none focus:border-[#000] focus:border-[2px]"
                              maxLength={50}
                              onChange={(e) =>
                                handleSocialChange(
                                  e,
                                  setFieldValue,
                                  "instagram_followers"
                                )
                              }
                            />
                          </div>
                          <ErrorMessage
                            component="div"
                            name="instagram_account"
                            className="error-msg"
                          />
                          <div className="flex flex-col gap-1 mt-2">
                            <label
                              className="font-bold"
                              htmlFor="instagram_followers"
                            >
                              {t("common.followers_1")}
                            </label>
                            <Field
                              disabled={!values.instagram_account}
                              id="instagram_followers"
                              name="instagram_followers"
                              className="w-full border border-solid border-secondary-200 rounded-[8px] px-3 py-1 outline-none focus:border-[#000] focus:border-[2px]"
                              maxLength={8}
                              onChange={(e) =>
                                handleFollowersChange(e, setFieldValue)
                              }
                            />
                          </div>
                        </div>

                        {/* tiktok */}
                        <div>
                          <label
                            htmlFor="tiktok_account"
                            className="text-[17px] font-bold mb-1 flex items-center"
                          >
                            {generateSNSIcon("tiktok", "!w-6 !h-6")}
                            <p>{t("register.add.account_name")}</p>
                          </label>
                          <div className="prefix-social-acc">
                            <Field
                              id="tiktok_account"
                              name="tiktok_account"
                              className="w-full border border-solid border-secondary-200 rounded-[8px] pr-3 pl-[22px] py-1 outline-none focus:border-[#000] focus:border-[2px]"
                              onChange={(e) =>
                                handleSocialChange(
                                  e,
                                  setFieldValue,
                                  "tiktok_followers"
                                )
                              }
                            />
                          </div>
                          <ErrorMessage
                            component="div"
                            name="tiktok_account"
                            className="error-msg"
                          />
                          <div className="flex flex-col gap-1 mt-2">
                            <label
                              className="font-bold"
                              htmlFor="tiktok_followers"
                            >
                              {t("common.followers")}
                            </label>
                            <Field
                              disabled={!values.tiktok_account}
                              id="tiktok_followers"
                              name="tiktok_followers"
                              className="w-full border border-solid border-secondary-200 rounded-[8px] px-3 py-1 outline-none focus:border-[#000] focus:border-[2px]"
                              maxLength={8}
                              onChange={(e) =>
                                handleFollowersChange(e, setFieldValue)
                              }
                            />
                          </div>
                        </div>

                        {/* <div>
                          <label
                            htmlFor="twitter_account"
                            className="text-[17px] font-bold mb-1 flex items-center"
                          >
                            <img
                              src={iconTwitter}
                              alt=""
                              className="w-6 h-6 mr-1"
                            />
                            <p>{t("register.add.account_name")}</p>
                          </label>
                          <Field
                            id="twitter_account"
                            name="twitter_account"
                            className="w-full border border-solid border-secondary-200 rounded-[8px] px-3 py-1 outline-none focus:border-[#000] focus:border-[2px]"
                            maxLength={8}
                          />
                          <ErrorMessage
                            component="div"
                            name="twitter_account"
                            className="error-msg"
                          />
                        </div> */}

                        {/* facebook */}
                        <div>
                          <label
                            htmlFor="face_account"
                            className="text-[17px] font-bold mb-1 flex items-center"
                          >
                            {generateSNSIcon("facebook", "!w-6 !h-6")}
                            <p>{t("register.add.user_name")}</p>
                          </label>
                          <div className="prefix-social-acc">
                            <Field
                              id="face_account"
                              name="face_account"
                              className="w-full border border-solid border-secondary-200 rounded-[8px] pr-3 pl-[22px] py-1 outline-none focus:border-[#000] focus:border-[2px]"
                              onChange={(e) =>
                                handleSocialChange(
                                  e,
                                  setFieldValue,
                                  "face_followers"
                                )
                              }
                            />
                          </div>
                          <ErrorMessage
                            component="div"
                            name="face_account"
                            className="error-msg"
                          />
                          <div className="flex flex-col gap-1 mt-2">
                            <label
                              className="font-bold"
                              htmlFor="face_followers"
                            >
                              {t("common.followers")}
                            </label>
                            <Field
                              disabled={!values.face_account}
                              id="face_followers"
                              name="face_followers"
                              className="w-full border border-solid border-secondary-200 rounded-[8px] px-3 py-1 outline-none focus:border-[#000] focus:border-[2px]"
                              maxLength={8}
                              onChange={(e) =>
                                handleFollowersChange(e, setFieldValue)
                              }
                            />
                          </div>
                        </div>
                      </div>

                      {/* youtube */}
                      <div className="mb-10">
                        <label
                          htmlFor="youtube_channel_id"
                          className="text-[17px] font-bold mb-1 flex items-center"
                        >
                          {generateSNSIcon("youtube", "!w-6 !h-6")}
                          <p>{t("register.add.youtube_channel_id")}</p>
                        </label>
                        <div>
                          <Field
                            id="youtube_channel_id"
                            name="youtube_channel_id"
                            className="w-full border border-solid border-secondary-200 rounded-[8px] px-3 py-1 outline-none focus:border-[#000] focus:border-[2px]"
                          />
                        </div>
                        <ErrorMessage
                          component="div"
                          name="youtube_channel_id"
                          className="error-msg"
                        />
                      </div>

                      <div className="mb-10">
                        <label
                          htmlFor="self_introduction"
                          className="text-[17px] font-bold mb-1 inline-block"
                        >
                          <p>{t("validation_field.self_introduction")}</p>
                        </label>
                        <Field
                          name="self_introduction"
                          className="w-full border border-solid border-secondary-200 rounded-[8px] px-3 py-1 outline-none focus:border-[#000] focus:border-[2px]"
                        />
                        <ErrorMessage
                          component="div"
                          name="self_introduction"
                          className="error-msg"
                        />
                      </div>

                      <div className="flex justify-between gap-2">
                        <button
                          type="button"
                          className="text-base leading-5 text-REGULAR_TEXT_COLOR font-semibold underline cursor-pointer hover:text-black"
                          onClick={(e) => logout(e)}
                        >
                          {t("common.action.back")}
                        </button>
                        <Button
                          disabled={isDisableBtn(values)}
                          type="submit"
                          className=" !text-[#fff] !min-w-[100px] !rounded-[8px] !px-4 !py-2"
                          classes={{
                            root: "!bg-[#0E5CB5]",
                          }}
                        >
                          {t("common.action.save")}
                        </Button>
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </main>
      </div>
      <SuccessDialog
        message={message}
        open={openSuccess}
        onClose={onSuccess}
        onOK={onSuccess}
      />
      <ErrorDialog
        message={message}
        open={openError}
        onClose={onError}
        onOK={onError}
      />
    </LayoutMain>
  );
}

export default Register;
