/* eslint-disable react/no-array-index-key */
import React, { useState } from "react";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import { EResponseStatus, toastDefaultOptions } from "app/helper/constant";
import { Tooltip } from "@mui/material";
import InfluencerService from "app/services/InfluencerService";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import {
  formatNumber,
  generateSNSIcon,
  getProjectDetailByLanguage,
  projectFeeDisplay,
} from "app/utils";
import { toast } from "react-toastify";
import ImageItem from "./ImageItem";
import ErrorDialog from "./common/ErrorDialog";
import Path from "../route/Path";
import SkeletonItem from "./SkeletonItem";
import Media from "./Media";

function ProjectItem({
  project,
  isLoading,
  openChat,
  linkUrl,
  deleteItem,
  updateList,
}) {
  const { t } = useTranslation();
  const [favorite, setFavorite] = useState(project.is_favorite);
  const [openError, setOpenError] = useState(false);
  const navigate = useNavigate();

  const markFavorite = (e) => {
    e.stopPropagation();
    e.preventDefault();
    InfluencerService.markFavorite(project.ad_project_id)
      .then((response) => {
        if (response.status === EResponseStatus.CREATED) {
          setFavorite(response.data.data.is_favorite);
          toast.success(
            response.data.data.is_favorite
              ? t("common.message.saved_to_wishlist")
              : t("common.message.remove_from_wishlist"),
            toastDefaultOptions
          );
          if (deleteItem && response.data.data.is_favorite === false) {
            updateList();
          }
        }
      })
      .catch(() => {
        toast.error(
          t("common.message.saved_to_wishlist_error"),
          toastDefaultOptions
        );
      });
  };

  const goToDetail = (e) => {
    e.stopPropagation();
    e.preventDefault();
    navigate(Path.project_detail(project.ad_project_id));
  };

  function commonContent(item) {
    return (
      <button
        type="button"
        onClick={(e) => markFavorite(e, item)}
        className="absolute top-3 right-3 rounded-full p-1 z-[2]"
      >
        <svg
          viewBox="0 0 32 32"
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden="true"
          role="presentation"
          focusable="false"
          className={`block h-5 w-5 stroke-2 stroke-[#fff] ${
            favorite ? "fill-[#FF385C]" : "fill-[#00000080]"
          }`}
        >
          <path d="m16 28c7-4.733 14-10 14-17 0-1.792-.683-3.583-2.05-4.95-1.367-1.366-3.158-2.05-4.95-2.05-1.791 0-3.583.684-4.949 2.05l-2.051 2.051-2.05-2.051c-1.367-1.366-3.158-2.05-4.95-2.05-1.791 0-3.583.684-4.949 2.05-1.367 1.367-2.051 3.158-2.051 4.95 0 7 7 12.267 14 17z" />
        </svg>
      </button>
    );
  }

  return (
    <Link
      to={linkUrl || ""}
      className="relative project-item rounded-[12px] p-[2px]"
    >
      {commonContent(project)}
      {project.project_media.length ? (
        <Swiper
          pagination
          navigation
          modules={[Pagination, Navigation]}
          className="mySwiper rounded-[12px]"
          slidesPerView={1}
        >
          {project.project_media.map((photo, index) => {
            return (
              <SwiperSlide key={index} className="w-100">
                <figure className="mb-3 relative pt-[95%]">
                  {isLoading ? (
                    <div className="absolute top-0 w-full h-full">
                      <Skeleton
                        animation="wave"
                        variant="rectangular"
                        width={9999}
                        height={9999}
                      />
                    </div>
                  ) : (
                    <div className="w-full h-full absolute top-0 cursor-pointer">
                      {(() => {
                        const isVideoProps = `${photo.thumbnail_url}`
                          ?.toLowerCase()
                          ?.includes(".mp4");
                        return (
                          <Media
                            lazy="true"
                            openChat={openChat || null}
                            data={photo.thumbnail_url}
                            isVideoProps={isVideoProps}
                            className="w-full h-full"
                            skeletonClassName="!rounded-[12px]"
                            videoClass="w-full h-full object-cover rounded-[12px]"
                            imageClassName="w-full h-full object-cover rounded-[12px]"
                          />
                        );
                      })()}
                    </div>
                  )}
                </figure>
              </SwiperSlide>
            );
          })}
        </Swiper>
      ) : (
        <figure className="mb-3 relative pt-[95%]">
          <div className="w-full h-full absolute top-0 cursor-pointer">
            <ImageItem
              role="presentation"
              imgclassname="w-full h-full object-cover rounded-[12px]"
              skeletonclassname="rounded-[12px]"
              imagesrc=""
              alt=""
              onClick={openChat || null}
            />
          </div>
        </figure>
      )}
      {isLoading ? (
        <Stack spacing={1}>
          <Skeleton animation="wave" variant="text" width={200} height={40} />
          <div className="flex items-center justify-between">
            <Skeleton animation="wave" variant="text" width={200} />
          </div>
        </Stack>
      ) : (
        <div
          onClick={(e) => goToDetail(e)}
          className="block"
          role="presentation"
        >
          <Tooltip title={getProjectDetailByLanguage(project)?.title || ""}>
            <p className="mb-2 text-[18px] font-bold truncate text-left h-[28px]">
              {getProjectDetailByLanguage(project)?.title}
            </p>
          </Tooltip>

          <SkeletonItem
            isLoading={isLoading}
            variant="rounded"
            width={100}
            height={40}
          >
            <Tooltip
              title={
                getProjectDetailByLanguage(project)?.reward_condition || ""
              }
            >
              <p className="truncate mb-2 font-bold h-[24px]">
                {getProjectDetailByLanguage(project)?.reward_condition}
              </p>
            </Tooltip>
          </SkeletonItem>

          <div className="flex items-center justify-between flex-wrap truncate">
            {isLoading ? (
              <Stack spacing={1}>
                <Skeleton
                  animation="wave"
                  variant="text"
                  width={200}
                  height={40}
                />
                <div className="flex items-center justify-between">
                  <Skeleton animation="wave" variant="text" width={100} />
                  <Skeleton animation="wave" variant="text" width={100} />
                </div>
              </Stack>
            ) : (
              <div className="flex items-center justify-between w-full gap-3">
                {/* Eee */}
                <div className="font-bold text-[15px] flex items-center truncate w-1/2">
                  <div className="truncate">
                    {project.fee[0] && project.fee[0].fee
                      ? formatNumber(
                          projectFeeDisplay(project.fee[0].fee),
                          true
                        )
                      : ""}
                  </div>
                </div>

                {/* SNS followers */}
                <div className="flex items-center w-1/2 justify-end">
                  {project?.ad_media?.map(item => generateSNSIcon(item, "flex-shrink-0"))}
                  <div className="font-bold text-[15px] truncate">
                    {project.desired_conditions.min_followers
                      ? `${formatNumber(
                          project.desired_conditions.min_followers
                        )} ${t("project.listAll.more_people")}`
                      : ""}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      <ErrorDialog
        message={t("common.message.update_fail")}
        open={openError}
        onClose={() => setOpenError(false)}
        onOK={() => setOpenError(false)}
      />
    </Link>
  );
}

export default ProjectItem;
