export default {
  prepare_payment: "Payment Processing",
  payable: "Payment Available",
  payment_request: "Payment Requested",
  paid: "Payment Paid",
  all: "All",
  price: "Amount",
  request_pay: "Request Payment",
  notice:
    "※Payment requests are possible when the available amount is ¥50,000 or more.",
  title: "Recruitment Case Name",
  fee: "Reward Amount",
  finished_at: "Completion Approval Date",
};
