export const MIN_LIMIT = {
  // default
  DEFAULT: 0,
};

export const MAX_LIMIT = {
  // default
  INPUT_TEXT: 100,
  INPUT_TEXT_AREA: 5000,

  // other
  FAMILY_NAME: 64,
  PHONE_NUMBER: 20,
  NUMBER_OF_USERS: 100,
};

export const GENDER = {
  MALE: "male",
  FEMALE: "female",
  OTHERS: "other",
};

export const EResponseStatus = {
  SUCCESS: 200,
  CREATED: 201,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
};

export const EProjectStatus = {
  WAIT_APPROVAL_RECRUITMENT: "wait_approval_recruitment",
  IN_PROGRESS: "in_progress",
  WAIT_APPROVAL_FINISHED: "wait_approval_finished",
  COMPLETED: "completed",
  SPECIAL_OFFER_FROM_OPERATOR: "special_offer_from_operator",
  ALL: "all",
  IN_PROGRESS_AND_REJECT_REPORT: "in_progress_and_reject_report",
  CANCEL_TRANSACTION: "cancel_transaction",
};

export const ERewardStatus = {
  PREPARING_PAYMENT: 3,
  PAYABLE: 4,
  PAYMENT_REQUEST: 1,
  PAID: 2,
  ALL: 0,
};

export const APPLIED_PROJECT_STATUS = {
  WAIT_APPROVAL_RECRUITMENT: "WAIT_APPROVAL_RECRUITMENT",
  IN_PROGRESS: "IN_PROGRESS",
  WAIT_APPROVAL_FINISHED: "WAIT_APPROVAL_FINISHED",
  COMPLETED: "COMPLETED",
  DECLINED: "DECLINED",
  REJECT_REPORT: "REJECT_REPORT",
  CANCEL_TRANSACTION: "CANCEL_TRANSACTION",
};

export const INFLUENCER_APPLIED_FOR_AD_PROJECT_STATUS = {
  REVIEW: 1,
  DECLINED: 2,
  ACCEPTED: 3,
  REJECTED: 4,
  CANCELED: 5,
  FINISHED: 6,
  APPROVED_DELIVERABLE: 7,
  DECLINE_DELIVERABLE: 8,
};

export const PROJECT_APPLIED_TYPE = {
  APPLIED: 1,
  SPECIAL_OFFER: 2,
};

export const EFileType = {
  IMAGE: "image",
  VIDEO: "video",
  PDF: "pdf",
  SVG: "svg",
  MP4: "mp4",
  HEIC: "heic",
};

export const EFacilityType = {
  RESTAURANT: 1,
  ACCOMMODATION: 2,
};

export const toastDefaultOptions = {
  position: "bottom-left",
  autoClose: 2000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: true,
  progress: undefined,
  theme: "light",
};

export const PER_PAGE = 20;
export const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB
export const MAX_VIDEO_SIZE = 50 * 1024 * 1024; // 50MB
export const FILE_FORMAT = [
  "image/png",
  "image/jpg",
  "image/jpeg",
  "application/pdf",
  "image/heic",
];
export const isVideoRegex = /(\.mp4)$/i;
export const emailRegex =
  /^((?=[^@._+-])(([A-Za-z0-9._+-]))*@((([A-Za-z0-9])*([._-])?)([._-]))+((([A-Za-z0-9])){1,}))$/;
export const postcodeRegex =
  /^(?:(?=[a-z0-9-]{8}$)[a-z0-9]*-[a-z0-9]*|[a-z0-9]{7})$/;
export const onlyNumberRegex = /^[0-9]+$/;
export const passwordRegex =
  /^(?=.*[0-9])(?=.*[A-Za-z])(?=.*[!%@#&\-()_[\]{}:;`'",.+*?\\/~$^=<>|\\])[0-9A-Za-z!%@#&\-()_[\]{}:;`'",.+*?\\/~$^=<>|\\]{8,64}$/; // minimum 8 characters, at least one letter, one number and one special characters
export const postCodeRegexInput = /^[0-9-]+$/;

export const COUNTRY_LIST = {
  JA: "jp",
};

export const PROJECT_AD_STATUS = {
  Draft: 1,
  Publish: 2,
  Private: 4,
  Trash: 5,
};

export const EProjectReward = {
  FIXED_PRICE: "fixed_price",
  FREE: "instead_of_fee",
};

export const EProjectRequest = {
  REQUEST: 1,
  NOT_REQUEST: 0,
};
