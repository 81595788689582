export default {
  add: {
    name_note:
      " (This information will also be visible to the cases' recruiters)",
    user_name: "Username",
    account_name: "Username",
    youtube_channel_id: "Channel ID",
    enter_information: "Please fill in the required information.",
    notice1: "※To become an influencer, you need to register your SNS account.",
    notice2:
      "Please enter the account information for one of the following SNS options.",
  },
};
