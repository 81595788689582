export default {
  list: {
    waiting_application_approval: "Pending Approval",
    waiting_completion_approval: "Pending Completion Approval",
    completed: "Completed",
    offer_from_operator: "Special Offer from Business",
  },
  detail: {
    keep: "Save",
    unkeep: "Saved",
    what_want_do: "Tasks for You",
    designation_sns: "Specify SNS Platforms",
    designation_pr: "Specify PR Methods",
    page_pr: "Designated PR Page",
    tag_acc: "Tagged Accounts",
    what_your_post: "MUST Inclusions in Posts",
    tag_must_post: "Secified Tags to Include",
    reward: "Reward",
    original_price: "Original Offering Price",
    post_period: "Post Period",
    already_applied: "Applied to This Requirements",
    show_all_image: "View All Photos",
    fixed_price: "Fixed Compensation",
    free_supply: "Free Offer",
    apply_condition: "Application Requirements",
    reward_condition: "Reward Conditions",
    gender: "Gender",
    age_group: "Age Group",
    min_followers: "Minimum Number of Followers",
    view_more: "See More",
    show_less: "Reduce Display",
  },
  listAll: {
    more_people: "or more",
    project_list: "Recruitment Cases",
  },
  apply: {
    title: "Self-PR for Projects",
    pr_url: "PR Work URL",
    pr_work_file: "PR Work File",
    apply_successful:
      "Application completed.<br />Please wait for the business operator to confirm.",
    apply_fail: "Apply failed",
    error_upload:
      "An error occurred during file upload. Please try again.",
    url_placeholder: "Please enter the URL of the posting SNS (optional).",
    message_placeholder:
      "Please enter comments for the business regarding the deliveraed items.",
    delivery_success:
      "The completion report has been submitted. <br/> Please wait for the business to approve it.",
  },
  chat: {
    waiting_application_approval_mess:
      "Pending approval from the business for your application.",
    waiting_report_approval_mess:
      "Pending approval from the business for completion.",
    reject_report_mess:
      "Completion report was not approved. Please correct the indicated contents and submit the completion report again.",
    decline_mess: "Your application was not approved.",
    cancel_mess: "The transaction has been canceled.",
    start_report_btn: "Submit the completion report",
    completed_mess: "Completion done.",
    cancel_transaction: "Cancel Transaction",
    accept_special_offer: "Accept the special offer",
    completed_mess_date:
      "{{from}} approved the completion report on {{- date}}.",
    open_project_detail: "Open Projects Details",
  },
};
