import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import store, { persistor } from "app/redux/store";
import AppRoute from "app/route";
import "app/assets/styles/tailwind.css";
import "app/assets/styles/index.scss";
import { I18nextProvider } from "react-i18next";
import i18n from "app/langs";
import "./validation";
import BlockUI from "app/components/BlockUI";
import { SocketContext, socketIO } from "app/context/socket";
import dayjs from "dayjs";
import i18next from "i18next";
import reportWebVitals from "./reportWebVitals";

require("dayjs/locale/ja");
require("dayjs/locale/zh-cn");
require("dayjs/locale/en");

const root = ReactDOM.createRoot(document.getElementById("root"));
if (i18next.resolvedLanguage === "cn") {
  dayjs.locale("zh-cn");
} else {
  dayjs.locale(i18next.resolvedLanguage);
}

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <I18nextProvider i18n={i18n}>
        <SocketContext.Provider value={socketIO}>
          <AppRoute />
          <BlockUI />
        </SocketContext.Provider>
      </I18nextProvider>
    </PersistGate>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
