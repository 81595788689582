/* eslint-disable camelcase */
/* eslint-disable react/no-array-index-key */
import React from "react";
import Drawer from "@mui/material/Drawer";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { useTranslation } from "react-i18next";
import { ErrorMessage, Form, Formik } from "formik";
import * as Yup from "yup";
import { MenuItem, Select } from "@mui/material";
import { listAgeGroup, listPrMethod, listRewardType, listSNS } from "./data";
import {getGeneration} from "../../utils";

function SeacrhFilterComponent({
  isDrawerOpen,
  setDrawerState,
  setFilterValues,
  filterValues,
  ageGroupArray,
}) {
  const { t, i18n } = useTranslation();

  const onSubmitFilter = (values) => {
    const ageGroupTextValues = listAgeGroup
      .filter((item) => {
        if (values?.age_group_start && values?.age_group_end) {
          return (
            item.value >= values?.age_group_start &&
            item.value <= values?.age_group_end
          );
        }
        return null;
      })
      .map((item) => item.value);

    setFilterValues({
      ...filterValues,
      reward_type: values?.reward_type,
      ad_method: values?.ad_method,
      sns: values?.sns,
      age_group: ageGroupTextValues,
      page: 1,
    });
    setDrawerState(false);
  };

  const initialValues = {
    reward_type: filterValues?.reward_type,
    ad_method: filterValues?.ad_method,
    sns: filterValues?.sns,
    age_group_start: ageGroupArray[0] || "",
    age_group_end: ageGroupArray[ageGroupArray.length - 1] || "",
  };

  const validationSchema = Yup.object().shape(
    {
      age_group_end: Yup.string().when(["age_group_start"], {
        is: (age_group_start) => {
          return age_group_start?.trim();
        },
        then: Yup.string().required(),
      }),
      age_group_start: Yup.string().when(["age_group_end"], {
        is: (age_group_end) => {
          return age_group_end?.trim();
        },
        then: Yup.string().required(),
      }),
    },
    ["age_group_end", "age_group_start"]
  );

  const handleDisableItem = (type, value, target) => {
    if (type === "start") {
      if (target && value > target) {
        return true;
      }
      return false;
    }
    if (target && value < target) {
      return true;
    }
    return false;
  };

  return (
    <Drawer
      anchor="bottom"
      open={isDrawerOpen}
      onClose={() => {
        setDrawerState(false);
      }}
      className="!z-[1031]"
      classes={{
        paper:
          "!h-[80%] !w-[80%] !mx-auto !max-w-[600px] !top-[10%] rounded-[12px] !bg-white",
      }}
      transitionDuration={600}
    >
      <header className="border-b border-solid border-secondary-300 px-4">
        <div className="flex items-center justify-center relative py-5">
          <button
            type="button"
            onClick={() => {
              setDrawerState(false);
            }}
            className="w-7 h-7 flex items-center justify-center absolute left-0 top-[50%] translate-y-[-50%] border border-black rounded-full"
          >
            <i className="text-[20px] fa-solid fa-xmark " />
          </button>
          <p className="font-[700]"> {t("common.action.filter")}</p>
        </div>
      </header>
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={onSubmitFilter}
      >
        {(props) => {
          const { values, handleChange, resetForm } = props;
          return (
            <Form className="flex flex-col h-full overflow-auto">
              <main className="flex-1">
                <div className="border-b border-solid border-secondary-300">
                  <div className="pt-6 px-4">
                    <p className="text-[18px] font-[700]">
                      {t("home.reward_type")}
                    </p>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 pt-4 pb-2 pl-7">
                      {listRewardType.map((item, index) => {
                        return (
                          <div key={index} className="w-full flex items-center">
                            <RadioGroup
                              name="reward_type"
                              onChange={handleChange}
                            >
                              <FormControlLabel
                                checked={values?.reward_type === item.value}
                                value={item.value}
                                classes={{ root: "!items-start" }}
                                control={
                                  <Radio classes={{ root: "!mt-[-8px]" }} />
                                }
                                label={<p>{t(item.label)}</p>}
                              />
                            </RadioGroup>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>

                <div className="border-b border-solid border-secondary-300">
                  <div className="pt-6 px-4">
                    <p className="text-[18px] font-[700]">
                      {t("home.pr_method")}
                    </p>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 pt-4 pb-2 pl-7">
                      {listPrMethod.map((item, index) => {
                        return (
                          <div key={index} className="w-full flex items-center">
                            <RadioGroup
                              name="ad_method"
                              onChange={handleChange}
                            >
                              <FormControlLabel
                                checked={values?.ad_method === item.value}
                                value={item.value}
                                classes={{ root: "!items-start" }}
                                control={
                                  <Radio classes={{ root: "!mt-[-8px]" }} />
                                }
                                label={<p>{t(item.label)}</p>}
                              />
                            </RadioGroup>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>

                <div className="border-b border-solid border-secondary-300">
                  <div className="pt-6 px-4">
                    <p className="text-[18px] font-[700]">
                      {t("home.sns_specify")}
                    </p>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 pt-4 pb-2 pl-7">
                      {listSNS.map((item, index) => {
                        return (
                          <div key={index} className="w-full flex items-center">
                            <RadioGroup name="sns" onChange={handleChange}>
                              <FormControlLabel
                                checked={values?.sns === item.value}
                                value={item.value}
                                classes={{ root: "!items-start" }}
                                control={
                                  <Radio classes={{ root: "!mt-[-8px]" }} />
                                }
                                label={<p>{t(item.label)}</p>}
                              />
                            </RadioGroup>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>

                <div className="pt-6 px-4">
                  <p className="text-[18px] font-[700]">
                    {t("home.age_specify")}
                  </p>
                  <div className="flex flex-col md:flex-row gap-4 md:gap-10 pt-4 pb-2 px-7 items-baseline">
                    <div className="age_group_start flex-1 w-[200px] md:w-[auto]">
                      <Select
                        className="w-full"
                        onChange={handleChange}
                        name="age_group_start"
                        value={values.age_group_start}
                      >
                        {listAgeGroup.map((item, index) => {
                          return (
                            <MenuItem
                              disabled={handleDisableItem(
                                "start",
                                item.value,
                                values.age_group_end
                              )}
                              key={index}
                              value={item.value}
                            >
                              {getGeneration(item.label, i18n.language)}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      <ErrorMessage
                        component="div"
                        name="age_group_start"
                        className="error-msg mt-1"
                      />
                    </div>
                    <div className="text-center w-[200px] md:w-[auto]">～</div>
                    <div className="age_group_end flex-1 w-[200px] md:w-[auto]">
                      <Select
                        className="w-full"
                        onChange={handleChange}
                        name="age_group_end"
                        value={values.age_group_end}
                      >
                        {listAgeGroup.map((item, index) => {
                          return (
                            <MenuItem
                              disabled={handleDisableItem(
                                "end",
                                item.value,
                                values.age_group_start
                              )}
                              key={index}
                              value={item.value}
                            >
                              {getGeneration(item.label, i18n.language)}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      <ErrorMessage
                        component="div"
                        name="age_group_end"
                        className="error-msg mt-1"
                      />
                    </div>
                  </div>
                </div>
              </main>

              <footer className="px-4">
                <div className="h-[80px] flex items-center justify-between">
                  <button
                    onClick={() =>
                      resetForm({
                        values: {
                          reward_type: "",
                          ad_method: "",
                          sns: "",
                          age_group_start: "",
                          age_group_end: "",
                        },
                      })
                    }
                    type="button"
                    className="font-bold underline mr-2"
                  >
                    {t("common.action.clear_all")}
                  </button>
                  <Button
                    variant="contained"
                    type="submit"
                    className="!py-3 !px-[23px] !bg-black !text-white !font-bold !rounded-[8px] !min-w-[100px]"
                  >
                    OK
                  </Button>
                </div>
              </footer>
            </Form>
          );
        }}
      </Formik>
    </Drawer>
  );
}

export default SeacrhFilterComponent;
