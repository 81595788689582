import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { CircularProgress, Button } from "@mui/material";
import { useLocation } from "react-router-dom";
import { ErrorMessage, Field, Formik, Form } from "formik";
import * as Yup from "yup";
import { emailRegex, EResponseStatus } from "app/helper/constant";
import { useSelector, useDispatch } from "react-redux";
import UserService from "app/services/UserService";
import { setActive } from "app/redux/blockUIReducer";
import ChangeLanguageButton from "app/components/ChangeLanguageButton";
import SigninSocial from "./SignInSocial";
import PopupShowMessage from "./PopupShowMessage";
import FormLoginWithEmail from "./FormLoginWithEmail";
import { setEmail } from "../../redux/emailReducer";

function LoginOptions({ showLoginOptions, setShowLoginOptions }) {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();

  const paramSearch = new URLSearchParams(location.search);
  const code = paramSearch.get("code");
  const social = useSelector((state) => state.social);

  const [isLoading, setIsLoading] = useState(false);
  const [errorMess, setErrorMess] = useState("");
  const [openPopupShowMessage, setOpenPopupShowMessage] = useState(false);
  const [openFormLoginWithEmail, setOpenFormLoginWithEmail] = useState(false);

  const initialValues = {
    email: "",
    social_id_provider: "email_influencer",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().required(
      t("validation_field.email_message")
    ).trim().matches(emailRegex, { message: t("validation_field.email_invalid_message") }),
  });

  const onSubmit = (values) => {
    dispatch(setActive(true));
    dispatch(setEmail(values?.email?.trim()));
    const checkExistPayload = {
      login_id: values?.email?.trim(),
      social_id_provider: values?.social_id_provider,
    };
    UserService.checkExistEmail(checkExistPayload)
      .then((response) => {
        if (response.status === EResponseStatus.CREATED) {
          if (!response?.data?.data) {
            setShowLoginOptions(false);
            setOpenFormLoginWithEmail(true);
          } else {
            setOpenPopupShowMessage(true);
          }
        }
      })
      .catch(() => setErrorMess(t("common.message.create_fail")))
      .finally(() => dispatch(setActive(false)));
  };

  useEffect(() => {
    if (code && social) {
      setShowLoginOptions(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Modal
        open={showLoginOptions}
        onClose={() => {
          setErrorMess("");
          setShowLoginOptions(false);
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={showLoginOptions}>
          <div className="flex justify-center items-center h-full p-6">
            <div className="relative max-w-[600px] w-full">
              <div className="border-0 rounded-[4px] shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
                <div className="relative flex items-center justify-center p-6 border-b border-border">
                  <div className="absolute left-6">
                    <IconButton
                      edge="start"
                      color="inherit"
                      onClick={() => {
                        setErrorMess("");
                        setShowLoginOptions(false);
                      }}
                      aria-label="close"
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                  <p className="font-[700]">{t("auth.Login_or_Register")}</p>
                  <ChangeLanguageButton className="hover:bg-secondary transition-all duration-200 ease-linear absolute right-6 p-2 -m-2" />
                </div>

                <div className="p-6">
                  <div className="text-[22px] mb-4 font-semibold">
                    {t("auth.welcome")}
                  </div>

                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                  >
                    {({ values, handleChange }) => {
                      return (
                        <Form>
                          {errorMess && (
                            <div className="error-msg mb-4">{errorMess}</div>
                          )}
                          <div className="input-field mb-4">
                            <Field
                              name="email"
                              maxLength={100}
                              onChange={(e) => {
                                handleChange(e);
                                setErrorMess("");
                              }}
                              placeholder={t("validation_field.email")}
                              className="w-full bg-gray-50 border border-border text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
                            />
                            <ErrorMessage
                              component="div"
                              name="email"
                              className="error-msg mt-1"
                            />
                          </div>
                          <Button
                            type="submit"
                            disabled={!values?.email}
                            className="!w-full sm:!text-[16px] !text-white !bg-[#0E5CB5] !rounded-[8px]"
                          >
                            {t("common.action.continue")}
                          </Button>
                        </Form>
                      );
                    }}
                  </Formik>

                  <div className="flex justify-center items-center my-[16px]">
                    <div className="flex-1 h-px bg-[#d1d1d1]" />
                    <div className="px-[24px] text-[12px] bg-white w-fit mx-auto flex flex-col">
                      <div>{t("or1")}</div>
                      <div>{t("or2")}</div>
                      <div>{t("or3")}</div>
                    </div>
                    <div className="flex-1 h-px bg-[#d1d1d1]" />
                  </div>

                  <SigninSocial setIsLoading={setIsLoading} />
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
      {isLoading ? (
        <div className="flex items-center justify-center h-full w-full z-[1500] top-0 left-0 transition fixed bg-black/[.5]">
          <CircularProgress size={50} />
        </div>
      ) : null}
      <PopupShowMessage
        open={openPopupShowMessage}
        label={t("auth.register_label")}
        message={t("auth.register_mess")}
      />
      <FormLoginWithEmail
        open={openFormLoginWithEmail}
        setOpen={setOpenFormLoginWithEmail}
      />
    </div>
  );
}

export default LoginOptions;
