export default {
  home: "/",
  login: "/login",
  profile: "/profile",
  project_applied: "/project-applied",
  project_detail: (projectId) =>
    projectId ? `/project/${projectId}` : "/project/:projectId",
  project_apply: (projectId) =>
    projectId ? `/project/${projectId}/apply` : "/project/:projectId/apply",
  register: "/register",
  account_settings: "/account-settings",
  account_settings_personal_info: "/account-settings/personal-info",
  // account_settings_global_setting: "/account-settings/global-setting",
  account_settings_login_security: "/account-settings/login-security",
  term_of_service: "/term-of-service",
  privacy_policy: "/privacy-policy",
  reward: "/reward",
  favorite: "/favorite",
  withdraw: "/withdraw",
  change_password: '/change-password'
};
