/* eslint-disable camelcase */
// i-002-001
import React, {
  createContext,
  useEffect,
  useMemo,
  useState,
  useCallback,
} from "react";
import LayoutMain from "app/layouts/LayoutMain";
import MainContent from "app/components/common/MainContent";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import Pagination from "@mui/material/Pagination";
import ProjectItem from "app/components/ProjectItem";
import { useLocation, useNavigate } from "react-router-dom";
import TuneIcon from "@mui/icons-material/Tune";
import Banner from "app/components/Banner";
import i18next from 'i18next';
import SearchFilterComponent from "./SeacrhFilterComponent";
import useCoreComponent from "../../hooks/useCoreComponent";
import { PER_PAGE } from "../../helper/constant";
import Path from "../../route/Path";
import { removeFieldEmptyValue } from "../../utils";
import AddressSearchInput from "./AddressSearchInput";

const GET_INFLUENCER_LIST = `influencer/ad-project/get-all`;
const GET_BANNER_LIST = `influencer/banner/get-all`;

export const HomeContext = createContext();

const BLANK_FILTER_OBJ = {
  page: 1,
  reward_type: "",
  ad_method: "",
  sns: "",
  age_group: [],
  address: "",
  latitude: "",
  longitude: "",
};

function Home() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const location = useLocation();
  const paramSearch = new URLSearchParams(location.search);

  const initialFilterData = {
    page: paramSearch.get("page") || 1,
    reward_type: paramSearch.get("reward_type") || "",
    ad_method: paramSearch.get("ad_method") || "",
    sns: paramSearch.get("sns") || "",
    age_group: paramSearch?.get("age_group")?.split(",") || [],
    address: paramSearch?.get("address") || "",
    latitude: paramSearch?.get("latitude") || "",
    longitude: paramSearch?.get("longitude") || "",
    locale: paramSearch?.get("address") ? i18next.resolvedLanguage  : ""
  };

  const [filterValues, setFilterValues] = useState(initialFilterData);
  const [showSearchPlacesAround, setShowSearchPlacesAround] = useState(false);
  const [searchPlacesAround, setSearchPlacesAround] = useState(
    !!(filterValues?.longitude || filterValues?.latitude)
  );
  const { response, errorMess, totalPages, isLoading } = useCoreComponent(
    GET_INFLUENCER_LIST,
    removeFieldEmptyValue({
      per_page: PER_PAGE,
      ...removeFieldEmptyValue(filterValues),
    })
  );

  const { response: bannerResponse, isLoading: isBannerLoading } =
    useCoreComponent(
      GET_BANNER_LIST,
      removeFieldEmptyValue({ page: 1, per_page: PER_PAGE }),
      true
    );

  const filterValuesFormat = {
    ...filterValues,
    age_group: filterValues?.age_group?.length ? filterValues?.age_group : "",
  };
  delete filterValuesFormat?.page;
  delete filterValuesFormat?.per_page;
  delete filterValuesFormat?.longitude;
  delete filterValuesFormat?.latitude;
  delete filterValuesFormat?.address;

  useEffect(() => {
    // handle change url
    const options = {
      page: filterValues.page || 1,
      per_page: PER_PAGE,
      reward_type: filterValues.reward_type || "",
      ad_method: filterValues.ad_method || "",
      sns: filterValues.sns || "",
      age_group: filterValues.age_group.length ? filterValues.age_group : "",
      address: filterValues.address || "",
      longitude: filterValues.longitude || "",
      latitude: filterValues.latitude || "",
      locale: filterValues.locale || "",
    };
    const url = new URLSearchParams(removeFieldEmptyValue(options));
    navigate(`?${url}`, { replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValues]);

  const setDrawerState = (state) => {
    setIsDrawerOpen(state);
  };

  const handleChangePage = (selectedPage) => {
    setFilterValues((prev) => ({
      ...prev,
      page: selectedPage,
    }));
    window.scrollTo(0, 0);
  };

  const resetFilter = useCallback(() => {
    setFilterValues(BLANK_FILTER_OBJ);
    setSearchPlacesAround(false);
    setShowSearchPlacesAround(false);
  }, []);

  return (
    <HomeContext.Provider
      value={useMemo(
        () => ({
          showSearchPlacesAround,
          setShowSearchPlacesAround,
          searchPlacesAround,
          setSearchPlacesAround,
        }),
        [
          showSearchPlacesAround,
          setShowSearchPlacesAround,
          searchPlacesAround,
          setSearchPlacesAround,
        ]
      )}
    >
      <LayoutMain setFilterValues={setFilterValues} resetFilter={resetFilter}>
        <MainContent>
          <div className="h-full w-full flex flex-col">
            {bannerResponse?.length > 0 && !isBannerLoading ? (
              <Banner banners={bannerResponse} />
            ) : null}

            <div className="flex flex-col flex-1 w-full">
              <div className="flex flex-col md:flex-row items-center justify-end mb-5 gap-2 relative">
                <div className="flex-1 w-full md:w-auto xl:absolute xl:left-1/2 xl:-translate-x-1/2">
                  <p className="w-full text-center text-[27px] font-bold">
                    {t("project.listAll.project_list")}
                  </p>
                </div>

                <div className="flex w-full items-center shrink-0 md:w-auto">
                  {/* Search input */}
                  <AddressSearchInput
                    filterValues={filterValues}
                    setFilterValues={setFilterValues}
                  />

                  <button
                    type="button"
                    style={{ marginLeft: "12px" }}
                    onClick={() => setIsDrawerOpen(true)}
                    className={`relative flex md:hidden items-center justify-center flex-shrink-0 h-10 w-10 border-solid border-black rounded-full ${
                      Object.values(filterValuesFormat).every((item) => !item)
                        ? "border"
                        : "border-2"
                    }`}
                  >
                    <TuneIcon fontSize="small" />
                    {Object.values(filterValuesFormat).filter((item) => item)
                      .length ? (
                      <div
                        className="absolute -right-2 -top-2 w-[18px] h-[18px] text-white bg-black
                rounded-full text-[11px] flex items-center justify-center"
                      >
                        {
                          Object.values(filterValuesFormat).filter(
                            (item) => item
                          ).length
                        }
                      </div>
                    ) : null}
                  </button>
                  <Button
                    variant="outline"
                    onClick={() => {
                      setIsDrawerOpen(!isDrawerOpen);
                    }}
                    className={`!relative !text-black !hidden md:!flex hover:!bg-secondary !items-center !border-solid !border-black !rounded-xl !p-4 !ml-3 !cursor-pointer ${
                      Object.values(filterValuesFormat).every((item) => !item)
                        ? "!border"
                        : "!border-2 !font-bold"
                    }`}
                  >
                    <i className="fa-solid fa-sliders mr-2" />
                    <span className="text-[12px] whitespace-nowrap">
                      {t("common.action.filter")}
                    </span>
                    {Object.values(filterValuesFormat).filter((item) => item)
                      .length ? (
                      <div
                        className="absolute -right-2 -top-2 w-[18px] h-[18px] text-white bg-black
                rounded-full text-[11px] flex items-center justify-center"
                      >
                        {
                          Object.values(filterValuesFormat).filter(
                            (item) => item
                          ).length
                        }
                      </div>
                    ) : null}
                  </Button>
                </div>
              </div>

              {/* List Project */}
              {(() => {
                if (!isLoading) {
                  if (errorMess) {
                    return (
                      <p className="text-[20px] font-[600] text-center">
                        {errorMess}
                      </p>
                    );
                  }
                  if (!response.length) {
                    return (
                      <p className="text-[20px] font-[600] text-center">
                        {t("common.message.no_data_display")}
                      </p>
                    );
                  }
                  return (
                    <div className="list-project flex flex-col flex-1">
                      <div className="flex-1 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-6">
                        {response.map((project) => {
                          return (
                            <div
                              key={project.ad_project_id}
                              className="project-item-wrapper"
                            >
                              <ProjectItem
                                project={project}
                                isLoading={isLoading}
                                linkUrl={Path.project_detail(
                                  project.ad_project_id
                                )}
                              />
                            </div>
                          );
                        })}
                      </div>
                      <div className="flex justify-center items-center pt-10">
                        <Pagination
                          page={Number(filterValues?.page)}
                          count={totalPages}
                          onChange={(event, selectedPage) => {
                            handleChangePage(selectedPage);
                            setFilterValues((prev) => ({
                              ...prev,
                              page: selectedPage,
                            }));
                          }}
                          showFirstButton
                          showLastButton
                        />
                      </div>
                    </div>
                  );
                }
                return null;
              })()}
              <SearchFilterComponent
                isDrawerOpen={isDrawerOpen}
                setDrawerState={setDrawerState}
                setFilterValues={setFilterValues}
                filterValues={filterValues}
                ageGroupArray={filterValues?.age_group}
              />
            </div>
          </div>
        </MainContent>
      </LayoutMain>
    </HomeContext.Provider>
  );
}

export default Home;
