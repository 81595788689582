import chatClient from "./chatClient";

class ChatService {
  constructor() {
    this.client = chatClient;
  }

  sendMessage(data) {
    return this.client.post("/api/chats/chats", data);
  }

  getAllMessages({ chatRoomId, page, limit }) {
    const apiUrl = `/api/chats/chats/${chatRoomId}?page=${page}&limit=${limit}`;
    return this.client.get(apiUrl);
  }

  getChatList({ page, limit }) {
    const apiUrl = `api/chats/chat-room/by-user?page=${page}&limit=${limit}`;
    return this.client.get(apiUrl);
  }

  updateChatRoomReplyStatus(chatRoomId) {
    const apiUrl = `api/chats/chat-room/${chatRoomId}`;
    return this.client.put(apiUrl);
  }

  getChatRoomByAdmin(projectId, page, limit, filterStatus) {
    const apiUrl = `api/chats/chat-room/chat-room-by-admin?project_id=${projectId}&page=${page}&limit=${limit}&filter_status=${filterStatus}`;
    return this.client.get(apiUrl);
  }

  updatePingToOperator(chatRoomId, body) {
    const apiUrl = `api/chats/chats/update/${chatRoomId}`;
    return this.client.post(apiUrl, body);
  }
}

export default new ChatService();
