export default {
  for_influencer: "To influencers",
  open_door: "Open the door",
  Start_registration: "Start your registration",
  Twitter: "X (FormerTwitter)",
  Google: "Continue with Google",
  Line: "Continue with LINE",
  Login_or_Register: "Login or Register",
  welcome: "Welcome to Buzz Traveler",
  register_label: "Member Registration",
  register_mess:
    "Thank you for registering as a member.\nWe have sent instructions for a temporary password to the email address you provided.\nPlease access the password change screen provided in the email, enter the temporary password specified in the email, and set a new password.",
  enter_password: "Enter Password", // need translation
  forgot_password_mess: "Forgot your password? Click here.",
  change_pass_info_title: "Password Reset",
  change_pass_info_mess:
    "We have sent an email with password reset instructions to the registered email address. Please access the password change screen provided in the email, enter the temporary password specified in the email, and set a new password.",
  change_pass_left_title: "Set the new password here.",
  wrong_format_new_pass:
    "Please enter your password between 8 and 64 characters, including half-width alphanumeric characters and symbols.",
  require_confirm_pass: "Please enter the new password (confirmation).",
  tempo_password: "Temporary password specified in the email",
  password_format_notice:
    "Please enter a password containing at least 8 characters, including alphanumeric and symbol characters.",
};
