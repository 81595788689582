import { createContext } from "react";
import socketio from "socket.io-client";
import store from "app/redux/store";

const getToken = () => {
  return store.getState()?.auth.token?.accessToken || "";
};

export const SOCKET_LISTENER = {
  DISCONNECT: "disconnect",
  POST_MESSAGE: "postedMsg",
  REPLY_STATUS: "reply_status",
  UPDATE_CHECK_IN: "updatedCheckin",
  UPDATE_APPLY_PROJECT_STATUS: "updatedAppliedProjectStatus",
  SHOW_NOTIFY: "showNotification",
};

export const SOCKET_TYPE = {
  CHAT: "CHAT",
  ITCL: "ITCL"
};

export const SOCKET_NAMESPACE = {
  CHAT: "chats",
  BOOKING: "booking"
};

export const socketIO = (type, namespace, query) => {
  let uri = "";
  switch (type) {
    case SOCKET_TYPE.CHAT:
      uri = `${process.env.REACT_APP_CHAT_API_URL}${namespace}`;
      break;
    case SOCKET_TYPE.ITCL:
      uri = `${process.env.REACT_APP_API_URL}${namespace}`;
      break;
    default:
      break;
  }

  const options = {
    transports: ["websocket"],
    // transports: ["websocket", "flashsocket", "polling"],
    auth: { token: `Bearer ${getToken()}` },
    withCredentials: true
  };

  if (query) {
    options.query = query;
  }

  return socketio(uri, options);
};

export const SocketContext = createContext();
