export default {
  add: {
    name_note: "（此資訊也會顯示給招募案件申請人）",
    user_name: "使用者名稱",
    account_name: "使用者名稱",
    youtube_channel_id: "頻道 ID",
    enter_information: "請填寫必填欄位。",
    notice1: "※要成為網紅之前，您需要註冊一個社交網路帳戶。",
    notice2: "請輸入以下社交網站的帳號資訊。",
  },
};
