import React, { useEffect, useState } from "react";
import Skeleton from "@mui/material/Skeleton";

function ChatboxSkeleton(props) {
  const { skeletonMapping } = props;
  const [skeletonMap, setSkeletonMap] = useState([
    false,
    true,
    false,
    false,
    true
  ]);

  useEffect(() => {
    if (skeletonMapping) {
      setSkeletonMap(skeletonMapping);
    }
  }, [setSkeletonMap, skeletonMapping]);

  return (
    <div className="chat-list-detail-skeleton">
      {skeletonMap.map((isMe) =>
        isMe ? (
          <div
            key={Math.random()}
            className="message-box-wrapper flex justify-end h-20 mt-2"
          >
            <Skeleton variant="text" className="w-[75%]" />
          </div>
        ) : (
          <div key={Math.random()} className="flex mt-2">
            <div className="flex-none w-12 h-12 mr-3">
              <Skeleton variant="circular" width={48} height={48} />
            </div>
            <div className="message-box-wrapper flex flex-1 h-25">
              <Skeleton variant="text" className="w-[75%]" />
            </div>
          </div>
        )
      )}
    </div>
  );
}

export default ChatboxSkeleton;
