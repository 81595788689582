import { createSlice } from "@reduxjs/toolkit";

const initialState = null;

const slice = createSlice({
  name: "email",
  initialState,
  reducers: {
    setEmail: (state, action) => {
      return action.payload;
    },
  },
});

export const { setEmail } = slice.actions;

export default slice.reducer;
