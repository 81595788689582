import { useEffect, useState, useCallback } from "react";
import CommonService from "app/services/CommonService";
import { EResponseStatus } from "app/helper/constant";
import { useTranslation } from "react-i18next";

function useCoreComponent(endpoint, params, getOnce) {
  const { t } = useTranslation();
  const [errorMess, setErrorMess] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [totalItem, setTotalItem] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [response, setResponse] = useState([]);

  const fetchData = useCallback(() => {
    setErrorMess("");
    setIsLoading(true);
    CommonService.getModelList(endpoint, params)
      .then((res) => {
        if (res.status === EResponseStatus.SUCCESS) {
          setTotalPages(res.data?.data?.totalPages);
          setTotalItem(res?.data?.data?.total);
          if (res?.data?.data?.row?.length) {
            setResponse(res?.data?.data?.row);
          } else {
            setResponse([]);
          }
        }
      })
      .catch(() => {
        setErrorMess(t("common.message.cannot_display_data"));
      })
      .finally(() => setIsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getOnce ? null : endpoint, getOnce ? null : JSON.stringify(params)]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { response, errorMess, totalPages, isLoading, totalItem };
}

export default useCoreComponent;
