export default {
  prepare_payment: "支払い準備中",
  payable: "支払い可能",
  payment_request: "支払い依頼済み",
  paid: "支払い済み",
  all: "すべて",
  price: "金額",
  request_pay: "支払い依頼",
  notice: "※支払い可能額が ¥ 50,000 以上になったら支払い依頼が可能になります。",
  title: "募集案件名",
  fee: "報酬金額",
  finished_at: "完了承認日",
};