// i-001-001
import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import LayoutMain from "app/layouts/LayoutMain";
import ChangeLanguageButton from "app/components/ChangeLanguageButton";
import { setAuthStateDefault } from "../../redux/authReducer";
import { setRoute, setRouteEmpty } from "../../redux/previousRouteReducer";
import { setProfileEmpty } from "../../redux/profileReducer";
import { setUpdateStateDefault } from "../../redux/updateInfoReducer";
import LoginOptions from "./LoginOptions";

const imageDeskop = require("app/assets/images/image-deskop.jpg");

function Login() {
  const { t } = useTranslation();
  const [showLoginOptions, setShowLoginOptions] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  const redirect = searchParams.get("redirect");

  useEffect(() => {
    if (!location.search) {
      dispatch(setAuthStateDefault());
      dispatch(setProfileEmpty(null));
      dispatch(setUpdateStateDefault());
      dispatch(setRouteEmpty()); // if url has no query, set previous route = null
    }
    if (redirect) {
      dispatch(setRoute(redirect)); // if url has redirect query, set previous route = redirect query
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LayoutMain showFooter={false} showMobileNav={false} customHeader>
      <SplitLayout
        right={
          <div className="flex flex-col justify-center items-center h-full relative">
            <ChangeLanguageButton className="h-10 w-10 absolute top-0 right-0 text-white" />
            <div className="text-white text-[28px] sm:text-[32px] font-semibold text-center mb-6">
              <h1>{t("auth.for_influencer")}</h1>
              <h1>{t("auth.open_door")}</h1>
            </div>
            <Button
              type="submit"
              className="!min-w-[160px] h-12 !text-[14px] sm:!text-[16px] !text-white !bg-[#0E5CB5] !rounded-[8px] !px-4"
              onClick={() => setShowLoginOptions(true)}
            >
              {t("auth.Start_registration")}
            </Button>
          </div>
        }
        showLoginOptions={showLoginOptions}
        setShowLoginOptions={(state) => setShowLoginOptions(state)}
        leftBG={imageDeskop}
      />
    </LayoutMain>
  );
}
function SplitLayout({
  left,
  right,
  leftBG,
  showLoginOptions,
  setShowLoginOptions,
}) {
  return (
    <div className="flex flex-wrap sm:flex-row-reverse items-stretch h-screen overflow-hidden ">
      <div
        className="h-1/2 md:h-full w-full md:w-1/2 xl:w-1/2 bg-cover flex"
        style={{ backgroundImage: `url('${leftBG}')` }}
      >
        {left}
      </div>
      <div className="h-1/2 md:h-full w-full flex-1 bg-cover p-4 bg-black">
        {right}
      </div>
      <LoginOptions
        showLoginOptions={showLoginOptions}
        setShowLoginOptions={setShowLoginOptions}
      />
    </div>
  );
}

export default Login;
