import register from "../pages/Register/translate/ja";
import project from "../pages/Projects/translate/ja";
import home from "../pages/Home/translate/ja";
import auth from "../pages/Login/translate/ja";
import accountSetting from "../pages/AccountSettings/translate/ja";
import reward from "../pages/Reward/translate/ja";
import chat from "../chat/langs/ChatJA";
import favorite from "../pages/Favorite/translate/ja";
import profile from "../pages/Profile/translate/ja";

export default {
  chat,
  home,
  register,
  project,
  auth,
  accountSetting,
  reward,
  favorite,
  profile,
  common: {
    btn: {
      download_csv: "CSVファイルをダウンロード",
      export: "エクスポート",
      continue: "続行",
      register: "登録",
    },
    message: {
      in_process: "進行中",
      create_success: "登録が完了しました。",
      update_success: "更新が完了しました。",
      delete_success: "削除されました。",
      create_fail: "登録が失敗した。もう一度お試しください。",
      update_fail: "更新に失敗しました。もう一度お試しください。",
      delete_fail: "削除に失敗しました。もう一度お試しください。",
      not_exist: "削除対象が存在しません。",
      wrong_current_password: "現在のパスワードが間違っています。",
      no_data_display: "表示するデータがありません。",
      select_lang: "使用言語と地域を選択してください",
      oversize_file: "アップロード可能な画像サイズは最大{{size}}MBです。",
      wrong_format_file:
        "アップロード可能なファイルは以下のファイルです。\n{{format}}。",
      cannot_display_data:
        "データを読み込めません。 もう一度やり直してください。",
      project_not_found:
        "該当の募集案件は非表示または削除された為表示出来ません。",
      project_expired: "該当の募集案件の有効期限が切れた為表示出来ません。",
      CANT_NOT_APPLY_TO_PROJECT_TWICE: "プロジェクトへの重複応募はできません。",
      INVALID_EMAIL: "メールアドレスが無効です。",
      EMAIL_EXIST: "入力されたメールアドレスは利用できません。",
      NOT_FOUND: "{{field}}アカウントが見つかりません。",
      COMMON_EXCEPTION: "{{field}}アカウントが見つかりません。",
      CAN_NOT_UPDATED: "登録に失敗しました。",
      one_time_service: "1回無料サービス",
      "Request failed with status code 403":
        "更新に失敗しました。もう一度お試しください。",
      "Request failed with status code 401":
        "更新に失敗しました。もう一度お試しください。",
      saved_to_wishlist: "お気に入りに保存しました。",
      remove_from_wishlist: "お気に入りから解除されました。",
      saved_to_wishlist_error: "お気に入りリストに保存エラー。",
      INVALID_PASSWORD: "現在のパスワードが間違っています。",
      confirm_password_not_match: "パスワードが一致していません。",
      wrong_information:
        "ID（メールアドレス）、パスワードのどちらかが間違っています。",
      all: "全て",
      twitter: "X (旧Twitter)",
      video: "動画",
      image: "写真",
      "image, video": "動画 または 写真",
      no_input_yet: "未設定",
      not_found: "申し訳ありませんが、お探しのものが見つかりません！",
      youtube_not_found: "YouTubeアカウントが見つかりません。",
    },
    action: {
      edit: "編集",
      cancel: "キャンセル",
      save: "保存",
      back: "戻る",
      filter: "フィルター",
      select_file: "ファイル選択",
      apply: "応募する",
      submit: "送信",
      clear_all: "すべてクリア",
      close: "閉じる",
      ping_platform: "翻訳依頼",
      continue: "続行する",
      yes: "はい",
      update: "更新する",
    },
    price: "¥",
    date_format: {
      dd_mm_yyyy: "DD日MM月YYYY年",
      yyyy_mm_dd: "YYYY年MM月DD日",
      yyyy_mm: "YYYY年MM月",
      mm_dd: "MM月DD日",
      dd: "DD日",
    },
    male: "男性",
    female: "女性",
    other: "指定なし",
    other1: "指定なし",
    japan: "日本",
    followers: "フォロワー",
    followers_1: "フォロワー数",
  },
  validate_message: {
    required: "{{- path}}を入力してください。",
    max_length: "{{- path}}は{{max}}文字以内で入力してください。",
    wrong_format: "{{- path}}形式が不正です。",
  },
  validation_field: {
    prDescription: "自己PR",
    prUrl1: "URL1",
    prUrl2: "URL2",
    prUrl3: "URL3",
    prFile1: "ファイル 1",
    prFile2: "ファイル 2",
    prFile3: "ファイル 3",
    name: "表示名",
    gender: "性別",
    postcode: "郵便番号",
    email: "メールアドレス",
    self_introduction: "自己紹介",
    instagram_account: "Instagram アカウント",
    tiktok_account: "Tiktok アカウント",
    twitter_account: "Twitter アカウント",
    face_account: "Facebook アカウント",
    youtube_channel_id: "Youtube チャンネルID",
    family_name: "姓",
    first_name: "名",
    current_password: "現在のパスワード",
    new_password: "新しいパスワード",
    confirm_new_password: "パスワードを認証する",
    confirm_new_password1: "新しいパスワード（確認用）",
    url: "URL",
    message: "メッセージ",
    reason: "理由",
    nickname: "表示名",
    country: "国",
    city: "市区町村",
    street: "都道府県",
    address: "番地",
    password: "パスワード",
    age_group_start: "希望するインフルエンサーの年齢層",
    age_group_end: "希望するインフルエンサーの年齢層",
    replyMessage: "返信メッセージ",
    email_message: "メールアドレスを入力してください。",
    email_invalid_message: "メールアドレスの形式が不正です。",
    new_password_required_message: "新しいパスワードを入力してください。",
    current_password_not_found: "現在のパスワードを入力してください。",
    name_required_message: "表示名を入力してください。",
    gender_required_message: "性別を入力してください。",
    pr_self_required_message: "自己PRを入力してください。",
  },
  header: {
    favorite: "募集案件のお気に入り",
    favorite1: "お気に入り",
    homepage: "インフルエンサーホーム",
    project_applied: "応募一覧",
    reward: "報酬",
    account_settings: "アカウント",
    log_out: "ログアウト",
  },
  change_language: "言語と地域",
  or1: "ま",
  or2: "た",
  or3: "は",
  footer: {
    privacy: "プライバシー",
    term_of_use: "利用規約",
    about_comany: "企業情報",
    currency: "日本語",
    copyright: "© Copyright 2023 株式会社明勝 All Rights Reserved."
  },
  business_operator: "事業者",
  operator_admin: "運営",
  notification_popup: {
    title: "お知らせ",
    show_more: "詳細を確認",
  },
  generation1: "代",
  generation2: "代",
  generation3: "代以上",
};
