import { EResponseStatus } from "../../helper/constant";
import { setActive } from "../../redux/blockUIReducer";
import store from "../../redux/store";
import UserService from "../../services/UserService";

export const formatUpdateProfileData = (profile, values) => {
  const formatData = {
    family_name: profile?.family_name,
    first_name: profile?.first_name,
    nickname: profile?.nickname,
    avatar_url: values?.avatar_url,
    self_introduction: values?.self_introduction,
    address: profile?.address,
    spoken_languages: profile?.spoken_languages,
    instagram: values?.instagram,
    instagram_followers: values?.instagram_followers ? Number(values?.instagram_followers) : 0,
    tiktok: values?.tiktok,
    tiktok_followers: values?.tiktok_followers ? Number(values?.tiktok_followers) : 0,
    facebook: values?.facebook,
    facebook_followers: values?.facebook_followers ? Number(values?.facebook_followers) : 0,
    youtube: values?.youtube,
  };
  return formatData;
};

export const updateProfileAction = ({
  profile,
  values,
  callbackSuccess,
  callbackError,
  callbackFinally,
}) => {
  store.dispatch(setActive(true));
  UserService.updateProfile(formatUpdateProfileData(profile, values))
    .then((response) => {
      if (response.status === EResponseStatus.SUCCESS) {
        if (callbackSuccess) {
          callbackSuccess();
        }
      }
    })
    .catch((error) => {
      if (callbackError) {
        callbackError(error);
      }
    })
    .finally(() => {
      store.dispatch(setActive(false));
      if (callbackFinally) {
        callbackFinally();
      }
    });
};
