import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Snackbar from "@mui/material/Snackbar";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PlayArrowOutlinedIcon from "@mui/icons-material/PlayArrowOutlined";
import {
  socketIO,
  SOCKET_LISTENER,
  SOCKET_NAMESPACE,
  SOCKET_TYPE,
} from "app/context/socket";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import HeaderInfo from "./HeaderInfo";
import InfluencerService from "../../services/InfluencerService";
import { EResponseStatus } from "../../helper/constant";
import { setShowNotify } from "../../redux/authReducer";
import Path from "../../route/Path";
import PopupNotify from "../PopupNotify";

const pageLogo = require("app/assets/images/buzz-logo.png");

function Header({ notShowHeaderInfo, resetFilter }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.auth);
  const { user_id: userId } = user || {};
  const [open, setOpen] = useState(false);
  const [lstNotify, setListNotify] = useState([]);
  const [currentNotify, setCurrentNotfy] = useState(0);
  const [selectedNotification, setSelectedNotification] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);

  useEffect(() => {
    InfluencerService.getListNotification().then((res) => {
      if (res.status === EResponseStatus.SUCCESS) {
        const data = res?.data?.data;
        setListNotify(data);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // handle open notification
  useEffect(() => {
    if (lstNotify?.length) {
      setOpen(true);
      dispatch(setShowNotify(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lstNotify]);

  // event socket listen to new notification
  useEffect(() => {
    const socket = socketIO(SOCKET_TYPE.ITCL, SOCKET_NAMESPACE.BOOKING, {
      room_id: userId,
    });

    socket.on(SOCKET_LISTENER.SHOW_NOTIFY, (response) => {
      if (response?.data?.length) {
        dispatch(setShowNotify(true));
        const listTemp = [...response.data];
        setListNotify(listTemp);
      }
    });

    return () => {
      socket.off(SOCKET_LISTENER.SHOW_NOTIFY);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, lstNotify]);

  const handleItemPosition = () => {
    const style = {
      top: -1 * currentNotify * 56,
    };
    return style;
  };

  const handleNavigateHome = () => {
    if (location.pathname === Path.home) {
      resetFilter();
    } else {
      navigate(Path.home);
    }
  };

  const showDetail = (notification) => {
    setSelectedNotification(notification);
    setOpenPopup(true);
  };

  return (
    <div className="flex flex-col sticky top-0 left-0 z-[1030] w-full">
      <header
        style={{ boxShadow: "rgba(0, 0, 0, 0.08) 0 1px 0px" }}
        className="bg-white order-2"
      >
        <div className="custom-container">
          <div className="flex items-center justify-between h-[79px]">
            {/* Logo */}
            <button
              type="button"
              className="block logo flex-shrink-0 cursor-pointer h-10 lg:h-11 relative"
              onClick={handleNavigateHome}
            >
              <img src={pageLogo} alt="" className="h-full" />
            </button>
            {notShowHeaderInfo ? null : (
              <HeaderInfo resetFilter={resetFilter}>
                <div className="hidden md:flex flex-wrap items-center truncate">
                  <button
                    type="button"
                    className="py-1 md:py-2 px-3 rounded-3xl text-[14px] font-bold truncate cursor-pointer"
                    onClick={handleNavigateHome}
                  >
                    {t("header.homepage")}
                  </button>

                  {/* <a
                    href={customerUrl}
                    target="_blank"
                    className="hover:bg-secondary py-1 md:py-2 px-3 rounded-3xl text-[14px] font-bold truncate cursor-pointer transition-all duration-200 ease-linear"
                    rel="noreferrer"
                  >
                    {t("project.listAll.guest_here")}
                  </a> */}
                </div>
              </HeaderInfo>
            )}
          </div>
        </div>
      </header>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={open}
        classes={{
          root: "!order-1 !top-0 !left-0 !right-0 md:!right-[auto] md:!mx-auto !translate-x-0 !w-full !bg-[#F7F7F7] !h-[56px] !relative !rounded-[8px] !overflow-hidden",
        }}
      >
        <div
          className="w-full bg-[#F7F7F7] text-[#222] h-full absolute"
          style={handleItemPosition()}
        >
          {lstNotify?.map((item) => {
            return (
              <div
                key={item.notification_id}
                className="h-full bg-[#F7F7F7] px-4 py-1"
              >
                <div className="flex justify-between items-center h-full">
                  <div className="flex items-center gap-3 mr-4 w-[calc(100%-122px)] font-bold">
                    <div className="flex-shrink-0">
                      {t("notification_popup.title")}
                    </div>
                    <div className="truncate">{item.title}</div>
                  </div>

                  <div className="flex items-center gap-3">
                    <button
                      type="button"
                      onClick={() => showDetail(item)}
                      className="text-[14px] whitespace-nowrap"
                    >
                      {t("notification_popup.show_more")}
                    </button>
                    <div className="flex flex-col">
                      <PlayArrowOutlinedIcon
                        className="!-rotate-90 cursor-pointer"
                        onClick={() => {
                          if (currentNotify > 0) {
                            setCurrentNotfy((prev) => prev - 1);
                          }
                        }}
                      />
                      <PlayArrowIcon
                        className="!rotate-90 cursor-pointer"
                        onClick={() => {
                          if (currentNotify < lstNotify.length - 1) {
                            setCurrentNotfy((prev) => prev + 1);
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </Snackbar>
      <PopupNotify
        open={openPopup}
        onClose={() => setOpenPopup(false)}
        notification={selectedNotification}
      />
    </div>
  );
}

export default Header;
