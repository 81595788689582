/* eslint-disable class-methods-use-this */
import client from "./client";
import CommonService from "./CommonService";

const baseUrl = "influencer/ad-project";
const notifyUrl = 'cms/notification'

class InfluencerService {
  getAdProjectDetail(projectId) {
    return client.get(`${baseUrl}/get-one/${projectId}`);
  }

  applyProject(projectId, applyData) {
    return client.post(`${baseUrl}/apply/${projectId}`, applyData);
  }

  markFavorite(projectId) {
    return client.post(`${baseUrl}/set-favorite/${projectId}`);
  }

  getAppliedProject(page, perPage, status) {
    return client.get(
      `${baseUrl}/get-list-applied?page=${page}&per_page=${perPage}&status=${status}`
    );
  }

  getListAppliedUnreadMess() {
    return client.get(`${baseUrl}/get-list-applied-unread-message`);
  }

  getTotalFeeByPage(params) {
    return CommonService.getModelList(`${baseUrl}/applied/total-fee`, params);
  }

  getPaymentReady() {
    return client.put(`${baseUrl}/applied/getting-ready-to-payment-ready`);
  }

  changeStatusApplied(projectId, data) {
    return CommonService.postModel(
      `${baseUrl}/change-status/${projectId}`,
      data
    );
  }

  sendDeliverable(applicationId, data) {
    return CommonService.postModel(
      `${baseUrl}/deliverable/store/${applicationId}`,
      data
    );
  }

  getDetailProjectApplied(projectId) {
    return CommonService.getModelList(`${baseUrl}/get-one/${projectId}`);
  }

  setReadMsg(chatRoomId) {
    return CommonService.postModel(`${baseUrl}/read-msg/${chatRoomId}`);
  }

  getListNotification() {
    return CommonService.getModelList(`${notifyUrl}/get-all`);
  }
}

export default new InfluencerService();
