import React, { useMemo, useState } from "react";
import { ToastContainer } from "react-toastify";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { LayoutContext } from "app/utils";
import Footer from "../components/common/Footer";
import "react-toastify/dist/ReactToastify.css";
import MobileNav from "../components/common/MobileNav";
import Header from "../components/common/Header";
import ChangeLanguage from "../components/ChangeLanguage";

function LayoutMain({
  children,
  path,
  showMobileHeader,
  customHeader,
  showToXL,
  resetFilter,
  setFilterValues,
  showFooter = true,
  showMobileNav = true,
}) {
  const [isChangeLangOpen, setIsChangeLangOpen] = useState(false);
  const notUpdate = useSelector((state) => state.notUpdate);

  return (
    <LayoutContext.Provider
      value={useMemo(
        () => ({ isChangeLangOpen, setIsChangeLangOpen }),
        [isChangeLangOpen, setIsChangeLangOpen]
      )}
    >
      <div className="flex flex-col min-h-screen">
        {!customHeader && <Header resetFilter={resetFilter} />}

        {/* Mobile */}
        {showMobileHeader && (
          <header
            className={`mt-5 ${showToXL ? "xl:hidden" : "md:hidden"} bg-white`}
          >
            <div className="custom-container">
              <div className="flex items-center justify-between h-16">
                <div className="flex items-center">
                  <Link
                    to={path}
                    className="w-7 h-7 flex items-center justify-center"
                  >
                    <i className="fa-solid fa-chevron-left" />
                  </Link>
                </div>
              </div>
            </div>
          </header>
        )}

        {children}

        <ChangeLanguage setFilterValues={setFilterValues} />

        {showFooter && <Footer />}

        {!notUpdate && showMobileNav && (
          <MobileNav
            resetFilter={resetFilter}
            className="md:hidden transition-transform"
          />
        )}
        <ToastContainer
          position="bottom-left"
          autoClose={1500}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover
          theme="light"
          limit={3}
        />
      </div>
    </LayoutContext.Provider>
  );
}

export default LayoutMain;
