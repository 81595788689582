export default {
  add: {
    name_note: "（募集案件依頼主にも表示されます）",
    user_name: "ユーザネーム",
    account_name: "ユーザ名",
    youtube_channel_id: "チャンネルID",
    enter_information: "必要事項を入力してください。",
    notice1:
      "※インフルエンサーとしての活動をする為に、SNSアカウントの登録が必要です。",
    notice2: "以下のいずれかのSNSのアカウント情報を入力ください。",
  },
};