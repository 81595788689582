import React from "react";
import { isVideoRegex } from "app/helper/constant";
import ImageItem from "./ImageItem";
import VideoItem from "./VideoItem";

function Media({
  data,
  controls,
  className,
  isVideoProps,
  openChat,
  videoClass,
  imageClassName,
  containerClassName,
  skeletonClassName,
  lazy
}) {
  const isVideo =
    isVideoRegex?.exec(`${data}`.toLowerCase()) ||
    isVideoRegex?.exec(`${data?.name}`.toLowerCase()) ||
    isVideoProps;
  return (
    <div className={className}>
      {(() => {
        if (isVideo) {
          return (
            <VideoItem
              skeletonclassname={`${skeletonClassName}`}
              containerclassname={`${containerClassName}`}
              onClick={openChat || null}
              imagesrc={data}
              controls={controls}
              videoclassname={videoClass}
            />
          );
        }
        return (
          <ImageItem
            lazy={lazy}
            skeletonclassname={`${skeletonClassName}`}
            containerclassname={`${containerClassName}`}
            onClick={openChat || null}
            role="presentation"
            imgclassname={`${imageClassName}`}
            imagesrc={data}
            alt=""
          />
        );
      })()}
    </div>
  );
}

export default Media;
