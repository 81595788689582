import React, { useContext } from "react";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import InputText from "app/components/InputText";
import EditableForm from "app/components/EditableForm";
import UserContext from "./UserContext";
import { updatePersonalInfoAction } from "../../../utils/personal-info";
import { setProfileStore } from "../../../redux/profileReducer";

const EDIT_NICKNAME = "show_edit_nickname";

function NicknameSettingField(props) {
   const dispatch = useDispatch();
  const { showEdit, setShowEdit, setOpenSuccess, setOpenError } = props;
  const { t } = useTranslation();
  const { userInfo, setUserInfo, profile } = useContext(UserContext);

  const validationSchema = Yup.object().shape({
    nickname: Yup.string().required().trim().max(64),
  });
  const initialValues = {
    nickname: "",
  };

  const updateUserInfo = (values, context) => {
    const { setValues } = context;
    updatePersonalInfoAction({
      userInfo,
      values,
      setUserInfo,
      setValues,
      setOpenSuccess,
      setOpenError,
      setShowEdit,
      callbackSuccess: (data) => {
        const object = { ...profile };
        object.nickname = data.nickname;
        dispatch(setProfileStore(object));
      },
    });
  };

  const renderForm = ({ values, setFieldValue }) => {
    return (
      <InputText
        required
        inputId="nickname"
        title={t("accountSetting.personal_info.nickname")}
        inputName="nickname"
        inputValue={values?.nickname}
        setFieldValue={setFieldValue}
        maxLength={64}
      />
    );
  };

  return (
    <EditableForm
      editForm={renderForm}
      onSubmit={updateUserInfo}
      validationSchema={validationSchema}
      initialValues={initialValues}
      label={t("accountSetting.personal_info.nickname")}
      editField={EDIT_NICKNAME}
      formValues={userInfo}
      displayValue={userInfo?.nickname}
      showEdit={showEdit}
      setShowEdit={setShowEdit}
      required
    />
  );
}

export default NicknameSettingField;
