import React from "react";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Path from "app/route/Path";
import ChangeLanguageButton from "app/components/ChangeLanguageButton";

function PopupShowMessage({ open, message, label }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Modal
      open={open}
      onClose={() => navigate(Path.change_password)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className="flex justify-center items-center h-full p-6">
          <div className="relative max-w-[550px] w-full">
            <div className="border-0 rounded-[4px] shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
              <div className="relative flex items-center justify-center p-6 border-b border-border">
                <div className="absolute left-6">
                  <IconButton
                    edge="start"
                    color="inherit"
                    onClick={() => navigate(Path.change_password)}
                    aria-label="close"
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
                <p className="font-[700]">{label}</p>
                <ChangeLanguageButton className="hover:bg-secondary transition-all duration-200 ease-linear absolute right-6 p-2 -m-2" />
              </div>

              <div className="p-6">
                <div className="mb-10 font-semibold whitespace-pre-wrap word-break">
                  {message}
                </div>

                <Button
                  type="button"
                  className="!w-full sm:!text-[16px] !text-white !bg-[#0E5CB5] !rounded-[8px]"
                  onClick={() => navigate(Path.change_password)}
                >
                  {t("common.action.yes")}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}

export default PopupShowMessage;
