import React from "react";

function MainContent({ children, notFullWidth }) {
  return (
    <main className="flex flex-1 my-8">
      <div
        className="custom-container md:flex justify-center items-center w-full"
      >
        <div className={`h-full ${notFullWidth ? "" : "w-full"}`}>
          {children}
        </div>
      </div>
    </main>
  );
}

export default MainContent;
