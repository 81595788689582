/* eslint-disable jsx-a11y/label-has-associated-control */
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import { setActive } from "app/redux/blockUIReducer";
import { EResponseStatus, passwordRegex } from "app/helper/constant";
import UserService from "app/services/UserService";

function ChangePassword({
  setSuccessMess,
  setErrorMess,
  setShowChangePassword,
  setLoginInfo,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const validationSchema = Yup.object().shape({
    current_password: Yup.string().required(t("validation_field.current_password_not_found")),
    new_password: Yup.string().required(t("validation_field.new_password_required_message")).matches(passwordRegex),
    confirm_new_password: Yup.string()
      .required(t("auth.require_confirm_pass"))
      .matches(passwordRegex, t("auth.wrong_format_new_pass"))
      .when("new_password", {
        is: (val) => !!(val && val.length > 0),
        then: Yup.string().oneOf(
          [Yup.ref("new_password")],
          t("common.message.confirm_password_not_match")
        ),
      }),
  });
  const initialValues = {
    current_password: "",
    new_password: "",
    confirm_new_password: "",
  };

  const updatePassword = (values, context) => {
    dispatch(setActive(true));
    const { resetForm } = context;
    const data = {
      current_password: values.current_password,
      new_password: values.new_password,
    };
    UserService.changePassword(data)
      .then((response) => {
        if (response.status === EResponseStatus.CREATED) {
          setLoginInfo((prev) => ({
            ...prev,
            password_update_at: response?.data?.data?.updated_at,
          }));
          setSuccessMess(t("common.message.update_success"));
          setShowChangePassword(false);
          resetForm();
        }
      })
      .catch((error) => {
        if (error.response.status === EResponseStatus.BAD_REQUEST) {
          const errorMess = error.response.data.message;
          setErrorMess(t(`common.message.${errorMess}`));
        } else {
          setErrorMess(t(`common.message.update_fail`));
        }
      })
      .finally(() => dispatch(setActive(false)));
  };

  return (
    <Formik
      validateOnMount
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={updatePassword}
    >
      {() => {
        return (
          <Form className="w-full">
            <div className="w-full h-auto flex flex-col">
              <div className="w-full mb-2">
                <label
                  htmlFor="current_password"
                  className="text-base leading-[22px] font-normal mb-2 inline-block cursor-pointer"
                >
                  {t("accountSetting.login_security.current_password")}
                </label>

                <div className="w-full">
                  <Field
                    id="current_password"
                    name="current_password"
                    maxLength={64}
                    type="password"
                    className="leading-6 p-[11px] border border-border w-full rounded focus:border-[#008489] !outline-none"
                  />
                  <ErrorMessage
                    component="div"
                    name="current_password"
                    className="error-msg"
                  />
                </div>
              </div>

              <div className="w-full mb-2">
                <label
                  htmlFor="new_password"
                  className="text-base leading-[22px] font-normal mb-2 inline-block cursor-pointer"
                >
                  {t("accountSetting.login_security.new_password")}
                </label>

                <div className="w-full">
                  <Field
                    id="new_password"
                    name="new_password"
                    maxLength={64}
                    type="password"
                    className="leading-6 p-[11px] border border-border w-full rounded focus:border-[#008489] !outline-none"
                  />
                  <ErrorMessage
                    component="div"
                    name="new_password"
                    className="error-msg"
                  />
                </div>
              </div>

              <div className="w-full mb-2">
                <label
                  htmlFor="confirm_new_password"
                  className="text-base leading-[22px] font-normal mb-2 inline-block cursor-pointer"
                >
                  {t("accountSetting.login_security.confirm_password")}
                </label>

                <div className="w-full">
                  <Field
                    id="confirm_new_password"
                    name="confirm_new_password"
                    type="password"
                    className="leading-6 p-[11px] border border-border w-full rounded focus:border-[#008489] !outline-none"
                  />
                  <ErrorMessage
                    component="div"
                    name="confirm_new_password"
                    className="error-msg"
                  />
                </div>
              </div>

              <div className="w-full mt-4">
                <Button
                  type="submit"
                  variant="contained"
                  className="!px-6 !py-2.5 !rounded-md !bg-[#008489] !text-base !font-extrabold !text-white"
                >
                  {t("accountSetting.login_security.change_password")}
                </Button>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export default ChangePassword;
