/* eslint-disable class-methods-use-this */
import client from "./client";
import { appendFormData } from "../utils";

class CommonService {
  getModelList(model, params) {
    return client.request({
      method: 'get',
      url: `${model}`,
      params
    })
  }

  updateModel(model, params, id) {
    return client.request({
      method: 'put',
      url: `${model}/${id}}`,
      responseType: 'json',
      data: JSON.stringify(params)
    })
  }

  deleteModel(model, id) {
    return client.request({
      method: 'delete',
      url: `${model}/ + ${id}`,
      responseType: 'json',
    })
  }

  postFile(model, data) {
    return client.request({
      method: 'post',
      url: `${model}`,
      data,
      responseType: 'json'
    })
  }

  postModel(model, data) {
    return client.request({
      method: 'post',
      url: `${model}`,
      data: JSON.stringify(data),
      responseType: 'json'
    })
  }

  uploadFile(file) {
    return client.post("common/upload-file", appendFormData(file));
  }
}
export default new CommonService()

